export const transformStagesTypesToOptions = (stagesTypes) => stagesTypes.map(({ huWorkflowStagePluginType, huWorkflowStagePluginTypeString }) => ({
    label: huWorkflowStagePluginTypeString,
    value: huWorkflowStagePluginType,
}));
export const findSystem = (systems, guid) => systems.find((system) => system.guid === guid);
export const transformSystemToOptions = (system) => {
    const pluginNameOption = {
        label: system.pluginCreateDescription.name,
        value: system.pluginCreateDescription.type,
    };
    const pluginMethodNameOptions = system.pluginCreateDescription.methodDescriptions.map(({ guid, name }) => ({
        label: name,
        value: guid,
    }));
    return {
        pluginNameOption,
        pluginMethodNameOptions,
    };
};
export const transformSystemsToSelectOptions = (system) => system.map(({ guid, code }) => ({
    label: code,
    value: guid,
}));
export const transformPluginsToSelectOptions = (system) => system.map(({ guid, name }) => ({
    label: name,
    value: guid,
}));
export const findPlugin = (systems, guid) => systems.find((system) => system.guid === guid);
export const transformPluginToOptions = (system) => {
    const pluginNameOption = {
        label: system.name,
        value: system.type,
    };
    const pluginMethodNameOptions = system.methodDescriptions.map(({ guid, name }) => ({
        label: name,
        value: guid,
    }));
    return {
        pluginNameOption,
        pluginMethodNameOptions,
    };
};
