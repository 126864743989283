import React from 'react';
import { PageLayout } from '@/shared/components/PageLayout';
import { Button, CssBaseline, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, makeStyles, } from '@material-ui/core';
import { NavLink, useHistory, useParams } from 'react-router-dom';
const useStyles = makeStyles(() => ({
    root: {
        width: 'auto',
        height: 'auto',
    },
    title: {
        marginTop: '1rem',
        marginBottom: '1rem',
        marginLeft: '1rem',
    },
    tableName: {
        marginTop: '2rem',
        marginLeft: '1rem',
        fontWeight: 'bold',
    },
    container: {
        overflow: 'hidden',
        // minWidth: 400,
        width: '900px',
    },
    tableRow: {
        padding: '5px 16px 5px 16px',
        fontSize: '0.8rem',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '0px',
        tableLayout: 'auto',
        lineHeight: '1.5rem',
    },
    tableParentHead: {
        padding: '5px 16px 5px 16px',
        fontSize: '0.8rem',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '0px',
        tableLayout: 'auto',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        fontWeight: 'bolder',
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
    buttonTurn: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
    },
    gtidButtons: {
        marginTop: '7.5rem',
    },
    gridBackButton: {
    // marginTop: '2rem',
    },
    backButton: {
        fontWeight: 700,
        fontSize: '16px',
    },
    nextButton: {
        fontWeight: 700,
        fontSize: '16px',
        color: '#0066CC',
    },
    gridNextButton: {
        textAlign: 'center',
    },
    textSelect: {
        fontSize: '0.8rem',
        fontWeight: 'bolder',
        color: 'black'
    },
    textBodySelect: {
        fontSize: '0.8rem',
    },
    paper: {
        width: '900px',
        marginLeft: '4rem'
    }
}));
const columns = [
    { value: 'level', label: 'Уровень', minWidth: 50 },
    { value: 'entity', label: 'Сущность', minWidth: 100 },
    { value: 'selectAll', label: 'Выбрать для всех', minWidth: 150 },
    { value: 'numsOfKeys', label: 'Количество ключей', minWidth: 50 },
    { value: 'numsOfStarts', label: 'Стартовано процессов', minWidth: 50 },
    { value: 'startOfProcess', label: 'Запуск процесса', minWidth: 70 },
];
const codesOfProcess = [
    { label: 'Код процесса 1' },
    { label: 'Код процесса 2' },
    { label: 'Код процесса 3' },
    { label: 'Код процесса 4' },
    { label: 'Код процесса 5' },
];
function createData(level, entity, selectAll, numsOfKeys, numsOfStarts, startOfProcess) {
    return { level, entity, selectAll, numsOfKeys, numsOfStarts, startOfProcess };
}
export const DataMigrationPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const rows = [
        createData(0, 'Сущность 1', 'Выбрать процесс', 2, 1, React.createElement(Button, { variant: 'contained', size: 'small', className: classes.buttonTurn }, "\u0417\u0430\u043F\u0443\u0441\u0442\u0438\u0442\u044C")),
        createData(0, 'Сущность 2', 'Выбрать процесс', 3, 2, React.createElement(Button, { className: classes.buttonTurn }, "\u0417\u0430\u043F\u0443\u0441\u0442\u0438\u0442\u044C")),
        createData(0, 'Сущность 3', 'Выбрать процесс', 4, 3, React.createElement(Button, { className: classes.buttonTurn }, "\u0417\u0430\u043F\u0443\u0441\u0442\u0438\u0442\u044C")),
    ];
    const { guid, source, destination } = useParams();
    return (React.createElement(PageLayout, null,
        React.createElement(Typography, { variant: "h4", className: classes.title }, "\u041C\u0438\u0433\u0440\u0430\u0446\u0438\u044F \u0434\u0430\u043D\u043D\u044B\u0445"),
        React.createElement(Paper, { elevation: 3, className: classes.paper },
            React.createElement(CssBaseline, null),
            React.createElement(TableContainer, { className: classes.container },
                React.createElement(Typography, { variant: "h6", className: classes.tableName }, "\u041E\u0447\u0435\u0440\u0435\u0434\u044C \u0438\u043C\u043F\u043E\u0440\u0442\u0430"),
                React.createElement(Table, null,
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null, columns.map((column) => (React.createElement(TableCell, { key: column.value, align: column.align, className: classes.tableParentHead, style: { width: column.minWidth } }, column.value === 'selectAll' ? (React.createElement(FormControl, { style: { width: '100%', height: '50%', marginTop: '-15px' } },
                            React.createElement(InputLabel, { id: "demo-simple-select-outlined-label", className: classes.textSelect }, column.label),
                            React.createElement(Select, { labelId: "demo-simple-select-outlined-label", id: "demo-simple-select-outlined", label: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0434\u043B\u044F \u0432\u0441\u0435\u0445" }, codesOfProcess.map((code) => (React.createElement(MenuItem, { key: code.label, value: code.label, className: classes.textSelect }, code.label)))))) : (column.label)))))),
                    React.createElement(TableBody, null, rows.map((row) => (React.createElement(TableRow, { key: row.entity, className: classes.tableRow },
                        React.createElement(TableCell, { className: classes.tableRow }, row.level),
                        React.createElement(TableCell, { className: classes.tableRow },
                            React.createElement(NavLink, { to: `/migrator/${guid}/${source}/${destination}/dataMigration/${row.entity}`, style: { color: 'black' } }, row.entity)),
                        React.createElement(TableCell, { className: classes.tableRow },
                            React.createElement(FormControl, { style: { width: '100%', height: '70%', marginTop: '-15px' } },
                                React.createElement(InputLabel, { id: "demo-simple-select-outlined-label", className: classes.textBodySelect }, row.selectAll),
                                React.createElement(Select, { labelId: "demo-simple-select-outlined-label", id: "demo-simple-select-outlined", label: "\u0412\u044B\u0431\u0440\u0430\u0442\u044C \u0434\u043B\u044F \u0432\u0441\u0435\u0445" }, codesOfProcess.map((code) => (React.createElement(MenuItem, { key: code.label, value: code.label, className: classes.textBodySelect }, code.label)))))),
                        React.createElement(TableCell, { className: classes.tableRow }, row.numsOfKeys),
                        React.createElement(TableCell, { className: classes.tableRow }, row.numsOfStarts),
                        React.createElement(TableCell, { className: classes.tableRow }, row.startOfProcess)))))))),
        React.createElement(Grid, { container: true, spacing: 1, className: classes.gtidButtons },
            React.createElement(Grid, { item: true, xs: 6, className: classes.gridBackButton },
                React.createElement(Button, { className: classes.backButton, onClick: () => history.push(`/migrator/${guid}/${source}/${destination}/import`) }, "\u041D\u0430\u0437\u0430\u0434")),
            React.createElement(Grid, { item: true, xs: 6, className: classes.gridNextButton },
                React.createElement(Button, { onClick: () => history.push(`/migrators`), className: classes.nextButton }, "\u0417\u0430\u0432\u0435\u0440\u0448\u0438\u0442\u044C")))));
};
