import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
const columns = [
    { id: 'id', label: 'ИД Сообщения' },
    { id: 'integrationChain', label: 'Интеграционная цепочка' },
    { id: 'status', label: 'Статус' },
    { id: 'currentStage', label: 'Текущий этап' },
    { id: 'result', label: 'Результат' },
];
const rowsPerPageSetting = [25, 50, 100];
const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: '100%',
    },
    container: {
        height: '100%',
    },
    tableRow: {
        cursor: 'pointer',
    },
}));
export const MessagesStatusTable = ({ rows }) => {
    const styles = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageSetting[0]);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(Number(event.target.value));
        setPage(0);
    };
    return (React.createElement(Paper, { className: styles.root },
        React.createElement(TableContainer, { className: styles.container },
            React.createElement(Table, { stickyHeader: true },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null, columns.map((column) => (React.createElement(TableCell, { key: column.id, align: column.align }, column.label))))),
                React.createElement(TableBody, null, rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (React.createElement(TableRow, { className: styles.tableRow, key: row.id, hover: true }, columns.map((column) => {
                    const value = row[column.id];
                    return (React.createElement(TableCell, { key: column.id, align: column.align }, (column.component && column.component(value)) || value));
                }))))))),
        rows.length > rowsPerPageSetting[0] && (React.createElement(TablePagination, { labelRowsPerPage: 'Cтрок на странице', rowsPerPageOptions: rowsPerPageSetting, component: "div", count: rows.length, rowsPerPage: rowsPerPage, page: page, onChangePage: handleChangePage, onChangeRowsPerPage: handleChangeRowsPerPage }))));
};
