import { client } from '@/shared/api';
const PATH_AUTH_API = 'Admin';
export const registerPluginInstance = async (config) => {
    const { data } = await client.post(`${PATH_AUTH_API}/RegisterPluginInstance`, config);
    if (!data.isSuccess) {
        if (data.fieldValidationErrors) {
            return data;
        }
        else { // !data.pingSucceeded - ?
            throw new Error(`RegisterPluginInstance. PingException: ${data.errorMessage}`);
        }
    }
    return data;
};
export const deletePluginInstance = async (guid) => {
    const { data } = await client.delete(`${PATH_AUTH_API}/DeletePluginInstance/${guid}1`);
    if (!data.isSuccess) {
        throw new Error(`DeletePluginInstance. ${data.errorMessage}`);
    }
    return data;
};
