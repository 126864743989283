import { PageLayout } from '@/shared/components/PageLayout';
import { /*Box,*/ Button, Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import Xarrow, { Xwrapper, useXarrow } from 'react-xarrows';
import { useActionLogger } from '@/shared/hooks/action-logger';
import { useGetRebuildImportTree } from './services/hooks/use-get-rebuild-import-tree';
// import { StyledTreeItem } from './icons/Icons';
import { RebuildTree } from './components/RebuildTree/RebuildTree';
const useStyles = makeStyles(() => ({
    title: {
        marginTop: '1rem',
        marginBottom: '1rem',
        marginLeft: '1rem',
    },
    turnButton: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        height: '50px',
        marginTop: '1rem',
        marginBottom: '1rem',
        marginLeft: '1rem',
    },
    titleGrid: {
        margin: '1rem',
        textAlign: 'center',
    },
    gridBackButton: {
        marginTop: '6rem',
    },
    backButton: {
        fontWeight: 700,
        fontSize: '16px',
    },
    nextButton: {
        fontWeight: 700,
        fontSize: '16px',
        color: '#0066CC',
    },
    gridNextButton: {
        marginTop: '6rem',
        textAlign: 'center',
    },
    // gridContainer: {
    //     marginTop: '2rem',
    // },
}));
export const MigratorRebuildImportTree = () => {
    const classes = useStyles();
    const history = useHistory();
    const updateXarrow = useXarrow();
    const { guid, source, destination } = useParams();
    const [importTree, setImportTree] = useState();
    const [selectedArrowGuid, setSelectedArrowGuid] = useState('');
    const [isTreeConstructed, setIsTreeConstructed] = useState(false);
    const [sourceDestinationObj, setSourceDestinationObj] = useState({ source: '', destination: '' });
    const { setAction } = useActionLogger();
    const { refetch } = useGetRebuildImportTree(`?migratorGuid=${guid}`);
    const handleGetRebuild = () => {
        refetch().then(({ data }) => {
            setImportTree(data);
            setIsTreeConstructed(true);
            if (!data?.isSuccess) {
                setAction({
                    type: 'error',
                    message: 'При построении очереди импорта возникла ошибка',
                });
            }
        });
    };
    const handleConnectionsSelection = (link) => {
        if (link.destinationProperty.guid === selectedArrowGuid) {
            setSelectedArrowGuid('');
            setSourceDestinationObj({
                source: '',
                destination: '',
            });
        }
        else {
            setSelectedArrowGuid(link.destinationProperty.guid);
            setSourceDestinationObj({
                source: link.sourceHuProperty.guid,
                destination: link.destinationProperty.guid,
            });
        }
    };
    const setArrowStyles = (link) => selectedArrowGuid === link.destinationProperty.guid ? '#35c13c' : 'cornflowerblue';
    // const sourceModel = importTree && importTree.sourceModelForDestinationEntity.huPropertyLinks.map(item => item.sourceHuProperty)
    // const destinationModel = importTree && importTree.sourceModelForDestinationEntity.huPropertyLinks.map(item => item.destinationProperty);
    // console.log('source', sourceModel)
    // console.log('destination', destinationModel)
    // console.log('importTree', importTree);
    return (React.createElement(PageLayout, null,
        React.createElement(Typography, { variant: "h4", style: { marginLeft: '7px', marginBottom: '2rem' }, className: classes.title }, isTreeConstructed ? 'Результаты построения дерева импорта' : 'Построение дерева импорта'),
        React.createElement(Button, { variant: "contained", size: "medium", style: { marginLeft: '8px' }, className: classes.turnButton, onClick: handleGetRebuild }, "\u0417\u0430\u043F\u0443\u0441\u0442\u0438\u0442\u044C"),
        importTree?.isSuccess && (React.createElement(Grid, { container: true, justify: "center", style: { position: 'relative', marginTop: '1rem' } },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "h5", className: classes.titleGrid }, "\u0421\u0438\u0441\u0442\u0435\u043C\u0430 \u0418\u0441\u0442\u043E\u0447\u043D\u0438\u043A")),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "h5", className: classes.titleGrid }, "\u0421\u0438\u0441\u0442\u0435\u043C\u0430 \u041F\u0440\u0438\u0435\u043C\u043D\u0438\u043A")),
            React.createElement(Xwrapper, null,
                React.createElement(Grid, { container: true, style: { overflow: 'hidden', position: 'relative', marginLeft: '8px' } },
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement("div", { style: {
                                border: '1px solid #0066CC',
                                paddingLeft: '10px',
                                paddingTop: '10px',
                                marginRight: '30px',
                            } },
                            React.createElement(Scrollbars, { style: { width: '100%', height: 400 }, onScrollFrame: updateXarrow, onScrollStop: updateXarrow },
                                React.createElement(RebuildTree, { node: importTree.sourceModelForDestinationEntity.sourceModel, selectedNode: sourceDestinationObj.source })))),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement("div", { style: {
                                border: '1px solid #0066CC',
                                paddingLeft: '10px',
                                paddingTop: '10px',
                                marginRight: '30px',
                            } },
                            React.createElement(Scrollbars, { style: { width: '100%', height: 400 }, onScrollFrame: updateXarrow, onScrollStop: updateXarrow },
                                React.createElement(RebuildTree, { node: importTree.sourceModelForDestinationEntity.destinationModel, selectedNode: sourceDestinationObj.destination })))),
                    importTree.sourceModelForDestinationEntity.huPropertyLinks.map((link) => (React.createElement(Xarrow, { key: link.destinationProperty.guid, start: link.sourceHuProperty.guid, end: link.destinationProperty.guid, endAnchor: { position: 'left', offset: { x: -15 } }, headSize: 4, 
                        // strokeWidth={4}
                        color: setArrowStyles(link), path: "grid", gridBreak: "80%", passProps: {
                            onClick: () => handleConnectionsSelection(link),
                            cursor: 'pointer',
                        } }))))))),
        React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true, xs: 6, className: classes.gridBackButton, style: { display: 'flex', gap: '1rem', marginTop: '2rem' } },
                React.createElement(Button, { className: classes.backButton, onClick: () => history.push(`/migrator/${guid}/${source}/${destination}/data_mapping`) }, "\u041D\u0430\u0437\u0430\u0434"),
                React.createElement(Button, { onClick: () => history.push(`/migrator/${guid}/${source}/${destination}/import`), className: classes.nextButton }, "\u0421\u043B\u0435\u0434\u0443\u044E\u0449\u0438\u0439 \u0448\u0430\u0433")))));
};
