/* eslint-disable no-console */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, /*Popover,*/ Tooltip /*Typography*/ } from '@material-ui/core';
// import { TreeView } from '@material-ui/lab';
// import { CloseSquare, MinusSquare, PlusSquare, StyledTreeItem } from '../../icons/Icons';
// import { useXarrow } from 'react-xarrows';
const useStyles = makeStyles((_theme) => ({
    root: {
        padding: '10px',
        height: 264,
        flexGrow: 1,
        maxWidth: 400,
        userSelect: 'none',
        fontSize: '16px',
    },
    propertiesContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        left: 25,
        borderLeft: '1px dashed #0066CC',
        paddingLeft: 15,
        width: 'fit-content',
        fontSize: '16px',
    },
    propertyBox: {
        marginBottom: '10px',
        // borderBottom: '1px solid #0066CC',
    },
    selected: {
        color: '#35c13c',
    },
    widthOfContent: {
        width: 'fit-content',
        borderBottom: '1px solid #0066CC',
        // fontSize: '18px',
    },
    name: {
        fontSize: '16px',
        fontWeight: 'bold',
    },
    popoverContainer: {
        fontSize: '12px',
    },
}));
const NodePopover = ({ node }) => {
    const styles = useStyles();
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { title: React.createElement("div", { className: styles.popoverContainer },
                React.createElement("div", null,
                    React.createElement("b", null, "\u0421\u0438\u0441\u0442\u0435\u043C\u043D\u043E\u0435 \u0438\u043C\u044F: "),
                    node.systemName),
                React.createElement("div", null,
                    React.createElement("b", null, "\u0423\u043D\u0438\u043A\u0430\u043B\u044C\u043D\u044B\u0439 \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440: "),
                    node.guid)), interactive: true, arrow: true, placement: "left" },
            React.createElement("div", null,
                React.createElement("b", null, "\u0418\u043C\u044F: "),
                node.name))));
};
const PropertyPopover = ({ property, selectedNode }) => {
    const styles = useStyles();
    const setNodeStyles = () => selectedNode === property.guid ? `${styles.widthOfContent} ${styles.selected}` : styles.widthOfContent;
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { key: property.guid, className: styles.propertyBox },
            React.createElement(Tooltip, { title: React.createElement("div", { className: styles.popoverContainer },
                    React.createElement("div", { className: styles.widthOfContent },
                        React.createElement("b", null, "\u0421\u0438\u0441\u0442\u0435\u043C\u043D\u043E\u0435 \u0438\u043C\u044F: "),
                        property.systemName),
                    React.createElement("div", { className: styles.widthOfContent },
                        React.createElement("b", null, "Guid: "),
                        property.guid),
                    React.createElement("div", { className: styles.widthOfContent },
                        React.createElement("b", null, "\u0422\u0438\u043F: "),
                        property.type),
                    React.createElement("div", { className: styles.widthOfContent },
                        React.createElement("b", null, "FK: "),
                        property.foreignKeyPropertyGuid)), interactive: true, arrow: true, placement: "left" },
                React.createElement("div", { id: property.guid, className: setNodeStyles() },
                    React.createElement("b", null, "\u0418\u043C\u044F: "),
                    property.name)),
            property.propertyEntity && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: styles.name }, "\u041E\u0431\u044A\u0435\u043A\u0442 \u0441\u0432\u043E\u0439\u0441\u0442\u0432\u0430: "),
                React.createElement(Node, { node: property.propertyEntity, selectedNode: selectedNode }))))));
};
const Node = ({ node, selectedNode }) => {
    const styles = useStyles();
    return (React.createElement("div", { style: { marginLeft: 15, width: 'fit-content', fontSize: '16px' } },
        React.createElement(NodePopover, { node: node }),
        React.createElement("div", null,
            React.createElement("b", null, "\u0421\u0432\u043E\u0439\u0441\u0442\u0432\u0430: ")),
        React.createElement("div", { className: styles.propertiesContainer }, node.properties.map((property) => (
        // eslint-disable-next-line react/jsx-key
        React.createElement(PropertyPopover, { property: property, selectedNode: selectedNode }))))));
};
export const RebuildTree = (props) => {
    const styles = useStyles();
    return (React.createElement("div", { className: styles.root },
        React.createElement(Node, Object.assign({}, props))));
};
