import { makeStyles } from '@material-ui/core';
import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import { getPointPosition } from '../utils';
export const NODE_WIDTH = 250;
export const NODE_HEIGHT = 60;
const useStyles = makeStyles(() => ({
    customNode: {
        width: `${NODE_WIDTH}px`,
        height: `${NODE_HEIGHT}px`,
        paddingTop: '3px',
        paddingBottom: '3px',
        boxSizing: 'border-box',
        border: '1px solid rgb(23, 153, 252)',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        borderRadius: '4px',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    content: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'space-around',
        flexDirection: 'column',
        height: '100%',
    },
    title: {
        width: '100%',
        textAlign: 'center',
        paddingLeft: '3px',
        paddingRight: '3px',
        borderBottom: '1px solid rgb(23, 153, 252)',
        fontWeight: 600,
    },
    topPoint: {
        top: '-2px',
        background: '#555',
    },
    bottomPoint: {
        bottom: '-2px',
        background: '#555',
    },
}));
export const CustomNode = memo(({ data, targetPosition, sourcePosition }) => {
    const styles = useStyles();
    return (React.createElement("div", { className: styles.customNode },
        data.target.map((item, index) => (React.createElement(Handle, { className: styles.topPoint, key: item, id: item, type: "target", position: targetPosition, isConnectable: false, style: {
                left: `${getPointPosition(data.target.length, index)}%`,
            } }))),
        React.createElement("div", { className: styles.content },
            React.createElement("div", { className: styles.title }, data.title),
            React.createElement("div", null, data.subtitle)),
        data.source.map((item, index) => (React.createElement(Handle, { className: styles.bottomPoint, key: item, type: "source", position: sourcePosition, id: item, style: {
                left: `${getPointPosition(data.source.length, index)}%`,
            }, isConnectable: false })))));
});
