import { useMutation, useQueryClient } from 'react-query';
import { useActionLogger } from '@/shared/hooks/action-logger';
import { createNewPlugin } from '../index';
export const useCreatePlugin = () => {
    const queryClient = useQueryClient();
    const { setAction } = useActionLogger();
    return useMutation(createNewPlugin, {
        onSuccess: () => {
            queryClient.invalidateQueries('GetPluginDescriptionList');
            setAction({
                type: 'success',
                message: 'Creation of the plugin was successful',
            });
        },
        onError: (error) => {
            setAction({
                type: 'error',
                message: error.toString(),
            });
        },
    });
};
