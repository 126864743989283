import { client } from '@/shared/api';
const PATH_AUTH_API = 'Admin';
export const getPlugins = async (additionlInfo) => {
    const { data } = await client.get(`${PATH_AUTH_API}/GetPluginDescriptionList${additionlInfo}`);
    return data;
};
export const getPlugin = async (additionlInfo) => {
    const { data } = await client.get(`${PATH_AUTH_API}/GetPluginByGuid${additionlInfo}`);
    return data;
};
