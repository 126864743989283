import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { Redirect } from 'react-router';
import { useAuth } from '@/shared/controllers/auth/context';
import { routes } from '@/pages/routes';
import { useActionLogger } from '@/shared/hooks/action-logger';
import { LoginForm } from './components/LoginForm';
export const Login = () => {
    const { login, isAuth } = useAuth();
    const { setAction } = useActionLogger();
    const [error] = useState('');
    useEffect(() => { }, []);
    const handleLogin = async (data) => {
        const res = await login(data);
        if (!res.isSuccess && res.errorMessage) {
            // setError(res.errorMessage);
            setAction({
                type: 'error',
                message: res.errorMessage.toString(),
            });
        }
    };
    if (isAuth) {
        return React.createElement(Redirect, { to: routes.management });
    }
    return (React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" },
        React.createElement(LoginForm, { error: error, onSubmit: handleLogin })));
};
