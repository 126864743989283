export const findId = (id, values) => {
    const stack = [...values];
    while (stack.length > 0) {
        const value = stack.pop();
        if (value?.PropertyEntity && 'Guid' in value.PropertyEntity && value.PropertyEntity.$id === id) {
            return value.PropertyEntity;
        }
        if (value?.PropertyEntity && 'Guid' in value.PropertyEntity && value.PropertyEntity.Properties?.$values) {
            stack.push(...value.PropertyEntity.Properties.$values);
        }
    }
    return null;
};
export const findNodeById = (id, tree) => {
    if (id === '1')
        return tree;
    return findId(id, tree.Properties.$values);
};
