import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { PageLayout } from '@/shared/components/PageLayout';
import { useGetPluginInstances } from '@/shared/services/instances/hooks/use-get-plugin-instances';
import { Menu } from '@/shared/components/Menu';
import Paper from '@material-ui/core/Paper';
import { useStyles } from "@/pages/PluginInstances/styles";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, } from '@material-ui/core';
export const PluginInstances = () => {
    const history = useHistory();
    const classes = useStyles();
    const columns = [
        { value: 'guid', label: 'Уникальный идентификатор', minWidth: 290 },
        { value: 'name', label: 'Название агента' },
        // { value: 'host', label: 'Host' },
        // { value: 'port', label: 'Port' },
        // { value: 'stateName', label: 'State Name' },
        // { value: 'httpHeader', label: 'Http Header' },
    ];
    const [additionalValue, setAdditionalValue] = useState('');
    const { data: pluginInstances, refetch } = useGetPluginInstances(additionalValue);
    const rowsPerPageSetting = [10, 25, 50, 100];
    const handleChangePage = (_, newPage) => {
        setAdditionalValue(`?pageSize=${pluginInstances?.pageSize}&pageNumber=${newPage + 1}`);
        refetch();
    };
    const handleChangeRowsPerPage = (event) => {
        setAdditionalValue(`?pageSize=${Number(event.target.value)}`);
        refetch();
    };
    return (React.createElement(PageLayout, null,
        React.createElement("div", null,
            React.createElement(Box, { component: "div", display: "inline" },
                React.createElement(Menu, { addButtonProps: {
                        disabled: false,
                        handleClick: () => history.push('/plugin-instance/add'),
                        label: 'Добавить агент',
                    } })),
            React.createElement(TableContainer, { component: Paper, className: classes.tableHideOverflow },
                React.createElement(Typography, { variant: "h4", className: classes.marginBott }, "\u0410\u0433\u0435\u043D\u0442\u044B"),
                React.createElement(Table, { className: classes.table },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null, columns.map((column) => (React.createElement(TableCell, { key: column.value, align: column.align, className: classes.tableRowHead, style: { width: column.minWidth } }, column.label))))),
                    React.createElement(TableBody, null, pluginInstances?.pluginInstanceInfoWithHeadAddresses?.map((plugin) => (React.createElement(TableRow, { key: plugin.guid }, columns.map((column) => {
                        const value = plugin[column.value];
                        column.value;
                        return (React.createElement(TableCell, { component: "section", scope: "row", key: column.value, className: classes.tableRow, style: { minWidth: column.minWidth } }, column.value === 'guid' ?
                            (React.createElement(NavLink, { to: `/plugin-instance/${plugin.guid}` }, (column.component && column.component(value)) || value))
                            :
                                (column.component && column.component(value)) || value));
                    })))))),
                pluginInstances?.pluginInstanceInfoWithHeadAddresses && (React.createElement(TablePagination, { labelRowsPerPage: 'Cтрок на странице', rowsPerPageOptions: rowsPerPageSetting, component: "div", count: pluginInstances?.totalCount, rowsPerPage: pluginInstances?.pageSize, page: pluginInstances?.currentPage - 1, onChangePage: handleChangePage, onChangeRowsPerPage: handleChangeRowsPerPage }))))));
};
