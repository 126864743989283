import { client } from '@/shared/api';
import { getTokenByType } from '@/shared/api/util';
const PATH_AUTH_API = 'Admin';
export const setToken = (tokenType, token) => {
    localStorage.setItem(tokenType, token);
};
export const setExpire = (expire) => {
    localStorage.setItem('expire', expire);
};
const getExpire = () => localStorage.getItem('expire');
const setAuthData = ({ accessToken, refreshToken, expire, }) => {
    setToken('accessToken', accessToken);
    setToken('refreshToken', refreshToken);
    setExpire(expire);
};
export const isAccessTokenExpired = () => {
    const expire = getExpire();
    if (expire === null)
        return false;
    const ONE_MINUTE = 1000 * 60;
    const accessTokenExpDate = new Date(expire).getTime() - ONE_MINUTE * 5;
    const nowTime = new Date().getTime();
    return accessTokenExpDate <= nowTime;
};
export const hasRefreshToken = () => Boolean(getTokenByType('refreshToken'));
export const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expire');
};
export const login = async (user) => {
    const { name, password } = user;
    const response = await client.post(`${PATH_AUTH_API}/Authenticate`, {
        name,
        password,
    });
    const { token: accessToken, expiresUtc: expire, refreshToken, isSuccess } = response.data;
    if (!isSuccess) {
        logout();
        return {
            isSuccess,
            errorMessage: response.data?.errorMessage || '',
        };
    }
    setAuthData({
        accessToken,
        expire,
        refreshToken,
    });
    return { isSuccess };
};
export const refreshTokens = async () => {
    const response = await client.post(`${PATH_AUTH_API}/AuthenticateByRefreshToken`, {
        expiredToken: getTokenByType('accessToken'),
        refreshToken: getTokenByType('refreshToken'),
    });
    const { token: accessToken, expiresUtc: expire, refreshToken, isSuccess } = response.data;
    if (!isSuccess) {
        logout();
        return { isSuccess };
    }
    setAuthData({
        accessToken,
        expire,
        refreshToken,
    });
    return { isSuccess };
};
