import { useMutation } from 'react-query';
import { useActionLogger } from '@/shared/hooks/action-logger';
import { deleteLink } from '..';
export const useDeleteMigLink = () => {
    // const queryClient = useQueryClient();
    const { setAction } = useActionLogger();
    return useMutation(deleteLink, {
        onSuccess: () => {
            setAction({
                type: 'success',
                message: 'Removing the link was successful',
            });
        },
        onError: (error) => {
            setAction({
                type: 'error',
                message: error.toString(),
            });
        },
    });
};
