import React, { useEffect, useState } from 'react';
import { PageLayout } from '@/shared/components/PageLayout';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, makeStyles } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import { useGetAllChains } from '../IntegrationChains/services/hooks/use-get-all-chains';
import { useCreateMigratorEntity } from './services/hooks/use-create-migrator-entity';
import { useInitiateChainForMetadata } from './services/hooks/use-initiate-chain';
import { useGetInfoByGuid } from './services/hooks/use-get-info-by-guid';
const useStyles = makeStyles((theme) => ({
    title: {
        paddingTop: '2rem',
    },
    container: {
        marginTop: '3rem',
        justifyContent: 'center',
        alignItems: 'center',
    },
    gridForm: {
        minWidth: '650px',
    },
    buttonTurn: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        height: '55px',
    },
    gridButton: {
        marginTop: '2rem',
    },
    buttonSave: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        height: '55px',
    },
    form: {
        marginTop: '2rem',
        textAlign: 'center',
    },
    gridBackButton: {
        marginTop: '6rem',
    },
    backButton: {
        fontWeight: 700,
        fontSize: '16px',
    },
    nextButton: {
        fontWeight: 700,
        fontSize: '16px',
        color: '#0066CC',
    },
    gridNextButton: {
        marginTop: '6rem',
    },
    griTextName: {
    // textAlign: 'end',
    },
    input: {
        marginRight: theme.spacing(1),
        flex: 1,
        flexShrink: 0,
        marginBottom: '1px',
    }
}));
export const MigratorPageSource = () => {
    const { guid, source, destination } = useParams();
    const styles = useStyles();
    const history = useHistory();
    const [additionalValue, setAdditionalValue] = useState('');
    const [nameOfSource, setNameOfSource] = useState('');
    const [systemNameOfSource, setSystemNameOfSource] = useState('');
    const [integrationChain, setIntagrationChain] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const handleGetName = (event) => {
        setNameOfSource(event.target.value);
    };
    const handleGetSystemName = (event) => {
        setSystemNameOfSource(event.target.value);
    };
    const { data: chainsList } = useGetAllChains(additionalValue);
    // console.log(chainsList);
    const { data: getInfoByGuid, refetch } = useGetInfoByGuid(`?migratorGuid=${guid}`);
    const entitySource = getInfoByGuid?.migratorInfo.info.sourceMetadataIntegrationChain;
    const { promiseInProgress } = usePromiseTracker();
    const { mutate: createEntity, data: createEntityResponse, isSuccess: disabledSaveButton } = useCreateMigratorEntity();
    const { mutate: initiateChain, isSuccess: disableInitiateChainButton } = useInitiateChainForMetadata();
    useEffect(() => {
        if (promiseInProgress) {
            setTimeout(() => {
                setIsLoaded(true);
            }, 300);
        }
    }, [promiseInProgress]);
    const handleSaveEntity = () => {
        if (nameOfSource && systemNameOfSource) {
            const newEntity = {
                migEntity: {
                    name: nameOfSource,
                    systemName: systemNameOfSource,
                    isSourceSystem: true,
                },
                migratorGuid: guid
            };
            setIsReadOnly(true);
            createEntity(newEntity);
            refetch();
        }
    };
    const handleGetChain = (event) => {
        setIntagrationChain(event.target.value);
    };
    const handleSetInitiateChain = () => {
        if (createEntityResponse?.isSuccess && integrationChain) {
            const chainData = chainsList?.chainDescriptions.find(chain => chain.code === integrationChain);
            if (chainData) {
                const newInitiateChain = {
                    chainCodeDto: {
                        code: chainData.code,
                        entityInfo: {
                            name: nameOfSource,
                            systemName: systemNameOfSource,
                            isSourceSystem: true,
                            guid: createEntityResponse.entityGuid
                        }
                    },
                    migratorGuid: guid
                };
                initiateChain(newInitiateChain);
                refetch();
            }
        }
    };
    const handleSetChainInExistingEntity = () => {
        if (createEntityResponse?.isSuccess && integrationChain) {
            const chainData = chainsList?.chainDescriptions.find(chain => chain.code === integrationChain);
            if (chainData && entitySource?.entityInfo) {
                const newInitiateChain = {
                    chainCodeDto: {
                        code: chainData.code,
                        entityInfo: {
                            name: entitySource?.entityInfo.name,
                            systemName: entitySource?.entityInfo.systemName,
                            isSourceSystem: true,
                            guid: entitySource?.entityInfo.guid
                        }
                    },
                    migratorGuid: guid
                };
                initiateChain(newInitiateChain);
                refetch();
            }
        }
    };
    return (React.createElement(PageLayout, null,
        React.createElement(Typography, { variant: "h4", className: styles.title, style: { margin: '1rem', padding: 0, marginLeft: '8px', marginBottom: '2rem' } }, "\u0418\u043C\u043F\u043E\u0440\u0442 \u043C\u0435\u0442\u0430\u0434\u0430\u043D\u043D\u044B\u0445 \u0441\u0438\u0441\u0442\u0435\u043C\u044B-\u0438\u0441\u0442\u043E\u0447\u043D\u0438\u043A\u0430"),
        isLoaded && entitySource && React.createElement(Grid, { spacing: 1, className: styles.container },
            entitySource?.entityInfo && entitySource?.entityInfo.name && entitySource?.entityInfo.systemName ? (
            // <Grid container spacing={1} style={{fontSize: '18px', color: 'grey'}}>
            //   <Grid item xs={6} style={{textAlign: 'end'}}>Имя: </Grid>
            //   <Grid item xs={6}>{entitySource.entityInfo.name}</Grid>
            //   <Grid item xs={6} style={{textAlign: 'end'}}>Системное имя: </Grid>
            //   <Grid item xs={6}>{entitySource.entityInfo.systemName}</Grid>
            // </Grid>
            React.createElement(Grid, { item: true, xs: 10, style: { marginLeft: '8px' } },
                React.createElement(TextField, { className: styles.input, label: '\u0418\u043C\u044F', value: entitySource.entityInfo.name, InputProps: { readOnly: true }, InputLabelProps: { shrink: true } }),
                React.createElement(TextField, { className: styles.input, label: '\u0421\u0438\u0441\u0442\u0435\u043C\u043D\u043E\u0435 \u0438\u043C\u044F', value: entitySource.entityInfo.systemName, InputProps: { readOnly: true }, InputLabelProps: { shrink: true } }))) : (React.createElement(React.Fragment, null,
                React.createElement(Grid, { item: true, xs: 6, className: styles.griTextName, style: { paddingLeft: 8, display: 'flex', gap: '1rem' } },
                    React.createElement(TextField, { id: "outlined-basic", label: "\u0418\u043C\u044F", placeholder: "\u0418\u043C\u044F", 
                        // multiline
                        variant: "outlined", InputProps: { readOnly: isReadOnly }, value: nameOfSource, onChange: handleGetName, required: true, style: { width: '181px' } }),
                    React.createElement(TextField, { id: "outlined-basic", label: "\u0421\u0438\u0441\u0442\u0435\u043C\u043D\u043E\u0435 \u0438\u043C\u044F", placeholder: "\u0421\u0438\u0441\u0442\u0435\u043C\u043D\u043E\u0435 \u0438\u043C\u044F", 
                        // multiline
                        variant: "outlined", InputProps: { readOnly: isReadOnly }, value: systemNameOfSource, onChange: handleGetSystemName, required: true, style: { width: '167px' } }),
                    React.createElement(Button, { variant: "contained", size: "medium", className: styles.buttonSave, onClick: handleSaveEntity, disabled: disabledSaveButton }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")))),
            entitySource?.code ? (React.createElement(Grid, { item: true, xs: 9, style: { textAlign: 'left', marginTop: '2rem', marginLeft: '8px' } },
                React.createElement(TextField, { style: { minWidth: '300px' }, className: styles.input, label: '\u0418\u043D\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u043E\u043D\u043D\u044B\u0439 \u043F\u0440\u043E\u0446\u0435\u0441\u0441', value: entitySource.code, InputProps: { readOnly: true }, InputLabelProps: { shrink: true } }))) : (React.createElement(React.Fragment, null,
                React.createElement(Grid, { item: true, xs: 6, className: styles.form, style: { display: 'flex', gap: '1rem', paddingLeft: 8 } },
                    React.createElement(FormControl, { variant: "outlined", style: { width: '364px' } },
                        React.createElement(InputLabel, { id: "demo-simple-select-outlined-label" }, "\u0418\u043D\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u043E\u043D\u043D\u044B\u0435 \u043F\u0440\u043E\u0446\u0435\u0441\u0441\u044B"),
                        React.createElement(Select, { labelId: "demo-simple-select-outlined-label", id: "demo-simple-select-outlined", value: integrationChain, label: "\u0418\u043D\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u043E\u043D\u043D\u044B\u0435 \u043F\u0440\u043E\u0446\u0435\u0441\u0441\u044B", onChange: handleGetChain }, chainsList?.chainDescriptions.map(chain => (React.createElement(MenuItem, { key: chain.guid, value: chain.code }, chain.code))))),
                    entitySource?.entityInfo.name && entitySource.entityInfo.systemName ? (React.createElement(Grid, { item: true, xs: 3, style: { textAlign: 'start' } },
                        React.createElement(Button, { variant: 'contained', size: 'medium', className: styles.buttonTurn, onClick: handleSetChainInExistingEntity, disabled: disableInitiateChainButton }, "\u0417\u0430\u043F\u0443\u0441\u0442\u0438\u0442\u044C"))) : (React.createElement(Grid, { item: true, xs: 3, style: { textAlign: 'start' } },
                        React.createElement(Button, { variant: 'contained', size: 'medium', className: styles.buttonTurn, onClick: handleSetInitiateChain, disabled: disableInitiateChainButton }, "\u0417\u0430\u043F\u0443\u0441\u0442\u0438\u0442\u044C")))))),
            React.createElement(Grid, { item: true, xs: 6, style: { marginTop: '2rem', display: 'flex', gap: '1rem' } },
                React.createElement(Button, { className: styles.backButton, onClick: () => history.push(`/migrator/${guid}`) }, "\u041D\u0430\u0437\u0430\u0434"),
                React.createElement(Button, { onClick: () => history.push(`/migrator/${guid}/destination/${source}/${destination}`), className: styles.nextButton }, "\u0421\u043B\u0435\u0434\u0443\u044E\u0449\u0438\u0439 \u0448\u0430\u0433")))));
};
