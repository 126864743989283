import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import { TreeNodeType } from '../../types';
import { CloseSquare, MinusSquare, PlusSquare, StyledTreeItem } from '../../icons/Icons';
import { findNodeById } from '../../utils';
import { useStyles } from './trees.styles';
import { ValueInfo } from './ValueInfo';
const Node = ({ node, onNodeSelect, selectedNodeId, parentNodeId, findNode, onExpand, onCollapse, enable }) => {
    const classes = useStyles();
    const { $id, Guid, Name, SystemName, Properties, $values, Type, $ref, PropertyEntity } = node;
    const refNode = $ref ? findNode($ref) : null;
    return (React.createElement(React.Fragment, null,
        $id && React.createElement(StyledTreeItem, { nodeId: `${parentNodeId}/ID:${Guid}`, label: ` ID: ${$id}` }),
        Guid && React.createElement(StyledTreeItem, { label: `Guid: ${Guid}`, nodeId: `${parentNodeId}/Guid:${Guid}` }),
        Name && React.createElement(StyledTreeItem, { label: `Name: ${Name}`, nodeId: `${parentNodeId}/Name:${Guid}` }),
        SystemName && (React.createElement(StyledTreeItem, { label: `SystemName: ${SystemName}`, nodeId: `${parentNodeId}/SystemName:${Guid}` })),
        Type && React.createElement(StyledTreeItem, { label: `Type: ${Type}`, nodeId: `${parentNodeId}/Type:${Guid}` }),
        PropertyEntity && (React.createElement(StyledTreeItem, { label: React.createElement("div", { id: `${parentNodeId}/PropertyEntity:${Guid}`, className: classes.content }, "PropertyEntity"), nodeId: `${parentNodeId}/PropertyEntity:${Guid}`, expandIcon: React.createElement("button", { style: { border: 'none' }, onClick: (e) => {
                    e.stopPropagation;
                    onExpand(`${parentNodeId}/PropertyEntity:${Guid}`);
                } },
                React.createElement(PlusSquare, null)), collapseIcon: React.createElement("button", { style: { border: 'none' }, onClick: (e) => {
                    e.stopPropagation;
                    onCollapse(`${parentNodeId}/PropertyEntity:${Guid}`);
                } },
                React.createElement(MinusSquare, null)), className: classes.content, onLabelClick: (event) => event.preventDefault() },
            React.createElement(Node, { node: PropertyEntity, parentNodeId: `${parentNodeId}/PropertyEntity:${Guid}`, onNodeSelect: onNodeSelect, selectedNodeId: selectedNodeId, findNode: findNode, onExpand: onExpand, onCollapse: onCollapse, enable: enable }))),
        Properties && (React.createElement(StyledTreeItem, { label: React.createElement("div", { className: classes.content, id: `${parentNodeId}/Properties:${Guid}` }, "Properties"), nodeId: `${parentNodeId}/Properties:${Guid}`, expandIcon: React.createElement("button", { style: { border: 'none' }, onClick: (e) => {
                    e.stopPropagation;
                    onExpand(`${parentNodeId}/Properties:${Guid}`);
                } },
                React.createElement(PlusSquare, null)), collapseIcon: React.createElement("button", { style: { border: 'none' }, onClick: (e) => {
                    e.stopPropagation;
                    onCollapse(`${parentNodeId}/Properties:${Guid}`);
                } },
                React.createElement(MinusSquare, null)), className: classes.content, onLabelClick: (event) => event.preventDefault() },
            React.createElement(Node, { node: Properties, parentNodeId: `${parentNodeId}/Properties:${Guid}`, onNodeSelect: onNodeSelect, selectedNodeId: selectedNodeId, findNode: findNode, onExpand: onExpand, onCollapse: onCollapse, enable: enable }))),
        $values && (React.createElement(StyledTreeItem, { label: React.createElement("div", { id: `${parentNodeId}/Values`, className: classes.content }, "Values"), nodeId: `${parentNodeId}/Values`, 
            // id={`${parentNodeId}/Values`}
            expandIcon: React.createElement("button", { style: { border: 'none' }, onClick: (e) => {
                    e.stopPropagation;
                    onExpand(`${parentNodeId}/Values`);
                } },
                React.createElement(PlusSquare, null)), collapseIcon: React.createElement("button", { style: { border: 'none' }, onClick: (e) => {
                    e.stopPropagation;
                    onCollapse(`${parentNodeId}/Values`);
                } },
                React.createElement(MinusSquare, null)), onLabelClick: (event) => event.preventDefault(), className: classes.content }, $values.map((value) => {
            const isSelected = `${parentNodeId}/Values/${value.Guid}` === selectedNodeId;
            if (value.Type !== TreeNodeType.Ref) {
                return (React.createElement(StyledTreeItem, { key: value.$id, className: classes.content, label: React.createElement(ValueInfo, { value: value, isSelected: isSelected, parentNodeId: parentNodeId, onNodeSelect: onNodeSelect }), 
                    // id={`${parentNodeId}/Values/${value.Guid}`}
                    nodeId: `${parentNodeId}/Values/${value.Guid}` }));
            }
            return (React.createElement(StyledTreeItem, { key: value.$id, label: React.createElement("div", { id: `${parentNodeId}/Values/${value.Guid}`, 
                    // onClick={(e) => (enable === true && (e.stopPropagation, onNodeSelect(`${parentNodeId}/Values/${value.Guid}`)))}
                    style: { color: '#0066CC', width: 'fit-content' }, className: isSelected ? classes.highlighted : undefined }, value.Name), nodeId: `${parentNodeId}/Values/${value.Guid}`, style: { width: 'fit-content' }, expandIcon: React.createElement("button", { style: { border: 'none' }, onClick: (e) => {
                        isSelected && e.stopPropagation;
                        onExpand(`${parentNodeId}/Values/${value.Guid}`);
                    } },
                    React.createElement(PlusSquare, null)), collapseIcon: React.createElement("button", { style: { border: 'none' }, onClick: (e) => {
                        isSelected && e.stopPropagation;
                        onCollapse(`${parentNodeId}/Values/${value.Guid}`);
                    } },
                    React.createElement(MinusSquare, null)), onLabelClick: (event) => event.preventDefault() },
                React.createElement(Node, { node: value, parentNodeId: `${parentNodeId}/Values/${value.Guid}`, onNodeSelect: onNodeSelect, selectedNodeId: selectedNodeId, findNode: findNode, onExpand: onExpand, onCollapse: onCollapse, enable: enable })));
        }))),
        refNode && (React.createElement(StyledTreeItem, { label: React.createElement("div", { id: `${parentNodeId}/PropertyEntityRef:${Guid}`, className: classes.content }, "PropertyEntityRef"), nodeId: `${parentNodeId}/PropertyEntityRef:${Guid}`, expandIcon: React.createElement("button", { style: { border: 'none' }, onClick: (e) => {
                    e.stopPropagation;
                    onExpand(`${parentNodeId}/PropertyEntityRef:${Guid}`);
                } },
                React.createElement(PlusSquare, null)), collapseIcon: React.createElement("button", { style: { border: 'none' }, onClick: (e) => {
                    e.stopPropagation;
                    onCollapse(`${parentNodeId}/PropertyEntityRef:${Guid}`);
                } },
                React.createElement(MinusSquare, null)), className: classes.content, onLabelClick: (event) => event.preventDefault() },
            React.createElement(Node, { node: refNode, parentNodeId: `${parentNodeId}/PropertyEntityRef:${Guid}`, onNodeSelect: onNodeSelect, selectedNodeId: selectedNodeId, findNode: findNode, onExpand: onExpand, onCollapse: onCollapse, enable: enable })))));
};
export const Tree = (props) => {
    const classes = useStyles();
    const { node } = props;
    const findNode = (id) => findNodeById(id, node);
    return (React.createElement(TreeView, { className: classes.root, defaultExpanded: ['1'], defaultEndIcon: React.createElement(CloseSquare, null) },
        React.createElement(Node, Object.assign({ findNode: findNode }, props))));
};
