import { useMutation, useQueryClient } from 'react-query';
import { useActionLogger } from '@/shared/hooks/action-logger';
import { deletePluginInstance } from '../index';
export const useDeleteInstance = () => {
    const queryClient = useQueryClient();
    const { setAction } = useActionLogger();
    return useMutation('DeletePluginInstance', deletePluginInstance, {
        onSuccess: () => {
            queryClient.invalidateQueries('GetAllPluginInstances');
            setAction({
                type: 'success',
                message: 'Removing of the plugin instance was successful',
            });
        },
        onError: (error) => {
            setAction({
                type: 'error',
                message: error.toString(),
            });
        },
    });
};
