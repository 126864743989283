import React, { useEffect } from 'react';
import { PageLayout } from '@/shared/components/PageLayout';
import { useHistory } from 'react-router-dom';
import { Box, Button, Paper } from '@material-ui/core';
import { FormAdd } from './components/FormAdd';
import { useStyles } from './migrator.styles';
import { useCreateMigrator } from './services/hooks/use-create-migrator';
import { useGetMiratorsList } from './services/hooks/use-get-migrators-list';
export const MigratorPageAdd = () => {
    const history = useHistory();
    const classes = useStyles();
    // const [validationBackend, setValidationBackend] = useState(null);
    const { mutate: creeteNewMigrator, data: createMigratorResponse, isLoading: sendingCreateMigrator, } = useCreateMigrator();
    const { refetch } = useGetMiratorsList();
    useEffect(() => {
        if (createMigratorResponse?.isSuccess) {
            refetch();
            history.push('/migrators');
        }
    }, [createMigratorResponse]);
    return (React.createElement(PageLayout, null,
        React.createElement("div", null,
            React.createElement(Box, { component: "div", display: "inline" },
                React.createElement(Button, { variant: "contained", size: "medium", className: classes.colorButtonBack, onClick: () => { history.push('/migrators'); } }, "\u041D\u0430\u0437\u0430\u0434 \u043A \u0441\u043F\u0438\u0441\u043A\u0443"))),
        React.createElement(Paper, null,
            React.createElement(FormAdd, { onSubmit: creeteNewMigrator, 
                // migratorError={validationBackend}
                disabledSaveButton: sendingCreateMigrator }))));
};
