import React, { useEffect, useState } from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { client } from '@/shared/api';
import { Autocomplete } from '@material-ui/lab';
const PATH_AUTH_API = 'Admin';
const useStyles = makeStyles(() => ({
    drop: {
        minWidth: '270px',
        // height: '11%',
        height: '56px',
    },
}));
export const DropDownHashCode = ({ onClickRow, valueDescription, setValueDescription }) => {
    const styles = useStyles();
    const [hash, setHashCode] = useState();
    const changeHashCode = (data) => {
        onClickRow(data);
        setValueDescription(data);
    };
    useEffect(() => {
        async function executeFetch() {
            const data = await GetHashValue();
            setHashCode(data);
        }
        executeFetch();
    }, []);
    // ToDo: add types
    async function GetHashValue() {
        try {
            const { data } = await client.get(`${PATH_AUTH_API}/GetHashTags`);
            return data;
        }
        catch (e) {
            alert(e);
            const emptyArray = [];
            return emptyArray;
        }
    }
    return (React.createElement(Autocomplete, { className: styles.drop, multiple: true, 
        // id="size-small-standard-multi"
        id: 'combo-box-demo', 
        // size="small"
        value: valueDescription, options: hash?.hashTags || [], getOptionLabel: (option) => option, onChange: (_event, value) => changeHashCode(value), renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { style: { height: '56px' }, variant: "outlined", label: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043A\u043B\u044E\u0447", placeholder: "\u041A\u043B\u044E\u0447" }))) }));
};
