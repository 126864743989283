export var ArrowType;
(function (ArrowType) {
    // Arrow which connects simple nodes(leafs)
    ArrowType["Simple"] = "Simple";
    // Arrow which connects folder and node
    ArrowType["High"] = "High";
})(ArrowType || (ArrowType = {}));
export var TreeNodeType;
(function (TreeNodeType) {
    TreeNodeType[TreeNodeType["Type1"] = 1] = "Type1";
    TreeNodeType[TreeNodeType["Type2"] = 2] = "Type2";
    TreeNodeType[TreeNodeType["Type3"] = 3] = "Type3";
    TreeNodeType[TreeNodeType["Ref"] = 4] = "Ref";
    TreeNodeType[TreeNodeType["Type5"] = 5] = "Type5";
    TreeNodeType[TreeNodeType["Type6"] = 6] = "Type6";
    TreeNodeType[TreeNodeType["Type7"] = 7] = "Type7";
    TreeNodeType[TreeNodeType["Type8"] = 8] = "Type8";
    TreeNodeType[TreeNodeType["Type9"] = 9] = "Type9";
    TreeNodeType[TreeNodeType["Type10"] = 10] = "Type10";
})(TreeNodeType || (TreeNodeType = {}));
export var MigratorState;
(function (MigratorState) {
    MigratorState[MigratorState["Unregistered"] = 1] = "Unregistered";
    MigratorState[MigratorState["Registered"] = 2] = "Registered";
    MigratorState[MigratorState["SystemsCreated"] = 3] = "SystemsCreated";
    MigratorState[MigratorState["MetaDataReceived"] = 4] = "MetaDataReceived";
    MigratorState[MigratorState["MappingCreated"] = 5] = "MappingCreated";
    MigratorState[MigratorState["ModelCreated"] = 6] = "ModelCreated";
    MigratorState[MigratorState["KeysReceived"] = 7] = "KeysReceived";
    MigratorState[MigratorState["ReadyForMigration"] = 8] = "ReadyForMigration";
})(MigratorState || (MigratorState = {}));
