import React from 'react';
import { IconButton, Drawer as DrawerBase, makeStyles } from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
        paddingTop: '96px',
    },
    closeBtn: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '96px',
        height: '96px',
        backgroundColor: '#000000',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#000000',
        },
    },
    iconClose: {
        height: '56px',
        width: '56px',
        fill: '#FFFFFF',
    },
}));
export const Drawer = ({ children, isOpen, onClose }) => {
    const styles = useStyles();
    return (React.createElement(DrawerBase, { anchor: "right", open: isOpen, onClose: onClose },
        React.createElement("div", { className: styles.root },
            React.createElement(IconButton, { className: styles.closeBtn, onClick: onClose },
                React.createElement(CloseRoundedIcon, { className: styles.iconClose }),
                ' '),
            children)));
};
