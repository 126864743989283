import React, { useState } from 'react';
import { Button, createStyles, FormControlLabel, makeStyles, Switch, TextField, Typography } from '@material-ui/core';
const useStyles = makeStyles(() => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '800px',
        padding: '72px',
    },
    header: {
        fontWeight: 600,
        marginBottom: '32px',
    },
    input: {
        width: '370px',
        marginBottom: '16px',
    },
    switch: {
        marginLeft: 0,
        marginBottom: '16px',
    },
}));
export const FormAddingUser = ({ isEditing = false }) => {
    const styles = useStyles();
    const [isAdmin, setIsAdmin] = useState(false);
    const toggleChecked = () => {
        setIsAdmin((prev) => !prev);
    };
    return (React.createElement("form", { className: styles.root, noValidate: true, autoComplete: "off" },
        React.createElement(Typography, { className: styles.header, variant: "h4", component: "h2" }, isEditing ? 'Редактирование пользователя' : 'Новый пользователь'),
        isEditing && (React.createElement(FormControlLabel, { className: styles.switch, control: React.createElement(Switch, { color: "primary" }), label: "\u0410\u043A\u0442\u0438\u0432\u0435\u043D", labelPlacement: "end" })),
        React.createElement(TextField, { className: styles.input, variant: "outlined", required: true, label: "\u041B\u043E\u0433\u0438\u043D" }),
        React.createElement(TextField, { className: styles.input, variant: "outlined", required: true, label: "\u0418\u043C\u044F" }),
        React.createElement(TextField, { type: "email", className: styles.input, variant: "outlined", required: true, label: "Email" }),
        React.createElement(TextField, { type: "password", className: styles.input, variant: "outlined", required: true, label: "\u041F\u0430\u0440\u043E\u043B\u044C" }),
        React.createElement(TextField, { type: "password", className: styles.input, variant: "outlined", required: true, label: "\u041F\u043E\u0432\u0442\u043E\u0440\u0438\u0442\u0435 \u043F\u0430\u0440\u043E\u043B\u044C" }),
        React.createElement(FormControlLabel, { className: styles.switch, control: React.createElement(Switch, { color: "primary", checked: isAdmin, onChange: toggleChecked }), label: "\u0410\u0434\u043C\u0438\u043D\u0438\u0441\u0442\u0440\u0430\u0442\u043E\u0440", labelPlacement: "end" }),
        React.createElement(FormControlLabel, { className: styles.switch, control: React.createElement(Switch, { color: "primary" }), label: "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0430 \u0438\u043D\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u0439", labelPlacement: "end" }),
        React.createElement(FormControlLabel, { className: styles.switch, control: React.createElement(Switch, { color: "primary" }), label: "\u041F\u0440\u043E\u0441\u043C\u043E\u0442\u0440 \u043D\u0430\u0441\u0442\u0440\u043E\u0435\u043A \u0438\u043D\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u0439", labelPlacement: "end" }),
        React.createElement(Button, { variant: "contained", color: "primary" }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")));
};
