import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
    root: {
        padding: '10px',
        height: 264,
        flexGrow: 1,
        maxWidth: 400,
        userSelect: 'none',
    },
    content: {
        width: 'fit-content',
    },
    colorOfType: {
        color: '#0066CC',
    },
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    highlighted: {
        fontWeight: 'bold',
    },
    input: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    titleDataMapping: {
        margin: '1rem',
    },
    titleDataGrid: {
        margin: '1rem',
        textAlign: 'center',
    },
    // itemGrid: {
    //     paddingLeft: '10px',
    //     // border: '1rem',
    // },
    actions: {
        display: 'flex',
        justifyContent: 'center',
    },
    box: {
        overflow: 'hidden',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    modalTitle: {
        margin: 0,
        padding: theme.spacing(2),
    },
    gridButtons: {
        marginTop: '2rem',
    },
    buttonBack: {
        fontWeight: 700,
        fontSize: '16px'
    },
    gridNextButton: {
        textAlign: 'center',
    },
    buttonNext: {
        fontWeight: 700,
        fontSize: '16px',
        color: '#0066CC',
    }
}));
