import { PageLayout } from '@/shared/components/PageLayout';
import { Box, Button, CssBaseline, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
const useStyles = makeStyles(() => ({
    root: {
        width: 'auto',
        height: 'auto',
    },
    colorButtonBack: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        marginTop: '1rem',
    },
    container: {
        overflow: 'hidden',
        // minWidth: 400,
        width: 800,
    },
    tableRow: {
        padding: '5px 16px 5px 16px',
        fontSize: '0.8rem',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '0px',
        tableLayout: 'auto',
        lineHeight: '1.5rem',
    },
    tableParentHead: {
        width: '100%',
        padding: '5px 16px 5px 16px',
        fontSize: '0.8rem',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '0px',
        tableLayout: 'auto',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        fontWeight: 'bolder',
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
    title: {
        marginTop: '1rem',
        marginBottom: '1rem',
        marginLeft: '1rem',
    },
    paper: {
        width: '800px',
        marginTop: '1rem',
        marginBottom: '1rem',
        marginLeft: '1rem',
    }
}));
const columns = [
    { value: 'key', label: 'Ключ', minWidth: 50 },
    { value: 'guid', label: 'GUID Экземпляра процесса', minWidth: 290 },
    { value: 'isCompleted', label: 'Завершен', minWidth: 30 },
    { value: 'state', label: 'Статус процесса', minWidth: 70 },
];
function createData(key, guid, isCompleted, state) {
    return { key, guid, isCompleted, state };
}
const rows = [
    createData('', '2b69f00c-28eb-4b08-b5cc-58a7c81f6e4e', 'Да', ''),
    createData('', 'a703fa44-15d7-4ee7-8f5b-635d5506f0e3', 'Нет', ''),
    createData('', 'bb241647-ef30-4db2-9d9b-59fb1f63bd4c', 'Нет', ''),
];
export const DataMigrationEntity = () => {
    const classes = useStyles();
    const history = useHistory();
    const { guid, source, destination, name } = useParams();
    return (React.createElement(PageLayout, null,
        React.createElement("div", { style: { width: '100%', marginBottom: '1rem' } },
            React.createElement(Box, { component: "div", display: "inline" },
                React.createElement(Button, { variant: "contained", size: "medium", className: classes.colorButtonBack, onClick: () => { history.push(`/migrator/${guid}/${source}/${destination}/dataMigration`); } }, "\u041D\u0430\u0437\u0430\u0434 \u043A \u0441\u043F\u0438\u0441\u043A\u0443"))),
        React.createElement(Paper, { elevation: 3, className: classes.paper },
            React.createElement(CssBaseline, null),
            React.createElement(TableContainer, { className: classes.container },
                React.createElement(Typography, { variant: "h5", className: classes.title }, name),
                React.createElement(Table, null,
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null, columns.map(column => (React.createElement(TableCell, { key: column.value, align: column.align, className: classes.tableParentHead, style: { width: column.minWidth } }, column.label))))),
                    React.createElement(TableBody, null, rows.map(row => (React.createElement(TableRow, { key: row.guid },
                        React.createElement(TableCell, { className: classes.tableRow }, row.key),
                        React.createElement(TableCell, { className: classes.tableRow }, row.guid),
                        React.createElement(TableCell, { className: classes.tableRow }, row.isCompleted),
                        React.createElement(TableCell, { className: classes.tableRow }, row.state))))))))));
};
