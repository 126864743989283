import { client } from '@/shared/api';
const PATH_AUTH_API = 'Admin';
export const getPluginInstances = async (additionlInfo) => {
    const { data } = await client.get(`${PATH_AUTH_API}/GetAllPluginInstancesWithCheckInstances${additionlInfo ? additionlInfo : ''}`);
    if (!data.isSuccess) {
        throw new Error(`GetAllPluginInstancesWithCheckInstances. ${data.errorMessage}`);
    }
    return data;
};
export const getPluginInstance = async (additionlInfo) => {
    const { data } = await client.get(`${PATH_AUTH_API}/GetPluginInstanceByGuid${additionlInfo}`);
    // if (!data.isSuccess) {
    //     throw new Error(`GetPluginInstanceByGuid. ${data.errorMessage}`);
    // }
    return data;
};
export const getInitializeInstanceByGuid = async (guid) => {
    const { data } = await client.get(`${PATH_AUTH_API}/InitializeInstanceByGuid/${guid}`);
    return data;
};
export const setInstanceServiceModeByGuid = async (guid) => {
    const { data } = await client.patch(`${PATH_AUTH_API}/SetInstanceServiceModeByGuid/${guid}`);
    return data;
};
