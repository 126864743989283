import React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
export const useActionLogger = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    return {
        setAction: ({ message, type }) => {
            enqueueSnackbar(message, {
                variant: type,
                preventDuplicate: type === 'error',
                autoHideDuration: type === 'error' ? null : 5000,
                action: (key) => (React.createElement(IconButton, { color: "inherit", edge: "end", onClick: () => closeSnackbar(key) },
                    React.createElement(HighlightOffIcon, null))),
            });
        },
    };
};
export const ActionLoggerProvider = ({ children }) => (React.createElement(SnackbarProvider, { maxSnack: 5, hideIconVariant: true, anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
    } }, children));
