import { Box, Button, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useGetNextWorkflowStagesTypes } from '@/pages/IntegrationChains/services/hooks/use-get-next-stages-types';
import { ConfirmDelete } from '@/shared/components/ConfirmDelete';
import { useGetSystemAvailable } from '@/pages/IntegrationChains/services/hooks/use-get-system-available';
import { useCreateWorkflowStage } from '@/pages/IntegrationChains/services/hooks/use-create-workflow-stage';
import { useGetTransformationPluginsAvailable } from '@/pages/IntegrationChains/services/hooks/use-get-transformation-plugins-available';
import { findSystem, findPlugin, transformStagesTypesToOptions, transformSystemsToSelectOptions, transformSystemToOptions, transformPluginsToSelectOptions, transformPluginToOptions, } from './utils';
const useStyles = makeStyles((theme) => ({
    input: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    colorButtonBack: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        marginTop: '1rem',
    },
    buttonDelete: {
        position: 'relative',
        top: '7px',
        right: "-7px",
    }
}));
export const NewWorkflowStagesRow = ({ guid, refetchChain }) => {
    const styles = useStyles();
    const { mutate: getNextWorkflowStagesTypes, data: stagesTypes } = useGetNextWorkflowStagesTypes();
    const { mutate: getAvailableSystem, data: availableSystem } = useGetSystemAvailable();
    const { mutate: getTransformationPluginsAvailable, data: availableTransformationPlugins } = useGetTransformationPluginsAvailable();
    const { mutate: createWorkflowStage, isLoading: saveIsLoading } = useCreateWorkflowStage(() => {
        if (refetchChain)
            refetchChain();
        setCode('');
        setDescription('');
        getNextWorkflowStagesTypes(guid);
    });
    const [showStageRow, setShowStageRow] = useState(false);
    const [code, setCode] = useState('');
    const [description, setDescription] = useState('');
    const [pluginType, setPluginType] = useState();
    const [system, setSystem] = useState();
    const [pluginName, setPluginName] = useState();
    const [method, setMethod] = useState();
    const handleAddStage = () => {
        getNextWorkflowStagesTypes(guid);
        setShowStageRow(true);
        if (refetchChain)
            refetchChain();
    };
    const handleSaveStage = () => {
        createWorkflowStage({
            integrationChainGuid: guid,
            code,
            description,
            methodGuid: method,
            systemGuid: system,
            workflowPluginType: pluginType,
            type: pluginName?.value,
        });
    };
    const handleDeleteStage = () => {
        setShowStageRow(false);
        if (refetchChain)
            refetchChain();
    };
    // eslint-disable-next-line no-undef
    // console.log('transformation',availableTransformationPlugins)
    // console.log('system',availableSystem)
    // console.log('guid',guid)
    const handleSetPluginType = useCallback((pluginType) => {
        if (pluginType === 3) {
            console.log(pluginType);
            setPluginType(pluginType);
            getTransformationPluginsAvailable(guid);
            return;
        }
        setPluginType(pluginType);
        getAvailableSystem({ guid, stagePluginType: pluginType });
    }, [getAvailableSystem, getTransformationPluginsAvailable, guid]);
    const handleChangePluginType = (event) => {
        const stagePluginType = event.target.value;
        handleSetPluginType(stagePluginType);
    };
    const pluginTypeOptions = useMemo(() => {
        if (!stagesTypes?.isSuccess) {
            return [];
        }
        const options = transformStagesTypesToOptions(stagesTypes.stagesAvailableDescriptions);
        handleSetPluginType(options[0]?.value);
        return options;
    }, [stagesTypes, handleSetPluginType]);
    // fsfsdjflsjflskdjflsdjfdslfjdslfjdslkfsdjflksdjflksdfjsdlfj
    const systemsOptions = useMemo(() => {
        if (!availableSystem?.isSuccess) {
            return [];
        }
        const options = transformSystemsToSelectOptions(availableSystem.systemForStageCreateDescriptions);
        setSystem(options[0]?.value);
        return options;
    }, [availableSystem]);
    const selectedSystemData = useMemo(() => {
        if (!availableSystem?.isSuccess || !system) {
            return;
        }
        return findSystem(availableSystem.systemForStageCreateDescriptions, system);
    }, [availableSystem, system]);
    const pluginAndMethodOptions = useMemo(() => {
        if (!selectedSystemData) {
            return;
        }
        const options = transformSystemToOptions(selectedSystemData);
        // console.log('options',options)
        setPluginName(options.pluginNameOption);
        if (options.pluginMethodNameOptions.length) {
            setMethod(options.pluginMethodNameOptions[0].value);
        }
        return options;
    }, [selectedSystemData]);
    // sdfjsldfjsdklfjsdfljsdflsdjflsjdflsdjflsjfdslfjsdldfjslkfjsdlfjl
    const pluginOptions = useMemo(() => {
        if (!availableTransformationPlugins?.isSuccess) {
            return [];
        }
        const options = transformPluginsToSelectOptions(availableTransformationPlugins.pluginCreateDescriptions);
        setSystem(options[0]?.value);
        return options;
    }, [availableTransformationPlugins]);
    const selectedPluginData = useMemo(() => {
        if (!availableTransformationPlugins?.isSuccess) {
            return;
        }
        if (system) {
            return findPlugin(availableTransformationPlugins.pluginCreateDescriptions, system);
        }
    }, [availableTransformationPlugins, system]);
    const pluginAndMethodTransformationOptions = useMemo(() => {
        if (!selectedPluginData) {
            return;
        }
        const options = transformPluginToOptions(selectedPluginData);
        // console.log('options',options)
        setPluginName(options.pluginNameOption);
        if (options.pluginMethodNameOptions.length) {
            setMethod(options.pluginMethodNameOptions[0].value);
        }
        return options;
    }, [selectedPluginData]);
    console.log('pluginAndMethodTransformationOptions', pluginAndMethodTransformationOptions);
    // console.log(code, description)
    return (React.createElement(React.Fragment, null, showStageRow ? (React.createElement(Box, { mb: 1, display: "flex", alignItems: "center" },
        React.createElement(TextField, { className: styles.input, label: 'Код', value: code, onChange: ({ target }) => setCode(target.value) }),
        React.createElement(TextField, { className: styles.input, label: 'Описание', value: description, onChange: ({ target }) => setDescription(target.value) }),
        React.createElement(Select, { className: styles.input, value: pluginType || '', onChange: handleChangePluginType, disabled: !pluginType }, pluginTypeOptions.map(({ label, value }) => (React.createElement(MenuItem, { key: value, value: value }, label)))),
        React.createElement(Select, { className: styles.input, value: system || '', onChange: ({ target }) => setSystem(target.value), disabled: !system }, pluginType !== 3 ? systemsOptions.map(({ label, value }) => (React.createElement(MenuItem, { key: value, value: value }, label))) : pluginOptions.map(({ label, value }) => (React.createElement(MenuItem, { key: value, value: value }, label)))),
        React.createElement(TextField, { className: styles.input, value: pluginName?.label || '', disabled: !pluginName, InputProps: {
                readOnly: true,
            } }),
        React.createElement(Select, { className: styles.input, value: method || '', onChange: ({ target }) => setMethod(target.value), disabled: !method }, pluginType !== 3 ? pluginAndMethodOptions?.pluginMethodNameOptions.map(({ label, value }) => (React.createElement(MenuItem, { key: value, value: value }, label))) : pluginAndMethodTransformationOptions?.pluginMethodNameOptions.map(({ label, value }) => (React.createElement(MenuItem, { key: value, value: value }, label)))),
        React.createElement(Button, { variant: "contained", size: "small", className: styles.colorButtonBack, disabled: saveIsLoading, onClick: handleSaveStage }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"),
        React.createElement(ConfirmDelete, { className: styles.buttonDelete, view: "icon", onDelete: handleDeleteStage }))) : (React.createElement(Button, { variant: "contained", className: styles.colorButtonBack, onClick: handleAddStage }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u044D\u0442\u0430\u043F"))));
};
