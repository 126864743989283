import { Box, Button, IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
const useStyles = makeStyles(() => ({
    colorButton: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        marginTop: '1rem',
    },
}));
export const ConfirmDeleteSystem = ({ onDelete, view }) => {
    const styles = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDelete = () => {
        onDelete();
        setAnchorEl(null);
    };
    return (React.createElement(React.Fragment, null,
        view === 'icon' ? (React.createElement(IconButton, { edge: "end", onClick: handleClick },
            React.createElement(DeleteIcon, null))) : (React.createElement(Box, { component: "div", display: "inline" },
            React.createElement(Button, { variant: "contained", className: styles.colorButton, onClick: handleClick }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u0441\u0438\u0441\u0442\u0435\u043C\u0443"))),
        React.createElement(Menu, { anchorEl: anchorEl, keepMounted: true, open: Boolean(anchorEl), onClose: handleClose },
            React.createElement(MenuItem, { onClick: handleDelete }, "\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u044C"),
            React.createElement(MenuItem, { onClick: handleClose }, "\u041E\u0442\u043C\u0435\u043D\u0438\u0442\u044C"))));
};
