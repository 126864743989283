import { useMutation, useQueryClient } from 'react-query';
import { useActionLogger } from '@/shared/hooks/action-logger';
import { AddNewVersionPlugins } from '../index';
export const useNewVersionPlugins = (callback) => {
    const queryClient = useQueryClient();
    const { setAction } = useActionLogger();
    return useMutation(AddNewVersionPlugins, {
        onSuccess: async (resp) => {
            await queryClient.invalidateQueries('GetPluginDescriptionList');
            setAction({
                type: 'success',
                message: 'success',
            });
            if (callback && typeof callback === 'function') {
                callback(resp?.versionGuid);
            }
        },
        onError: (error) => {
            setAction({
                type: 'error',
                message: error.toString(),
            });
        },
    });
};
