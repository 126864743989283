import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, } from '@material-ui/core';
import React, { useState } from 'react';
import { PageLayout } from '@/shared/components/PageLayout';
import { Menu } from '@/shared/components/Menu';
import { NavLink, useHistory } from 'react-router-dom';
// styles
import { useStyles } from "@/pages/IntegrationChains/styles";
import { useGetAllChains } from './services/hooks/use-get-all-chains';
export const IntegrationChains = () => {
    const columns = [
        { value: 'id', label: 'ИД', minWidth: 40 },
        { value: 'guid', label: 'Уникальный идентификатор', minWidth: 290 },
        { value: 'code', label: 'Код', minWidth: 260 },
        { value: 'description', label: 'Описание' },
    ];
    const history = useHistory();
    const classes = useStyles();
    const [additionalValue, setAdditionalValue] = useState('');
    const { data: chainsList, refetch } = useGetAllChains(additionalValue);
    console.log(chainsList);
    const rowsPerPageSetting = [10, 25, 50, 100];
    const handleChangePage = (_, newPage) => {
        setAdditionalValue(`?pageSize=${chainsList?.pageSize}&pageNumber=${newPage + 1}`);
        refetch();
    };
    const handleChangeRowsPerPage = (event) => {
        setAdditionalValue(`?pageSize=${Number(event.target.value)}`);
        refetch();
    };
    return (React.createElement(PageLayout, null,
        React.createElement("div", null,
            React.createElement(Box, { component: "div", display: "inline" },
                React.createElement(Menu, { addButtonProps: {
                        disabled: false,
                        handleClick: () => history.push('/integration-chain/add'),
                        label: 'Добавить процесс',
                    } })),
            React.createElement(TableContainer, { component: Paper, className: classes.tableHideOverflow },
                React.createElement(Typography, { variant: "h4", className: classes.marginBott }, "\u0418\u043D\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u043E\u043D\u043D\u044B\u0435 \u043F\u0440\u043E\u0446\u0435\u0441\u0441\u044B"),
                React.createElement(Table, { className: classes.table, "aria-label": "simple table", stickyHeader: true },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null, columns.map((column) => (React.createElement(TableCell, { key: column.value, align: column.align, className: classes.tableRowHead }, column.label))))),
                    React.createElement(TableBody, null, chainsList?.chainDescriptions.map((plugin) => (React.createElement(TableRow, { key: plugin.guid }, columns.map((column) => {
                        const value = plugin[column.value];
                        column.value;
                        return (React.createElement(TableCell, { component: "td", scope: "row", key: column.value, className: classes.tableRow, style: { width: column.minWidth } }, column.value === 'guid' ?
                            (React.createElement(NavLink, { to: `/integration-chain/${plugin.guid}` }, (column.component && column.component(value)) || value))
                            :
                                (column.component && column.component(value)) || value));
                    })))))),
                chainsList?.chainDescriptions && (React.createElement(TablePagination, { labelRowsPerPage: 'Cтрок на странице', rowsPerPageOptions: rowsPerPageSetting, component: "div", count: chainsList?.totalCount, rowsPerPage: chainsList?.pageSize, page: chainsList?.currentPage - 1, onChangePage: handleChangePage, onChangeRowsPerPage: handleChangeRowsPerPage }))))));
};
