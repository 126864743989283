import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getTokenByType } from '@/shared/api/util';
import { login, logout } from '@/shared/services/auth';
import { AuthContext } from './context';
export const AuthProvider = ({ children }) => {
    const accessToken = getTokenByType('accessToken');
    const refreshToken = getTokenByType('refreshToken');
    const [isAuth, setIsAuth] = useState(Boolean(accessToken || refreshToken));
    const history = useHistory();
    const handleLogin = useCallback(async (data) => {
        const response = await login(data);
        setIsAuth(response.isSuccess);
        return response;
    }, []);
    const handleLogout = useCallback(() => {
        logout();
        setIsAuth(false);
        history.push('/login');
    }, [history]);
    const contextValue = {
        // @ts-ignore
        login: handleLogin,
        logout: handleLogout,
        isAuth,
    };
    return React.createElement(AuthContext.Provider, { value: contextValue }, children);
};
