import React from 'react';
import { Box, Button, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
const useStyles = makeStyles((theme) => ({
    form: {
        width: '500px',
        height: '305px',
        marginLeft: '1rem',
        marginTop: '2rem',
    },
    input: {
        marginBottom: theme.spacing(2),
    },
    colorButton: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        marginTop: '1rem',
    },
    marginTopColorGrid: {
        marginTop: '2rem',
        fontSize: '15px',
    },
    textAliginEnd: {
        textAlign: 'end',
        marginTop: '8px',
    },
    marginLeft: {
        marginLeft: '1rem',
    },
}));
export const FormAdd = ({ onSubmit }) => {
    const styles = useStyles();
    const { register, handleSubmit, setValue, errors } = useForm();
    React.useEffect(() => {
        register('code', {
            required: true,
        });
        register('description');
    }, [register]);
    return (React.createElement("form", { className: styles.form, onSubmit: handleSubmit((data) => onSubmit(data)) },
        React.createElement(Typography, { variant: "h5", className: styles.marginLeft }, "\u0414\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u0438\u043D\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u043E\u043D\u043D\u043E\u0433\u043E \u043F\u0440\u043E\u0446\u0435\u0441\u0441\u0430"),
        React.createElement(Box, { className: styles.form, justifyContent: "flex-start" },
            React.createElement(Grid, { container: true, spacing: 1, className: styles.marginTopColorGrid },
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u041A\u043E\u0434 *"),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(TextField, { className: styles.input, fullWidth: true, id: "outlined-basic", variant: "outlined", size: "small", onChange: ({ target }) => setValue('code', target.value), error: Boolean(errors?.code) })),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435"),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(TextField, { className: styles.input, fullWidth: true, id: "outlined-basic", variant: "outlined", size: "small", onChange: ({ target }) => setValue('description', target.value), error: Boolean(errors?.description) }))),
            React.createElement(Box, { mb: 2, mt: 4 },
                React.createElement(Button, { variant: "contained", color: "primary", type: "submit", className: styles.colorButton }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")))));
};
