import { useMutation, useQueryClient } from 'react-query';
import { useActionLogger } from '@/shared/hooks/action-logger';
import { createMigLink } from '..';
export const useCreatePropertyLink = (callback) => {
    const queryClient = useQueryClient();
    const { setAction } = useActionLogger();
    return useMutation(createMigLink, {
        onSuccess: () => {
            //  queryClient.invalidateQueries('GetListLink');
            setAction({
                type: 'success',
                message: 'The creation was successful',
            });
            if (callback && typeof callback === 'function') {
                callback();
            }
        },
        onError: (error) => {
            setAction({
                type: 'error',
                message: error.toString(),
            });
        },
    });
};
