import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem, TextField, Typography, } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from '../Tree/trees.styles';
export const SelectArrow = ({ connection, removeLinkNode, setSelectedNode1, setSelectedNode2 }) => {
    const classes = useStyles();
    // const queryClient = useQueryClient();
    const [anchorEl, setAnchorEl] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [comments, setComments] = useState('');
    // const { mutate: updateLinkWithComment } = useMutation('update linkedNode', updateLinkedNodes, {
    //     onSuccess: () => {
    //         queryClient.invalidateQueries('linkedNodes');
    //     },
    // });
    const { connections } = useSelector((store) => store.connectionsState);
    const dispatch = useDispatch();
    const handleSave = (item) => {
        const newConnections = connections?.map((connection) => {
            if (connection.propertyLinkGuid === item.propertyLinkGuid) {
                return {
                    ...connection,
                    comment: comments,
                };
            }
            return connection;
        });
        // setConnections(newConnections);
        dispatch({ type: 'UPDATE_CONNECTION', payload: newConnections });
        // updateLinkWithComment({
        //     id: item.id,
        //     start: item.start.split('/').pop() || '',
        //     end: item.end.split('/').pop() || '',
        //     comment: comments,
        // });
        setSelectedNode1('');
        setSelectedNode2('');
        setComments('');
        setShowModal(false);
    };
    const handleShowModal = () => {
        setShowModal(true);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (React.createElement("div", null,
        React.createElement(Button, { style: { border: '1px solid red', marginBottom: '50px', background: 'white' }, "aria-controls": "simple-menu", "aria-haspopup": "true", onClick: handleClick }, "\u041E\u0442\u043A\u0440\u044B\u0442\u044C \u043C\u0435\u043D\u044E"),
        React.createElement(Menu, { id: "simple-menu", anchorEl: anchorEl, keepMounted: true, open: Boolean(anchorEl), onClose: handleClose },
            React.createElement(MenuItem, { onKeyDown: (e) => e.key === 'Delete' && removeLinkNode(connection.propertyLinkGuid), onClick: () => removeLinkNode(connection.propertyLinkGuid) }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C"),
            React.createElement(MenuItem, { onClick: handleShowModal }, "\u041A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439")),
        React.createElement(Dialog, { onKeyDown: (e) => e.key === 'Escape' && setShowModal(false), open: showModal, "aria-labelledby": "data-dialog-title" },
            React.createElement(DialogTitle, { id: "data-dialog-title", className: classes.actions },
                React.createElement(Typography, null, "\u0412\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0435 \u043D\u043E\u0434\u044B:"),
                React.createElement(IconButton, { "aria-label": "close", className: classes.closeButton, onClick: () => setShowModal(false) },
                    React.createElement(CloseIcon, null))),
            React.createElement(DialogContent, null,
                React.createElement(DialogContentText, null, connection.sourcePropertyGuid.split('/').pop() ?? 'Не выбрано'),
                React.createElement(DialogContentText, null, connection.destinationPropertyGuid.split('/').pop() ?? 'Не выбрано'),
                React.createElement(TextField, { autoFocus: true, label: "\u041A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439", fullWidth: true, value: comments, onChange: (e) => setComments(e.target.value) }),
                React.createElement(DialogActions, { className: classes.actions },
                    React.createElement(Button, { variant: "contained", color: "primary", onClick: () => handleSave(connection) }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"))))));
};
