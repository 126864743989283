import React, { useState } from 'react';
import { CssBaseline, makeStyles, Tooltip, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { FormatDate } from '@/shared/components/FormatDate';
const uuid = (index) => `${Math.random()}_${index ? index : Math.random()}_${new Date().getTime()}`;
const columns = [
    { value: 'messageId', label: 'ИД сообщения', minWidth: 290 },
    { value: 'chainType', label: 'Тип процесса', minWidth: 190 },
    {
        value: 'startDate',
        label: 'Дата начала',
        component: (startDate) => React.createElement(FormatDate, { date: startDate }),
    },
    { value: 'currentStage', label: 'Текущий этап' },
    { value: 'status', label: 'Статус' },
    { value: 'searchDatas', label: 'Словарь ключевых данных', minWidth: 200 },
    { value: 'errors', label: 'Ошибки' },
    { value: 'endDate', label: 'Дата окончания', component: (endDate) => React.createElement(FormatDate, { date: endDate }) },
    { value: 'executionResultComment', label: 'Комментарий' },
];
const columnsMessageStages = [
    {
        value: 'startDate',
        label: 'Дата начала',
        minWidth: 150,
        component: (startDate) => React.createElement(FormatDate, { date: startDate }),
    },
    { value: 'stageType', label: 'Тип этапа', minWidth: 80 },
    { value: 'stageCode', label: 'Код этапа', minWidth: 120 },
    { value: 'status', label: 'Статус', minWidth: 180 },
    {
        value: 'endDate',
        label: 'Дата выполнения',
        minWidth: 140,
        component: (endDate) => React.createElement(FormatDate, { date: endDate }),
    },
    { value: 'executionComment', label: 'Комментарий' },
];
const rowsPerPageSetting = [10, 25, 50];
const useStyles = makeStyles(() => ({
    root: {
        width: 'auto',
        height: 'auto',
    },
    container: {
        overflow: 'hidden',
        minWidth: 650,
    },
    tableRow: {
        cursor: 'pointer',
        padding: '5px 16px 5px 16px',
        fontSize: '0.8rem',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '0px',
        tableLayout: 'auto',
        lineHeight: '1.5rem',
    },
    tableParentHead: {
        cursor: 'pointer',
        padding: '5px 16px 5px 16px',
        fontSize: '0.8rem',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '0px',
        tableLayout: 'auto',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        fontWeight: 'bolder',
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
    tableParentRow: {
        cursor: 'pointer',
        background: '#fafafa',
    },
    colorForKeyValue: {
        color: '#3f51b5',
    },
    statusFailed: {
        background: '#F08080',
    },
    statusCompleted: {
        background: '#8FBC8F',
    },
    marginBott: {
        marginTop: '1rem',
        marginBottom: '1rem',
        marginLeft: '1rem',
    },
}));
export const MonitoringInfoTable = ({ dashboards, onClickRow, onClickPagination }) => {
    const styles = useStyles();
    const [, setRowsPerPage] = useState(rowsPerPageSetting[0]);
    const handleChangePage = (_, newPage) => {
        onClickPagination(`?pageSize=${dashboards?.pageSize}&pageNumber=${newPage + 1}`);
    };
    const handleChangeRowsPerPage = (event) => {
        onClickPagination(`?pageSize=${Number(event.target.value)}`);
        setRowsPerPage(Number(event.target.value));
    };
    return (React.createElement(Paper, { elevation: 3 },
        React.createElement(CssBaseline, null),
        React.createElement(TableContainer, { className: styles.container },
            React.createElement(Typography, { variant: "h4", className: styles.marginBott }, "\u041C\u043E\u043D\u0438\u0442\u043E\u0440\u0438\u043D\u0433"),
            React.createElement(Table, null,
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null, columns.map((column, index) => (React.createElement(TableCell, { key: uuid(index), align: column.align, className: styles.tableParentHead, style: { width: column.minWidth } }, column.label))))),
                React.createElement(TableBody, null, dashboards && dashboards?.dashboardInfos.map((item, index) => (React.createElement(React.Fragment, null,
                    React.createElement(TableRow, { className: styles.tableRow, key: uuid(index), hover: true, onClick: () => onClickRow(item) }, columns.map((column, index) => {
                        let value = '';
                        if ('searchDatas' === column.value) {
                            item[column.value].forEach((element) => {
                                value += `${element.key} ${element.value} `;
                            });
                        }
                        else {
                            value = item[column.value];
                        }
                        return (React.createElement(TableCell, { key: uuid(index), align: column.align, "data-test": column.value, className: styles.tableRow, style: { width: column.minWidth } }, column.value === 'searchDatas'
                            ? item[column.value].map((element) => `${element.key}: ${element.value}  `)
                            : (column.component && column.component(value)) || value));
                    })),
                    item.isShowStageInfo ? (React.createElement(TableRow, null,
                        React.createElement(TableCell, { colSpan: 9 },
                            React.createElement(Table, null,
                                React.createElement(TableHead, null,
                                    React.createElement(TableRow, null, columnsMessageStages.map((column, index) => (React.createElement(TableCell, { key: uuid(index), align: column.align, className: styles.tableParentHead }, column.label))))),
                                React.createElement(TableBody, null, item.messageStage.stagesInfo.map((stage, index) => (React.createElement(TableRow, { className: styles.statusCompleted, key: uuid(index) }, columnsMessageStages.map((column, index) => {
                                    const lengthSimbolExecutionComment = 10;
                                    // if (
                                    //     column.label === 'Комментарий' &&
                                    //     stage.executionComment != null &&
                                    //     stage.executionComment.length >=
                                    //         lengthSimbolExecutionComment
                                    // ) {
                                    //     informationErrorText = stage.executionComment;
                                    //     let sliced = stage.executionComment.slice(0, 20);
                                    //     if (sliced.length < stage.executionComment.length) {
                                    //         sliced += '...';
                                    //     }
                                    //     stage.executionComment = sliced;
                                    // }
                                    const value = stage[column.value];
                                    column.value;
                                    return (React.createElement(Tooltip, { key: uuid(index), title: (column.label === 'Комментарий' && stage.executionComment !== null && stage.executionComment.length >=
                                            lengthSimbolExecutionComment) ? stage.executionComment : '', placement: "right", interactive: true },
                                        React.createElement(TableCell, { align: column.align, 
                                            // className={statusFailed}
                                            className: styles.tableRow }, (column.component &&
                                            column.component(value)) ||
                                            (column.label === 'Комментарий' && stage.executionComment !== null && stage.executionComment.length >=
                                                // eslint-disable-next-line prefer-template
                                                lengthSimbolExecutionComment ? value.slice(0, 20) + '...' : value))));
                                }))))))))) : null)))))),
        dashboards && (React.createElement(TablePagination, { labelRowsPerPage: 'Cтрок на странице', rowsPerPageOptions: rowsPerPageSetting, component: "div", count: dashboards.totalCount, rowsPerPage: dashboards.pageSize, page: dashboards.currentPage - 1, onChangePage: handleChangePage, onChangeRowsPerPage: handleChangeRowsPerPage }))));
};
