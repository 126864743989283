import { useMutation, useQueryClient } from 'react-query';
import { useActionLogger } from "@/shared/hooks/action-logger";
import { getNextSystemAvailable } from '../index';
export const useGetSystemAvailable = () => {
    const queryClient = useQueryClient();
    const { setAction } = useActionLogger();
    return useMutation(getNextSystemAvailable, {
        onSuccess: (resp) => {
            // queryClient.invalidateQueries('GetSystemsAvailable');
            if (resp?.isSuccess) {
                setAction({
                    type: 'success',
                    message: `GetSystemsAvailable ${resp?.errorMessage ? resp?.errorMessage : ' Success'}`,
                });
            }
            else {
                setAction({
                    type: 'error',
                    // eslint-disable-next-line no-nested-ternary
                    message: `GetSystemsAvailable ${resp?.errorMessage ? resp.errorMessage : resp?.errorFullMessage ? resp.errorFullMessage : ' Error'}`,
                });
            }
            if (resp?.errorFullMessage)
                console.error(resp?.errorFullMessage);
        },
        onError: (error) => {
            setAction({
                type: 'error',
                // message: error?.toString(),
                message: `GetSystemsAvailable${error?.errorMessage}`,
            });
        },
    });
};
