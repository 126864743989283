import React, { useEffect } from 'react';
import { Box, Button, Grid, makeStyles, MenuItem, TextField, Typography, FormHelperText } from '@material-ui/core';
import { useForm } from 'react-hook-form';
const useStyles = makeStyles((theme) => ({
    form: {
        width: '400px',
        marginLeft: '2rem',
    },
    input: {
        marginBottom: theme.spacing(2),
    },
    colorButton: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        marginBottom: '2rem',
        marginTop: '1rem',
    },
    paddingBott: {
        paddingTop: '1rem',
        paddingBottom: '2rem',
    },
    margin: {
        margin: theme.spacing(1),
    },
    marginTop: {
        marginTop: '2rem',
    },
    textAliginEnd: {
        textAlign: 'end',
        marginTop: '8px',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    marginTopColorGrid: {
        marginTop: '2rem',
        fontSize: '15px',
    },
    textAliginEndMarginBottom: {
        textAlign: 'end',
        marginBottom: '2rem',
    },
}));
export const FormAdd = ({ onSubmit, isSubmitting, pluginInstanceError, }) => {
    const styles = useStyles();
    const { register, handleSubmit, setValue, errors } = useForm();
    useEffect(() => {
        register('name', {
        // required: true,
        });
        register('httpHeader', {
        // required: true,
        });
        register('host', {
        // required: true,
        });
        register('port', {
        // required: true,
        });
        register('customHeadHttpPrefix');
        register('customHeadHost');
        register('customHeadPort');
    }, [register]);
    const getErrorByName = (name) => pluginInstanceError?.filter((error) => String(error?.fieldName).toLowerCase() === String(name).toLowerCase())[0];
    return (React.createElement("form", { className: styles.form, onSubmit: handleSubmit((data) => onSubmit(data)) },
        React.createElement(Typography, { variant: "h5", className: styles.paddingBott }, "\u0414\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u0430\u0433\u0435\u043D\u0442\u0430"),
        React.createElement(Box, { className: styles.form, justifyContent: "flex-start" },
            React.createElement(Grid, { container: true, spacing: 1, className: styles.marginTopColorGrid },
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0430\u0433\u0435\u043D\u0442\u0430 *"),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(TextField, { className: styles.input, fullWidth: true, id: "outlined-basic", variant: "outlined", size: "small", onChange: ({ target }) => setValue('name', target.value), 
                        // error={Boolean(errors?.name)}
                        error: Boolean(getErrorByName('name') && getErrorByName('name')?.fieldErrors !== null), "aria-describedby": "my-helper-plugin-instance-name" }),
                    React.createElement(FormHelperText, { error: true, id: "my-helper-plugin-instance-name" }, getErrorByName('name')?.fieldErrors?.map((error, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    React.createElement("span", { key: index }, error))))),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A HTTP *"),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(TextField, { className: styles.input, fullWidth: true, id: "standard-select-currency", select: true, variant: "outlined", size: "small", onChange: ({ target }) => setValue('httpHeader', target.value), 
                        // error={Boolean(errors?.httpHeader)}
                        error: Boolean(getErrorByName('httpheader') && getErrorByName('httpheader')?.fieldErrors !== null), "aria-describedby": "my-helper-plugin-instance-httpheader" },
                        React.createElement(MenuItem, { value: "http" }, "http"),
                        React.createElement(MenuItem, { value: "https" }, "https")),
                    React.createElement(FormHelperText, { error: true, id: "my-helper-plugin-instance-httpheader" }, getErrorByName('httpheader')?.fieldErrors?.map((error, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    React.createElement("span", { key: index }, error))))),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u0410\u0434\u0440\u0435\u0441 *"),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(TextField, { className: styles.input, fullWidth: true, id: "outlined-basic", variant: "outlined", size: "small", onChange: ({ target }) => setValue('host', target.value), 
                        // error={Boolean(errors?.host)}
                        error: Boolean(getErrorByName('host') && getErrorByName('host')?.fieldErrors !== null), "aria-describedby": "my-helper-plugin-instance-host" }),
                    React.createElement(FormHelperText, { error: true, id: "my-helper-plugin-instance-host" }, getErrorByName('host')?.fieldErrors?.map((error, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    React.createElement("span", { key: index }, error))))),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u041F\u043E\u0440\u0442 *"),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(TextField, { className: styles.input, fullWidth: true, id: "outlined-basic", variant: "outlined", size: "small", onChange: ({ target }) => setValue('port', target.value), 
                        // error={Boolean(errors?.port)}
                        error: Boolean(getErrorByName('port') && getErrorByName('port')?.fieldErrors !== null), "aria-describedby": "my-helper-plugin-instance-port" }),
                    React.createElement(FormHelperText, { error: true, id: "my-helper-plugin-instance-port" }, getErrorByName('port')?.fieldErrors?.map((error, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    React.createElement("span", { key: index }, error))))),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u041F\u0440\u043E\u0442\u043E\u043A\u043E\u043B"),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(TextField, { className: styles.input, fullWidth: true, id: "standard-select-currency", select: true, variant: "outlined", size: "small", onChange: ({ target }) => setValue('customHeadHttpPrefix', target.value), error: Boolean(getErrorByName('customHeadHttpPrefix') && getErrorByName('customHeadHttpPrefix')?.fieldErrors !== null), "aria-describedby": "my-helper-plugin-instance-customHeadHttpPrefix" },
                        React.createElement(MenuItem, { value: "http" }, "http"),
                        React.createElement(MenuItem, { value: "https" }, "https")),
                    React.createElement(FormHelperText, { error: true, id: "my-helper-plugin-instance-customHeadHttpPrefix" }, getErrorByName('customHeadHttpPrefix')?.fieldErrors?.map((error, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    React.createElement("span", { key: index }, error))))),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "Custom Head Host"),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(TextField, { className: styles.input, fullWidth: true, id: "outlined-basic", variant: "outlined", size: "small", onChange: ({ target }) => setValue('customHeadHost', target.value), 
                        // error={Boolean(errors?.customHeadHost)}
                        error: Boolean(getErrorByName('customHeadHost') && getErrorByName('customHeadHost')?.fieldErrors !== null), "aria-describedby": "my-helper-plugin-instance-customHeadHost" }),
                    React.createElement(FormHelperText, { error: true, id: "my-helper-plugin-instance-customHeadHost" }, getErrorByName('customHeadHost')?.fieldErrors?.map((error, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    React.createElement("span", { key: index }, error))))),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "Custom Head Port"),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(TextField, { className: styles.input, fullWidth: true, id: "outlined-basic", variant: "outlined", size: "small", onChange: ({ target }) => setValue('customHeadPort', target.value), 
                        // error={Boolean(errors?.customHeadPort)}
                        error: Boolean(getErrorByName('customHeadPort') && getErrorByName('customHeadPort')?.fieldErrors !== null), "aria-describedby": "my-helper-plugin-instance-customHeadPort" }),
                    React.createElement(FormHelperText, { error: true, id: "my-helper-plugin-instance-customHeadPort" }, getErrorByName('customHeadPort')?.fieldErrors?.map((error, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    React.createElement("span", { key: index }, error))))))),
        React.createElement(Box, null,
            React.createElement(Button, { variant: "contained", className: styles.colorButton, type: "submit", disabled: isSubmitting }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"))));
};
