import { PageLayout } from '@/shared/components/PageLayout';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGetAllSystems } from '../Systems/services/hooks/use-get-all-systems';
import { useGetMiratorsList } from './services/hooks/use-get-migrators-list';
import { useStyles } from './migrator.styles';
import { useUpdateMigratorSystem } from './services/hooks/use-update-migrator-system';
import { useGetInfoByGuid } from './services/hooks/use-get-info-by-guid';
export const MigratorPage = () => {
    const { guid } = useParams();
    const [additionalValue, setAdditionalValue] = useState('');
    const [sourceSystem, setSourceSystem] = useState('');
    const [targetSystem, setTargetSystem] = useState('');
    const [querySource, setQuerySource] = useState('');
    const [queryTarget, setQueryTarget] = useState('');
    const { data } = useGetMiratorsList();
    const { data: systemsList } = useGetAllSystems(additionalValue);
    // console.log(systemsList);
    const classes = useStyles();
    const history = useHistory();
    const migrator = data?.migratorList.find((migrator) => migrator.guid === guid);
    const { mutate: updateSystem, isLoading, isSuccess } = useUpdateMigratorSystem();
    const { data: getInfoByGuid } = useGetInfoByGuid(`?migratorGuid=${guid}`);
    const handleChangeSource = (event) => {
        setSourceSystem(event.target.value);
    };
    const handleChangeTarget = (event) => {
        setTargetSystem(event.target.value);
    };
    const hadleSaveSourceMigrator = () => {
        if (sourceSystem) {
            const systemData = systemsList?.huHeadSystemInfos.find((system) => system.code === sourceSystem);
            if (systemData) {
                const payload = {
                    migSystem: {
                        guid: systemData.guid,
                        name: systemData.code,
                        typeString: 'Source',
                    },
                    migratorGuid: guid,
                };
                updateSystem(payload);
                setQuerySource(`${systemData.guid}`);
            }
        }
    };
    const hadleSaveTargetMigrator = () => {
        if (targetSystem) {
            const targetData = systemsList?.huHeadSystemInfos.find((system) => system.code === targetSystem);
            if (targetData) {
                const payload = {
                    migSystem: {
                        guid: targetData.guid,
                        name: targetData.code,
                        typeString: 'Destination',
                    },
                    migratorGuid: guid,
                };
                updateSystem(payload);
                setQueryTarget(`${targetData.guid}`);
            }
        }
    };
    const nullGuid = '00000000-0000-0000-0000-000000000000';
    const destinationSystemObj = getInfoByGuid?.migratorInfo?.info?.destinationSystem;
    const sourceSystemObj = getInfoByGuid?.migratorInfo?.info?.sourceSystem;
    const ifDestinationSystemSelected = destinationSystemObj?.guid !== nullGuid;
    const ifSourceSystemSelected = sourceSystemObj?.guid !== nullGuid;
    return (React.createElement(React.Fragment, null,
        React.createElement(PageLayout, null,
            React.createElement("div", { style: { width: '100%', marginBottom: '2rem', marginLeft: '9px' } },
                React.createElement(Box, { component: "div", display: "inline" },
                    React.createElement(Button, { variant: "contained", size: "medium", className: classes.colorButtonBack, onClick: () => {
                            history.push('/migrators');
                        } }, "\u041D\u0430\u0437\u0430\u0434 \u043A \u0441\u043F\u0438\u0441\u043A\u0443"))),
            React.createElement(Typography, { variant: "h5", className: classes.titleGuid, style: { margin: '1rem', padding: 0, marginLeft: '8px', marginBottom: '2rem' } }, "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u043C\u0438\u043A\u0440\u043E\u0441\u0435\u0440\u0432\u0438\u0441\u0435 \u043C\u0438\u0433\u0440\u0430\u0446\u0438\u0438"),
            React.createElement(Box, { className: classes.guidForm },
                React.createElement(Grid, { xs: 8, container: true, spacing: 1, className: classes.guidGridContainer, style: { marginLeft: 6 } },
                    React.createElement(Grid, { item: true, xs: 6, className: classes.guidText }, "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u043C\u0438\u043A\u0440\u043E\u0441\u0435\u0440\u0432\u0438\u0441\u0430:"),
                    React.createElement(Grid, { item: true, xs: 6, className: classes.guidField }, migrator?.name),
                    React.createElement(Grid, { item: true, xs: 6, className: classes.guidText }, "\u0425\u043E\u0441\u0442:"),
                    React.createElement(Grid, { item: true, xs: 6, className: classes.guidField }, migrator?.host),
                    React.createElement(Grid, { item: true, xs: 6, className: classes.guidText }, "\u041F\u043E\u0440\u0442:"),
                    React.createElement(Grid, { item: true, xs: 6, className: classes.guidField }, migrator?.port),
                    React.createElement(Grid, { item: true, xs: 6, className: classes.guidText }, "\u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A http:"),
                    React.createElement(Grid, { item: true, xs: 6, className: classes.guidField }, migrator?.httpHeader),
                    React.createElement(Grid, { item: true, xs: 6, className: classes.guidText }, "\u0423\u043D\u0438\u043A\u0430\u043B\u044C\u043D\u044B\u0439 \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440:"),
                    React.createElement(Grid, { item: true, xs: 6, className: classes.guidField }, migrator?.guid)),
                React.createElement(Grid, { xs: 8, container: true, spacing: 1, className: classes.guidSystemContainer, style: { marginLeft: 12 } }, ifSourceSystemSelected ? (React.createElement(Grid, { container: true, spacing: 1, style: { marginTop: '2rem' } },
                    React.createElement(Grid, { item: true, xs: 8, style: { fontSize: '18px', marginBottom: '2rem' } }, "\u0421\u0438\u0441\u0442\u0435\u043C\u0430 - \u0418\u0441\u0442\u043E\u0447\u043D\u0438\u043A"),
                    React.createElement(Grid, { item: true, xs: 6, style: { textAlign: 'start', color: 'grey' } }, "\u041A\u043E\u0434: "),
                    React.createElement(Grid, { item: true, xs: 6, style: { color: 'grey' } }, sourceSystemObj?.name),
                    React.createElement(Grid, { item: true, xs: 6, style: { textAlign: 'start', color: 'grey' } }, "\u0423\u043D\u0438\u043A\u0430\u043B\u044C\u043D\u044B\u0439 \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440: "),
                    React.createElement(Grid, { item: true, xs: 6, style: { color: 'grey' } }, sourceSystemObj?.guid))) : (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { item: true, style: { marginLeft: -17, fontSize: 17, position: 'relative', left: -4 }, className: classes.guidSystemText }, "\u0421\u0438\u0441\u0442\u0435\u043C\u0430 - \u0418\u0441\u0442\u043E\u0447\u043D\u0438\u043A"),
                    React.createElement(Grid, { item: true, style: { paddingLeft: 15, position: 'relative', left: -10 } },
                        React.createElement(FormControl, { variant: "outlined", className: classes.formControl },
                            React.createElement(InputLabel, { id: "demo-simple-select-outlined-label" }, "\u0421\u0438\u0441\u0442\u0435\u043C\u044B"),
                            React.createElement(Select, { labelId: "demo-simple-select-outlined-label", id: "demo-simple-select-outlined", value: sourceSystem, onChange: handleChangeSource, label: "\u0421\u0438\u0441\u0442\u0435\u043C\u044B" }, systemsList?.huHeadSystemInfos.map((system) => (React.createElement(MenuItem, { key: system.guid, value: system.code }, system.code)))))),
                    React.createElement(Grid, { item: true, className: classes.guidSystemButton },
                        React.createElement(Button, { variant: "contained", style: { height: 55, position: 'relative', left: -10 }, className: classes.buttonSave, onClick: hadleSaveSourceMigrator, disabled: !sourceSystem }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"))))),
                ifDestinationSystemSelected ? (React.createElement(Grid, { xs: 8, container: true, spacing: 1, className: classes.guidSystemContainer, style: { marginLeft: 12 } },
                    React.createElement(Grid, { container: true, spacing: 1, style: { marginTop: '2rem' } },
                        React.createElement(Grid, { item: true, xs: 8, style: { fontSize: '18px', marginBottom: '2rem' } }, "\u0421\u0438\u0441\u0442\u0435\u043C\u0430 - \u041F\u0440\u0438\u0435\u043C\u043D\u0438\u043A"),
                        React.createElement(Grid, { item: true, xs: 6, style: { textAlign: 'start', color: 'grey' } }, "\u041A\u043E\u0434: "),
                        React.createElement(Grid, { item: true, xs: 6, style: { color: 'grey' } }, destinationSystemObj?.name),
                        React.createElement(Grid, { item: true, xs: 6, style: { textAlign: 'start', color: 'grey' } }, "\u0423\u043D\u0438\u043A\u0430\u043B\u044C\u043D\u044B\u0439 \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440: "),
                        React.createElement(Grid, { item: true, xs: 6, style: { color: 'grey' } }, destinationSystemObj?.guid)))) : (React.createElement(Grid, { container: true, spacing: 1, className: classes.guidSystemContainer, style: { marginLeft: '6.5px', justifyContent: 'flex-start' } },
                    React.createElement(Grid, { item: true, className: classes.guidSystemText, style: { fontSize: 17 } }, "\u0421\u0438\u0441\u0442\u0435\u043C\u0430 - \u041F\u0440\u0438\u0435\u043C\u043D\u0438\u043A"),
                    React.createElement(Grid, { item: true },
                        React.createElement(FormControl, { variant: "outlined", className: classes.formControl },
                            React.createElement(InputLabel, { id: "demo-simple-select-outlined-label" }, "\u0421\u0438\u0441\u0442\u0435\u043C\u044B"),
                            React.createElement(Select, { labelId: "demo-simple-select-outlined-label", id: "demo-simple-select-outlined", value: targetSystem, onChange: handleChangeTarget, label: "\u0421\u0438\u0441\u0442\u0435\u043C\u044B" }, systemsList?.huHeadSystemInfos.map((system) => (React.createElement(MenuItem, { key: system.guid, value: system.code }, system.code)))))),
                    React.createElement(Grid, { item: true, xs: 3, className: classes.guidSystemButton },
                        React.createElement(Button, { variant: "contained", style: { height: 55 }, className: classes.buttonSave, onClick: hadleSaveTargetMigrator, disabled: !targetSystem }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"))))),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(Button, { style: { marginLeft: 4, marginTop: '2rem' }, size: "medium", className: classes.guidNextButton, onClick: () => history.push(`/migrator/${guid}/source/${getInfoByGuid?.migratorInfo ? getInfoByGuid.migratorInfo.info.sourceSystem.guid : querySource}/${getInfoByGuid?.migratorInfo ? getInfoByGuid.migratorInfo.info.destinationSystem.guid : queryTarget}`), disabled: isLoading }, "\u0421\u043B\u0435\u0434\u0443\u044E\u0449\u0438\u0439 \u0448\u0430\u0433")))));
};
