import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { hasRefreshToken, isAccessTokenExpired, refreshTokens } from '@/shared/services/auth';
import config from '@/config/localconfig';
import { history } from '@/heplers/history';
import { getTokenByType } from './util';
export const client = axios.create({
    baseURL: config.headurl,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});
export const get = (param) => trackPromise(client.get(param));
export const post = (param, body) => trackPromise(client.post(param, body));
export const put = (param, body) => trackPromise(client.put(param, body));
export const del = (param) => trackPromise(client.delete(param));
client.interceptors.request.use(async (request) => {
    const accessToken = getTokenByType('accessToken');
    if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`;
    }
    if (isAccessTokenExpired() && hasRefreshToken() && request.url !== 'Admin/AuthenticateByRefreshToken') {
        try {
            const isSuccess = await refreshTokens();
            if (isSuccess) {
                request.headers.Authorization = `Bearer ${getTokenByType('accessToken')}`;
            }
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            toast.error(error);
        }
    }
    return request;
});
// Если сервер в какой то момент ответил 401 - переведем пользователя на реавторизацию (по хорошему надо обновить токен если есть refresh)
client.interceptors.response.use((response) => response, (error) => {
    if (error.response.status === 401) {
        history.push('/login');
    }
    return error;
});
