import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '@/shared/controllers/auth/context';
import { routes } from '@/pages/routes';
export const PrivateRoute = ({ children, ...rest }) => {
    const { isAuth } = useAuth();
    return (React.createElement(Route, Object.assign({}, rest, { render: ({ location }) => isAuth ? (children) : (React.createElement(Redirect, { to: {
                pathname: routes.login,
                state: { from: location },
            } })) })));
};
