import React, { useMemo, useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Paper } from '@material-ui/core';
import { useStyles } from "@/pages/Systems/styles";
import { PageLayout } from '@/shared/components/PageLayout';
import { FormAdd } from "@/pages/Systems/components/FormAdd";
import { useRegisterSystem } from "@/pages/Systems/services/hooks/use-register-system";
import { useNeedetGetPlugins } from "@/pages/Systems/services/hooks/use-get-needed-plugins";
import { useGetPluginInstances } from "@/shared/services/instances/hooks/use-get-plugin-instances";
import { useDeleteSystem } from "@/pages/Systems/services/hooks/use-delete-system";
export const SystemAdd = () => {
    const [validationBackend, setValidationBackend] = useState(null);
    const { data: pluginsList } = useNeedetGetPlugins();
    // зачем доп параметры? >>>
    // const { data: pluginInstances, isLoading: isLoadingPluginInstances } = useGetPluginInstances(additionalValue);
    const { data: pluginInstances } = useGetPluginInstances();
    const classes = useStyles();
    const { mutate: registerSystem, data: registerSystemResponse, isLoading: submittingSystem } = useRegisterSystem((errors) => {
        setValidationBackend(errors);
    });
    const [additionalValue] = useState('');
    const { refetch } = useGetPluginInstances(additionalValue);
    useEffect(() => {
        if (registerSystemResponse?.isSuccess) {
            history.push(`/system/${registerSystemResponse.guid}`);
            refetch();
        }
    }, [registerSystemResponse]);
    const history = useHistory();
    const { mutate: deleteSystem } = useDeleteSystem(() => history.push('/systems'));
    const serializationOptions = useMemo(() => pluginsList?.huHeadPluginDescriptions
        ?.filter(({ pluginTypeDescription }) => pluginTypeDescription === 'Serialization')
        .map(({ name, guid }) => ({
        label: name,
        value: guid,
    })) || [], [pluginsList]);
    const integrationOptions = useMemo(() => pluginsList?.huHeadPluginDescriptions
        ?.filter(({ pluginTypeDescription }) => pluginTypeDescription === 'Integration')
        .map(({ name, guid }) => ({
        label: name,
        value: guid,
    })) || [], [pluginsList]);
    const pluginInstanceOptions = useMemo(() => pluginInstances?.pluginInstanceInfoWithHeadAddresses.map(({ name, guid }) => ({
        label: name,
        value: guid,
    })) || [], [pluginInstances]);
    const objectServeOptions = useMemo(() => pluginsList?.huHeadPluginDescriptions
        ?.filter(({ pluginTypeDescription }) => pluginTypeDescription === 'ObjectServing')
        .map(({ name, guid }) => ({
        label: name,
        value: guid,
    })) || [], [pluginsList]);
    return (React.createElement(PageLayout, null,
        React.createElement("div", { className: classes.overflow },
            React.createElement("div", { style: { width: '100%' } },
                React.createElement(Button, { variant: "contained", size: "medium", className: classes.colorButtonBack, onClick: () => { history.push('/systems'); } }, "\u041D\u0430\u0437\u0430\u0434 \u043A \u0441\u043F\u0438\u0441\u043A\u0443"))),
        React.createElement(Paper, null,
            React.createElement(FormAdd, { systemResponseError: validationBackend, 
                //     registerSystemResponse && "fieldValidationErrors" in registerSystemResponse ?
                //         registerSystemResponse?.fieldValidationErrors : null}
                submittingSystem: submittingSystem, serializationOptions: serializationOptions, integrationOptions: integrationOptions, pluginInstanceOptions: pluginInstanceOptions, objectServeOptions: objectServeOptions, onSubmit: registerSystem, onDelete: deleteSystem }))));
};
