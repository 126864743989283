import React, { useEffect } from 'react';
import { Box, Button, makeStyles, Paper, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import LogoUrl from '@/shared/images/HyperUp-logo-horizontal-blue.png';
const useStyles = makeStyles((theme) => ({
    form: {
        width: '257px',
    },
    input: {
        marginBottom: theme.spacing(2),
    },
}));
export const LoginForm = ({ onSubmit, error }) => {
    const styles = useStyles();
    const { register, handleSubmit, setValue, errors, formState } = useForm();
    useEffect(() => {
        register('name', {
            validate: (value) => value?.length || 'This is required.',
        });
        register('password', {
            validate: (value) => value?.length || 'This is required.',
        });
    }, [register]);
    return (React.createElement(Paper, { elevation: 3 },
        React.createElement(Box, { width: 400, p: 4, display: "flex", flexDirection: "column", alignItems: "center" },
            React.createElement(Box, { mb: 3 },
                React.createElement("img", { src: LogoUrl, alt: "HyperUp logo", width: 257, height: 47 })),
            error && (React.createElement(Box, { mb: 3, color: "#ff0000" }, error)),
            React.createElement("form", { className: styles.form, onSubmit: handleSubmit((data) => onSubmit(data)) },
                React.createElement(TextField, { className: styles.input, fullWidth: true, label: "Login", variant: "outlined", onChange: (e) => setValue('name', e.target.value), error: Boolean(errors?.name) }),
                React.createElement(TextField, { className: styles.input, fullWidth: true, label: "Password", type: "password", variant: "outlined", onChange: (e) => setValue('password', e.target.value), error: Boolean(errors?.password) }),
                React.createElement(Button, { variant: "contained", color: "primary", type: "submit", disabled: formState.isSubmitting }, "Log in")))));
};
