import { useMutation } from 'react-query';
import { useActionLogger } from '@/shared/hooks/action-logger';
import { createWorkflowStagesLink } from '../index';
export const useCreateWorkflowStagesLink = () => {
    const { setAction } = useActionLogger();
    return useMutation(createWorkflowStagesLink, {
        onSuccess: () => {
            setAction({
                type: 'success',
                message: 'Creation of the stages link was successful',
            });
        },
        onError: (error) => {
            setAction({
                type: 'error',
                message: error.toString(),
            });
        },
    });
};
