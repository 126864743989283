import React, { useState } from 'react';
import { Menu } from '@/shared/components/Menu';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { PageLayout } from '@/shared/components/PageLayout';
import { useStyles } from '../migrator.styles';
import { useGetMiratorsList } from '../services/hooks/use-get-migrators-list';
import { TableBodyComponent } from '../components/TableBodyComponent';
export const MigratorsList = () => {
    const history = useHistory();
    const classes = useStyles();
    const columns = [
        { value: 'guid', label: 'Уникальный идентификатор' },
        { value: 'name', label: 'Название микросервиса' },
        { value: 'state', label: 'Статус' },
    ];
    const { data, refetch, isSuccess } = useGetMiratorsList();
    console.log(data);
    const rowsPerPageSetting = [10, 25, 50, 100];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageSetting[0]);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
        refetch();
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(Number(event.target.value));
        setPage(0);
        refetch();
    };
    // console.log(JSON.parse(dataResponse.huEntityString))
    return (React.createElement(PageLayout, null,
        React.createElement("div", null,
            React.createElement(Box, { component: "div", display: "inline" },
                React.createElement(Menu, { addButtonProps: {
                        disabled: false,
                        handleClick: () => history.push(`/migrator/add`),
                        label: 'Добавить микросервис',
                    } })),
            React.createElement(TableContainer, { component: Paper, className: classes.tableHideOverflow },
                React.createElement(Typography, { variant: "h4", className: classes.marginBott }, "\u041C\u0438\u043A\u0440\u043E\u0441\u0435\u0440\u0432\u0438\u0441\u044B \u043C\u0438\u0433\u0440\u0430\u0446\u0438\u0438"),
                React.createElement(Table, { className: classes.table, "aria-label": "simple table", stickyHeader: true },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null, columns.map((column) => (React.createElement(TableCell, { key: column.value, align: column.align, className: classes.tableRowHead, style: { width: column.minWidth } }, column.label))))),
                    React.createElement(TableBody, null, data?.migratorList.map((migrator) => (React.createElement(TableBodyComponent, { key: migrator.guid, migrator: migrator, columns: columns }))))),
                data && data.migratorList && (React.createElement(TablePagination, { labelRowsPerPage: 'Cтрок на странице', rowsPerPageOptions: rowsPerPageSetting, component: "div", count: data?.migratorList.length, rowsPerPage: rowsPerPage, page: page, onChangePage: handleChangePage, onChangeRowsPerPage: handleChangeRowsPerPage }))))));
};
