import { Box, Typography } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { useDeleteStage } from '@/pages/IntegrationChains/services/hooks/use-delete-stage';
import { useGetStagesDescription } from '@/pages/IntegrationChains/services/hooks/use-get-stages-description';
import { useGetInputLinksByStage } from '@/pages/IntegrationChains/services/hooks/use-get-input-links-by-stage';
import { useGetParentStages } from '@/pages/IntegrationChains/services/hooks/use-get-parent-stages';
import { useDeleteInputLink } from '@/pages/IntegrationChains/services/hooks/use-delete-input-link';
import { useCreateWorkflowStagesLink } from '@/pages/IntegrationChains/services/hooks/use-create-workflow-stages-link';
import { NewWorkflowStagesRow } from '../NewWorkflowStagesRow';
import { WorkflowStagesRow } from '../WorkflowStagesRow';
import { LinkRow } from '../LinkRow';
import { NewLinkRow } from '../NewLinkRow';
export const WorkflowStages = ({ guid, stageInfos, refetchChain }) => {
    const { data: stagesDescription } = useGetStagesDescription(guid);
    // const stagesDescription = stageInfos;
    const { mutate: deleteStage } = useDeleteStage(() => {
        refetchChain();
    });
    const { mutate: getInputLinksByStage, data: inputLinksByStage } = useGetInputLinksByStage(() => {
        refetchChain();
    });
    const { mutate: getParentStages, data: parentStages } = useGetParentStages();
    const { mutateAsync: deleteInputLink } = useDeleteInputLink();
    const { mutateAsync: createWorkflowStagesLink } = useCreateWorkflowStagesLink();
    const [showInputLinksForStage, setShowInputLinksForStage] = useState();
    const handleDeleteStage = (stageGuid) => {
        deleteStage(stageGuid);
    };
    const handleDeleteInputLink = async (linkGuid) => {
        try {
            await deleteInputLink(linkGuid);
            if (showInputLinksForStage) {
                getInputLinksByStage(showInputLinksForStage);
                getParentStages({ chainGuid: guid, currentStageGuid: showInputLinksForStage });
            }
        }
        catch (err) {
            return err;
        }
    };
    const handleLink = (stageGuid) => {
        if (showInputLinksForStage === stageGuid) {
            setShowInputLinksForStage(undefined);
        }
        else {
            getInputLinksByStage(stageGuid);
            getParentStages({ chainGuid: guid, currentStageGuid: stageGuid });
            setShowInputLinksForStage(stageGuid);
        }
    };
    const handleSaveLink = async (executionResultGuid, previousWorkflowStageGuid) => {
        if (!showInputLinksForStage) {
            return;
        }
        await createWorkflowStagesLink({
            integrationChainGuid: guid,
            nextWorkflowStageGuid: showInputLinksForStage,
            executionResultGuid,
            previousWorkflowStageGuid,
        });
        getParentStages({ chainGuid: guid, currentStageGuid: showInputLinksForStage });
        getInputLinksByStage(showInputLinksForStage);
    };
    return (React.createElement(Fragment, null,
        React.createElement(Box, { mt: 3, mb: 1 },
            React.createElement(Typography, { variant: "h5" }, "\u042D\u0442\u0430\u043F\u044B \u043F\u0440\u043E\u0446\u0435\u0441\u0441\u0430")),
        stagesDescription ? stagesDescription.stageInfos.map((stage, idx) => (React.createElement(Fragment, { key: stage.guid },
            React.createElement(WorkflowStagesRow, { guid: stage.guid, showLabels: idx === 0, onDeleteRow: handleDeleteStage, code: stage.code, description: stage.description || '', pluginType: stage.pluginTypeString || '', system: stage.systemInfo?.code || '', pluginName: stage.pluginInfo?.name || '', pluginMethodName: stage.pluginMethodInfo?.name || '', onLink: idx !== 0 ? handleLink : undefined }),
            stage.guid === showInputLinksForStage && (React.createElement(Box, { ml: "50px", width: "70%" },
                inputLinksByStage?.isSuccess &&
                    inputLinksByStage?.stagesLinkInfos?.map(({ guid, previousStageDescription, linkExecutionResultDescription }) => (React.createElement(LinkRow, { key: guid, guid: guid, code: previousStageDescription.code, description: previousStageDescription.description, result: linkExecutionResultDescription.code, onDeleteRow: handleDeleteInputLink }))),
                parentStages?.stageDescriptions && (React.createElement(NewLinkRow, { stageDescriptions: parentStages.stageDescriptions, onSave: handleSaveLink }))))))) : null,
        React.createElement(NewWorkflowStagesRow, { refetchChain: refetchChain, guid: guid })));
};
