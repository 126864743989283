import React, { useEffect, useState } from 'react';
import { useStyles } from "@/pages/Plugins/styles";
import { Box, Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { PageLayout } from '@/shared/components/PageLayout';
import { FormAdd } from "@/pages/Plugins/components/FormAdd";
import { useCreatePlugin } from "@/pages/Plugins/services/hooks/use-create-plugin";
import { useGetPlugins } from "@/shared/services/plugins/hooks/use-get-plugins";
export const PluginPageAdd = () => {
    const classes = useStyles();
    const history = useHistory();
    const { mutate: createNewPlugin, data: createPluginResponse, isLoading: sendingCreatePlugin } = useCreatePlugin();
    const [additionalValue] = useState('');
    const { refetch } = useGetPlugins(additionalValue);
    useEffect(() => {
        if (createPluginResponse?.isSuccess) {
            refetch();
            history.push(`/plugin/${createPluginResponse.guid}`);
        }
    }, [createPluginResponse]);
    return (React.createElement(PageLayout, null,
        React.createElement("div", { style: { width: '100%', marginBottom: '1rem' } },
            React.createElement(Box, { component: "div", display: "inline" },
                React.createElement(Button, { variant: "contained", size: "medium", className: classes.colorButtonBack, onClick: () => { history.push('/plugins'); } }, "\u041D\u0430\u0437\u0430\u0434 \u043A \u0441\u043F\u0438\u0441\u043A\u0443"))),
        React.createElement(FormAdd, { onSubmit: createNewPlugin, disabledSaveButton: sendingCreatePlugin })));
};
