import { useMutation, useQueryClient } from 'react-query';
import { useActionLogger } from '@/shared/hooks/action-logger';
import { updateMigratorSystem } from '..';
export const useUpdateMigratorSystem = () => {
    const queryClient = useQueryClient();
    const { setAction } = useActionLogger();
    return useMutation(updateMigratorSystem, {
        onSuccess: () => {
            queryClient.invalidateQueries('GetMigratorList');
            setAction({
                type: 'success',
                message: 'Migrator system was updated successful',
            });
        },
        onError: (error) => {
            setAction({
                type: 'error',
                message: error.toString(),
            });
        },
    });
};
