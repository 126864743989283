import React from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import LogoUrl from '@/shared/images/HyperUp-logo-horizontal-blue.png';
import { useAuth } from '@/shared/controllers/auth/context';
const useStyles = makeStyles(() => ({
    colorButton: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
    },
}));
export const PageHeader = () => {
    const { logout } = useAuth();
    const stylesClass = useStyles();
    return (React.createElement(Box, { component: "header", display: "flex", flexGrow: 1, alignItems: "center", height: 64 },
        React.createElement(Box, { mr: 8 },
            React.createElement("a", { href: "/management" },
                React.createElement("img", { src: LogoUrl, alt: "HyperUp logo", width: 140, height: 25 }))),
        React.createElement(Box, { ml: "auto" },
            React.createElement(Button, { variant: "contained", className: stylesClass.colorButton, onClick: logout }, "\u0412\u044B\u0445\u043E\u0434"))));
};
