import { useMutation, useQueryClient } from 'react-query';
import { useActionLogger } from '@/shared/hooks/action-logger';
import { registerPluginInstance } from '../index';
export const useRegisterInstance = (callback) => {
    const queryClient = useQueryClient();
    const { setAction } = useActionLogger();
    return useMutation('RegisterPluginInstance', registerPluginInstance, {
        onSuccess: (resp) => {
            if (resp?.isSuccess) {
                queryClient.invalidateQueries('GetAllPluginInstances');
                setAction({
                    type: 'success',
                    message: 'Plugin instance successfully registered',
                });
            }
            else if (resp?.fieldValidationErrors) {
                if (callback && typeof callback === 'function') {
                    callback(resp.fieldValidationErrors);
                }
            }
        },
        onError: (error) => {
            setAction({
                type: 'error',
                message: error.toString(),
            });
        },
    });
};
