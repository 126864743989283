import { client } from '@/shared/api';
const PATH_AUTH_API = 'head/Migrator';
export const getMigratorsList = async () => {
    const { data } = await client.get(`${PATH_AUTH_API}/GetList`);
    if (!data.isSuccess) {
        throw new Error(`GetList. ${data.errorMessage}`);
    }
    return data;
};
// TODO: запросить сделать метод post, или узнать как отправлять body через axios с методом get
export const getEntitiesWithProperties = async (bodyParams) => {
    const { data } = await client.get(`${PATH_AUTH_API}/RequestEntityEntriesWithKeysPropertiesValue?migratorGuid=${bodyParams.entityGuid}&chainCode=${bodyParams.chainCode}`);
    if (!data.isSuccess) {
        throw new Error(`GetEntitiesWithProperties. ${data.errorMessage}`);
    }
    return data;
};
export const createMigrator = async (migratorData) => {
    const { data } = await client.post(`${PATH_AUTH_API}/Create`, migratorData);
    if (!data.isSuccess) {
        throw new Error(`CreateMigrator. ${data.errorMessage}`);
    }
    return data;
};
export const updateMigratorState = async (additionlInfo) => {
    const { data } = await client.post(`${PATH_AUTH_API}/RegisterHead?migratorGuid=${additionlInfo}`);
    if (!data.isSuccess) {
        throw new Error(`RegisterHead. ${data.errorMessage}`);
    }
    return data;
};
export const updateMigratorSystem = async (updMigData) => {
    const { data } = await client.post(`${PATH_AUTH_API}/UpdateSystem`, updMigData);
    if (!data.isSuccess) {
        throw new Error(`UpdateSystem. ${data.errorMessage}`);
    }
    return data;
};
export const createMigartorEntity = async (entity) => {
    const { data } = await client.post(`${PATH_AUTH_API}/CreateEntity`, entity);
    if (!data.isSuccess) {
        throw new Error(`CreateEntity. ${data.errorMessage}`);
    }
    return data;
};
export const initiateChain = async (chain) => {
    const { data } = await client.post(`${PATH_AUTH_API}/InitiateChainForMetadata`, chain);
    if (!data.isSuccess) {
        throw new Error(`InitiateChainForMetadata. ${data.errorMessage}`);
    }
    return data;
};
export const getMetadataModel = async (additionlInfo) => {
    const { data } = await client.get(`${PATH_AUTH_API}/GetMetadataModel${additionlInfo}`);
    if (!data.isSuccess) {
        throw new Error(`GetMetadataModel. ${data.errorMessage}`);
    }
    return data;
};
export const getMigratorInfo = async (additionalInfo) => {
    const { data } = await client.get(`${PATH_AUTH_API}/GetInfo${additionalInfo}`);
    if (!data.isSuccess) {
        throw new Error(`GetInfo. ${data.errorMessage}`);
    }
    return data;
};
export const getListMigPropertyLink = async (additionalInfo) => {
    const { data } = await client.get(`${PATH_AUTH_API}/GetListMigPropertyLink${additionalInfo}`);
    if (!data.isSuccess) {
        throw new Error(`GetListMigPropertyLink. ${data.errorMessage}`);
    }
    return data;
};
export const createMigLink = async (linkData) => {
    const { data } = await client.post(`${PATH_AUTH_API}/CreateMigPropertyLink`, linkData);
    if (!data.isSuccess) {
        throw new Error(`CreateMigPropertyLink. ${data.errorMessage}`);
    }
    return data;
};
export const deleteLink = async (additionalInfo) => {
    const { data } = await client.get(`${PATH_AUTH_API}/DeleteMigPropertyLink${additionalInfo}`);
    if (!data.isSuccess) {
        throw new Error(`CreateMigPropertyLink. ${data.errorMessage}`);
    }
    return data;
};
export const getRebuildImportTree = async (additionalInfo) => {
    const { data } = await client.get(`${PATH_AUTH_API}/RebuildImportTree${additionalInfo}`);
    return data;
};
