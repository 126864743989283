import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
const useStyles = makeStyles(() => ({
    colorButton: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        marginBottom: '1rem',
        marginTop: '1rem',
        textAlign: 'center',
    },
}));
export const UploadFiles = ({ onSendFiles, isDisabled }) => (React.createElement(Button, { variant: "contained", className: useStyles().colorButton, component: "label", disabled: isDisabled },
    "\u0417\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u0444\u0430\u0439\u043B\u044B",
    React.createElement("input", { type: "file", hidden: true, multiple: true, onChange: async ({ target }) => {
            if (!target.files)
                return;
            Array.from(target.files).forEach(async (file) => {
                await onSendFiles(file);
            });
        } })));
