import { client } from '@/shared/api';
const PATH_AUTH_API = 'Admin';
export const getAllSystems = async (additionlInfo) => {
    const { data } = await client.get(`${PATH_AUTH_API}/GetAllSystems${additionlInfo}`);
    if (!data.isSuccess) {
        throw new Error(`GetNextWorkflowStageType. ExceptionString: ${data.errorMessage}`);
    }
    return data;
};
export const getSystem = async (additionlInfo) => {
    const { data } = await client.get(`${PATH_AUTH_API}/GetSystemByGuid${additionlInfo}`);
    if (!data.isSuccess) {
        throw new Error(`GetSystemByGuid. ExceptionString: ${data.errorMessage}`);
    }
    return data;
};
export const getNeededPlugins = async () => {
    const { data } = await client.get(`${PATH_AUTH_API}/GetPluginsMetadata`);
    return data;
};
export const deleteSystem = async (guid) => {
    const { data } = await client.delete(`${PATH_AUTH_API}/DeleteSystem/${guid}`);
    if (!data.isSuccess) {
        throw new Error(`DeleteSystem error. ${data.errorMessage}`);
    }
    return data;
};
export const createSystemSecret = async (config) => {
    const { data } = await client.post(`${PATH_AUTH_API}/CreateSystemSecret`, config);
    if (!data.isSuccess) {
        throw new Error(`CreateSystemSecret. ${data.errorMessage}`);
    }
    return data;
};
export const registerSystem = async (config) => {
    const { data } = await client.post(`${PATH_AUTH_API}/RegisterSystem`, config);
    if (!data.isSuccess) {
        if (data.fieldValidationErrors) {
            return data;
        }
        else {
            throw new Error(`RegisterSystem. ${data.errorMessage}`);
        }
    }
    return data;
};
export const updateSystem = async (config) => {
    const { data } = await client.patch(`${PATH_AUTH_API}/UpdateSystem`, config);
    if (!data.isSuccess) {
        throw new Error(`UpdateSystem. ${data.errorMessage}`);
    }
    return data;
};
