import React, { useEffect } from 'react';
import { Box, Button, Grid, IconButton, makeStyles, MenuItem, TextField, Typography, FormHelperText, FormControl, Select } from '@material-ui/core';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import DeleteIcon from '@material-ui/icons/Delete';
const useStyles = makeStyles((theme) => ({
    form: {
        width: '400px',
        marginLeft: '2rem',
        // height: '570px',
        paddingBottom: '1.5rem',
    },
    input: {
        marginBottom: theme.spacing(2),
    },
    colorButtons: {
        backgroundColor: '#0066CC',
        color: 'white',
    },
    marginTopColorGrid: {
        // marginTop: '2rem',
        fontSize: '15px',
    },
    textAliginEnd: {
        textAlign: 'end',
        marginTop: '8px',
    },
    textAliginEndColor: {
        textAlign: 'end',
        marginTop: '8px',
        color: 'grey',
    },
    color: {
        color: 'grey',
    },
    button: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        marginTop: '1rem',
    },
    paddingBott: {
        paddingTop: '1rem',
        paddingBottom: '2rem',
        marginLeft: '2rem',
    },
    reMarginTop: {
        marginTop: '-16px',
        display: 'block'
    }
}));
export const FormAdd = ({ onSubmit, systemResponseError, 
// submittingSystem,
serializationOptions, integrationOptions, pluginInstanceOptions, objectServeOptions, }) => {
    const styles = useStyles();
    const { register, control, handleSubmit, setValue, errors } = useForm();
    const { fields, append, remove } = useFieldArray({ control, name: 'systemSecrets' });
    useEffect(() => {
        register('code');
        register('description');
        register('serializationPluginGuid');
        register('integrationPluginGuid');
        register('objectServePluginGuid');
        register('pluginInstanceGuid');
    }, [register]);
    const getErrorByName = (name) => systemResponseError?.filter((error) => String(error?.fieldName).toLowerCase() === String(name).toLowerCase())[0];
    return (React.createElement("form", { className: styles.form, onSubmit: handleSubmit((data) => onSubmit(data)) },
        React.createElement(Typography, { variant: "h5", className: styles.paddingBott }, "\u0414\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u0441\u0438\u0441\u0442\u0435\u043C\u044B"),
        React.createElement(Box, { className: styles.form, justifyContent: "flex-start" },
            React.createElement(Grid, { container: true, spacing: 1, className: styles.marginTopColorGrid },
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u041A\u043E\u0434 *"),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(TextField, { name: "code", className: styles.input, fullWidth: true, id: "outlined-basic", variant: "outlined", size: "small", onChange: ({ target }) => setValue('code', target.value), error: Boolean(getErrorByName('code') && getErrorByName('code')?.fieldErrors !== null), "aria-describedby": "my-helper-code-text" }),
                    React.createElement(FormHelperText, { error: true, id: "my-helper-code-text" }, getErrorByName('code')?.fieldErrors?.map((error, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    React.createElement("span", { className: styles.reMarginTop, key: index }, error))))),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435 *"),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(TextField, { name: "description", className: styles.input, fullWidth: true, id: "outlined-basic", variant: "outlined", size: "small", onChange: ({ target }) => setValue('description', target.value), error: Boolean(getErrorByName('description') && getErrorByName('description')?.fieldErrors !== null), "aria-describedby": "my-helper-description-text" }),
                    React.createElement(FormHelperText, { error: true, id: "my-helper-description-text" }, getErrorByName('description')?.fieldErrors?.map((error, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    React.createElement("span", { className: styles.reMarginTop, key: index }, error))))),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u0421\u0435\u0440\u0438\u0430\u043B\u0438\u0437\u0430\u0446\u0438\u043E\u043D\u043D\u044B\u0439 \u043F\u043B\u0430\u0433\u0438\u043D"),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(FormControl, { error: Boolean(getErrorByName('serializationPluginGuid') && getErrorByName('serializationPluginGuid')?.fieldErrors !== null), className: styles.input, fullWidth: true, id: "standard-select-currency", variant: "outlined", size: "small", "aria-describedby": "my-helper-serializationPluginGuid-text" },
                        React.createElement(Select, { name: "serializationPluginGuid", onChange: ({ target }) => setValue('serializationPluginGuid', target.value) }, serializationOptions.map(({ label, value }) => (React.createElement(MenuItem, { key: value, value: value }, label)))),
                        React.createElement(FormHelperText, { error: true, id: "my-helper-serializationPluginGuid-text" }, getErrorByName('serializationPluginGuid')?.fieldErrors?.map((error, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        React.createElement("span", { key: index }, error)))))),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u0418\u043D\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u043E\u043D\u043D\u044B\u0439 \u043F\u043B\u0430\u0433\u0438\u043D"),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(FormControl, { error: Boolean(getErrorByName('integrationPluginGuid') && getErrorByName('integrationPluginGuid')?.fieldErrors !== null), className: styles.input, fullWidth: true, id: "standard-select-currency", variant: "outlined", size: "small", "aria-describedby": "my-helper-integrationPluginGuid-text" },
                        React.createElement(Select, { name: "integrationPluginGuid", onChange: ({ target }) => setValue('integrationPluginGuid', target.value) }, integrationOptions.map(({ label, value }) => (React.createElement(MenuItem, { key: value, value: value }, label)))),
                        React.createElement(FormHelperText, { error: true, id: "my-helper-integrationPluginGuid-text" }, getErrorByName('integrationPluginGuid')?.fieldErrors?.map((error, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        React.createElement("span", { key: index }, error)))))),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u041F\u043B\u0430\u0433\u0438\u043D \u043E\u0442\u043B\u043E\u0436\u0435\u043D\u043D\u043E\u0439 \u043E\u0447\u0435\u0440\u0435\u0434\u0438"),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(FormControl, { error: Boolean(getErrorByName('objectServePluginGuid') && getErrorByName('objectServePluginGuid')?.fieldErrors !== null), className: styles.input, fullWidth: true, id: "standard-select-currency", variant: "outlined", size: "small", "aria-describedby": "my-helper-objectServePluginGuid-text" },
                        React.createElement(Select, { name: "pluginInstanceGuid", onChange: ({ target }) => setValue('objectServePluginGuid', target.value) }, objectServeOptions.map(({ label, value }) => (React.createElement(MenuItem, { key: value, value: value }, label)))),
                        React.createElement(FormHelperText, { error: true, id: "my-helper-objectServePluginGuid-text" }, getErrorByName('objectServePluginGuid')?.fieldErrors?.map((error, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        React.createElement("span", { key: index }, error)))))),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u0410\u0433\u0435\u043D\u0442"),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(FormControl, { error: Boolean(getErrorByName('pluginInstanceGuid') && getErrorByName('pluginInstanceGuid')?.fieldErrors !== null), className: styles.input, fullWidth: true, id: "standard-select-currency", variant: "outlined", size: "small", "aria-describedby": "my-helper-pluginInstanceGuid-text" },
                        React.createElement(Select, { name: "pluginInstanceGuid", onChange: ({ target }) => setValue('pluginInstanceGuid', target.value) }, pluginInstanceOptions.map(({ label, value }) => (React.createElement(MenuItem, { key: value, value: value }, label)))),
                        React.createElement(FormHelperText, { error: true, id: "my-helper-pluginInstanceGuid-text" }, getErrorByName('pluginInstanceGuid')?.fieldErrors?.map((error, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        React.createElement("span", { key: index }, error)))))),
                fields && fields.length ? (React.createElement(React.Fragment, null,
                    React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u0421\u0435\u043A\u0440\u0435\u0442"),
                    React.createElement(Grid, { item: true, xs: 6 }, fields.map((item, index) => (React.createElement(Box, { key: item.id, display: "flex", alignItems: "center" },
                        React.createElement(Box, { mr: 1, width: "150px" },
                            React.createElement(Controller, { as: React.createElement(TextField, { className: styles.input, fullWidth: true, label: "\u0422\u0438\u043F", 
                                    // required
                                    select: true, defaultValue: "", id: "standard-select-currency", variant: "outlined", size: "small" },
                                    React.createElement(MenuItem, { value: 1 }, "\u041B\u043E\u0433\u0438\u043D"),
                                    React.createElement(MenuItem, { value: 2 }, "\u041F\u0430\u0440\u043E\u043B\u044C"),
                                    React.createElement(MenuItem, { value: 3 }, "\u0422\u043E\u043A\u0435\u043D"),
                                    React.createElement(MenuItem, { value: 4 }, "\u0421\u0435\u0440\u0432\u0435\u0440"),
                                    React.createElement(MenuItem, { value: 5 }, "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0411\u0414"),
                                    React.createElement(MenuItem, { value: 6 }, "\u0425\u043E\u0441\u0442"),
                                    React.createElement(MenuItem, { value: 7 }, "\u041F\u043E\u0440\u0442")), name: `updateSystemSecrets[${index}].secretType`, control: control, defaultValue: item.type })),
                        React.createElement(Box, { width: "200px" },
                            React.createElement(TextField, { type: item.type === 2 ? 'password' : 'text', className: styles.input, fullWidth: true, id: "standard-select-currency", variant: "outlined", size: "small", label: "\u0417\u043D\u0430\u0447\u0435\u043D\u0438\u0435", 
                                // required
                                name: `updateSystemSecrets[${index}].value`, defaultValue: item.value })),
                        React.createElement(IconButton, { edge: "end", onClick: () => remove(index) },
                            React.createElement(DeleteIcon, null)))))))) : null),
            React.createElement("div", { style: { width: '100%' } },
                React.createElement(Box, { component: "div", display: "inline" },
                    React.createElement(Button, { variant: "contained", className: styles.button, type: "submit" }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")),
                React.createElement(Box, { component: "div", display: "inline", m: 1 },
                    React.createElement(Button, { variant: "contained", onClick: () => append({ secretType: 1, value: '' }), className: styles.button }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0441\u0435\u043A\u0440\u0435\u0442"))))));
};
