import { TreeNodeType } from '@/pages/Migrators/types';
export const getColorByType = (type) => {
    let color;
    switch (type) {
        case TreeNodeType.Type1:
            color = '#911eb4';
            break;
        case TreeNodeType.Type2:
        case TreeNodeType.Type6:
            color = '#e6194B';
            break;
        case TreeNodeType.Type3:
            color = '#f58231';
            break;
        case TreeNodeType.Type5:
            color = '#f032e6';
            break;
        case TreeNodeType.Type7:
            color = '#42d4f4';
            break;
        case TreeNodeType.Type8:
            color = '#000075';
            break;
        case TreeNodeType.Type9:
            color = '#808000';
            break;
        case TreeNodeType.Type10:
            color = '#800000';
            break;
        default:
            color = '#000000';
            break;
    }
    return color;
};
