import React, { useContext } from 'react';
const notImplemented = () => {
    throw new Error(`Not implemented`);
};
export const AuthContext = React.createContext({
    login: notImplemented,
    logout: notImplemented,
    isAuth: false,
});
AuthContext.displayName = 'AuthContext';
export const useAuth = () => useContext(AuthContext);
