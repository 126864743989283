import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthProvider } from '@/shared/controllers/auth/provider';
import { ActionLoggerProvider } from '@/shared/hooks/action-logger';
import { App } from '@/App';
//
// High level component Loader
import { Loader } from "@/shared/components/Loader/Loader";
import { ToastContainer } from 'react-toastify';
// react-query instance
import { queryClient } from '@/plugins/reactQuery';
// Redux Store
// import store from './store'
import "./index.scss";
const showReactQueryDevtools = false;
ReactDOM.render(React.createElement(QueryClientProvider, { client: queryClient },
    React.createElement(ActionLoggerProvider, null,
        React.createElement(BrowserRouter, null,
            React.createElement(AuthProvider, null,
                React.createElement(Loader, null),
                React.createElement(CssBaseline, null),
                React.createElement(App, null),
                React.createElement(ToastContainer, { position: "top-right", autoClose: 5000, hideProgressBar: false, newestOnTop: false, closeOnClick: true, rtl: false, pauseOnFocusLoss: true, draggable: true, pauseOnHover: true }),
                showReactQueryDevtools &&
                    React.createElement(ReactQueryDevtools, { initialIsOpen: false }))))), document.getElementById('root'));
