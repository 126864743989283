import { useMutation } from 'react-query';
import { useActionLogger } from "@/shared/hooks/action-logger";
import { getTransformationPluginsAvailable } from '..';
export const useGetTransformationPluginsAvailable = () => {
    const { setAction } = useActionLogger();
    // type TransformationPluginAvailable = {
    //   isSuccess: boolean;
    //   errorFullMessage: string;
    //   errorMessage: string;
    //   errorCode: number;
    //   pluginCreateDescriptions: [];
    // };
    return useMutation(getTransformationPluginsAvailable, {
        onSuccess: (resp) => {
            if (resp?.isSuccess) {
                setAction({
                    type: 'success',
                    message: `GetTransformationPluginsAvailable ${resp?.errorMessage ? resp?.errorMessage : ' Success'}`,
                });
            }
            else {
                setAction({
                    type: 'error',
                    // eslint-disable-next-line no-nested-ternary
                    message: `GetTransformationPluginsAvailable ${resp?.errorMessage ? resp.errorMessage : resp?.errorFullMessage ? resp.errorFullMessage : ' Error'}`,
                });
            }
            if (resp?.errorFullMessage)
                console.error(resp?.errorFullMessage);
        },
        onError: (error) => {
            setAction({
                type: 'error',
                message: `GetTransformationPluginsAvailable${error?.errorMessage}`,
            });
        },
    });
};
