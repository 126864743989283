import React, { useState } from 'react';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { WorkflowStages } from './components/WorkflowStages';
import { ChainScheme } from './components/ChainScheme';
const useStyles = makeStyles((theme) => ({
    form: {
        width: '600px',
    },
    input: {
        marginBottom: theme.spacing(2),
        width: '400px',
        height: '40px',
    },
    button: {
        borderRadius: '0px',
    },
    colorButton: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        marginTop: '1rem',
    },
    color: {
        color: 'grey',
    },
    paddingTop: {
        paddingTop: '2rem',
    },
    marginTopColorGrid: {
        color: 'grey',
        marginTop: '2rem',
        fontSize: '15px',
    },
    textAlignEnd: {
        textAlign: 'end',
    },
    margin: {
        margin: '0 2rem',
    },
}));
export const FormEdit = ({ defaultValues, stageInfos, refetchChain }) => {
    const styles = useStyles();
    const [showSchemeScreen, setShowSchemeScreen] = useState(false);
    const toggleShowSchemeScreen = () => {
        setShowSchemeScreen((state) => !state);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.margin },
            showSchemeScreen ? (React.createElement(ChainScheme, { guid: defaultValues.guid, name: defaultValues.code })) : (React.createElement(React.Fragment, null,
                React.createElement(Typography, { variant: "h5", className: styles.paddingTop }, "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E\u0431 \u0438\u043D\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u043E\u043D\u043D\u043E\u043C \u043F\u0440\u043E\u0446\u0435\u0441\u0441\u0435"),
                React.createElement(Box, { className: styles.form },
                    React.createElement(Grid, { container: true, spacing: 1, className: styles.marginTopColorGrid },
                        React.createElement(Grid, { item: true, xs: 6, className: styles.textAlignEnd }, "\u041A\u043E\u0434:"),
                        React.createElement(Grid, { item: true, xs: 6, className: styles.color }, defaultValues.code),
                        React.createElement(Grid, { item: true, xs: 6, className: styles.textAlignEnd }, "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435:"),
                        React.createElement(Grid, { item: true, xs: 6, className: styles.color }, defaultValues.description || ''),
                        React.createElement(Grid, { item: true, xs: 6, className: styles.textAlignEnd }, "\u0423\u043D\u0438\u043A\u0430\u043B\u044C\u043D\u044B\u0439 \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440:"),
                        React.createElement(Grid, { item: true, xs: 6, className: styles.color }, defaultValues.guid))),
                React.createElement(WorkflowStages, { refetchChain: refetchChain, guid: defaultValues.guid, stageInfos: stageInfos }))),
            React.createElement(Box, { ml: 1, mt: 4, display: "-webkit-inline-box", justifyContent: "center" },
                React.createElement(Box, { mr: 1, mt: 2, mb: 4, className: styles.button },
                    React.createElement(Button, { variant: "contained", className: styles.colorButton, onClick: toggleShowSchemeScreen }, showSchemeScreen ? 'Редактировать цепочку' : 'Схема'))))));
};
