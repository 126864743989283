import { Button, List, ListItem, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
const useStyles = makeStyles(() => ({
    colorButton: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        marginBottom: '1rem',
        marginTop: '2rem',
    },
}));
export const MenuItem = ({ handleClickItem, isActive, label }) => (React.createElement(ListItem, { button: true, onClick: handleClickItem },
    React.createElement(Box, { fontWeight: isActive ? 600 : 400, fontSize: 16, py: 1 }, label)));
export const Menu = ({ menuItems, addButtonProps, children }) => (React.createElement(React.Fragment, null,
    React.createElement(Button, { className: useStyles().colorButton, variant: "contained", size: "medium", disabled: addButtonProps.disabled, onClick: addButtonProps.handleClick }, addButtonProps.label),
    menuItems && menuItems.length > 0 && (React.createElement(List, null, menuItems.map(({ id, label, isActive, handleClickItem }) => (React.createElement(MenuItem, { key: id, handleClickItem: handleClickItem, label: label, isActive: isActive }))))),
    children));
