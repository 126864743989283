import React from 'react';
import { Button, TableCell, TableRow } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { MigratorState } from '../types';
import { useStyles } from '../migrator.styles';
import { useRegisterStateHead } from '../services/hooks/use-register-state-head';
import { useGetMiratorsList } from '../services/hooks/use-get-migrators-list';
export const TableBodyComponent = ({ migrator, columns }) => {
    const classes = useStyles();
    const migratorStateLabels = {
        [MigratorState.Unregistered]: 'Не зарегистрирован',
        [MigratorState.Registered]: 'Зарегистрирован',
        [MigratorState.SystemsCreated]: 'Системы добавлены',
        [MigratorState.MetaDataReceived]: 'Метаданные получены',
        [MigratorState.MappingCreated]: 'Связи созданы',
        [MigratorState.ModelCreated]: 'Модель импорта создана',
        [MigratorState.KeysReceived]: 'Значения ключевых полей получены',
        [MigratorState.ReadyForMigration]: 'Готов к миграции данных',
    };
    const { refetch } = useGetMiratorsList();
    const { mutate: updateState, isLoading } = useRegisterStateHead(() => refetch());
    return (React.createElement(TableRow, null, columns.map((column) => {
        const value = migrator[column.value];
        column.value;
        return (React.createElement(TableCell, { component: "section", scope: "row", key: column.value, className: classes.tableRow, style: { minWidth: column.minWidth } }, column.value === 'guid' && migrator.state !== 1 ? (React.createElement(NavLink, { to: `/migrator/${migrator.guid}`, style: { color: 'black' } }, (column.component && column.component(value)) || value)) : (
        // eslint-disable-next-line no-nested-ternary
        (column.component && column.component(value)) ||
            (column.value === 'state' ? (migrator.state === 1 ? (React.createElement(Button, { variant: "contained", className: classes.registerButton, onClick: () => updateState(migrator.guid), disabled: isLoading }, "\u0417\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u0442\u044C")) : (migratorStateLabels[migrator[column.value]])) : (value)))));
    })));
};
