import React, { useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { PageLayout } from '@/shared/components/PageLayout';
import Paper from '@material-ui/core/Paper';
import { Box, Button } from '@material-ui/core';
import { InstanceView } from "@/pages/PluginInstances/components/InstanceView";
import { useStyles } from "@/pages/PluginInstances/styles";
import { useGetPluginInstance } from '@/shared/services/instances/hooks/use-get-plugin-instances';
import { useGetInitializeInstanceByGuid } from '@/shared/services/instances/hooks/use-get-initialize-instance';
import { useActionLogger } from '@/shared/hooks/action-logger';
import { useDeleteInstance } from './services/hooks/use-delete-instance';
import { useSetInstanceServiceModeByGuid } from '@/shared/services/instances/hooks/use-set-instance-service-mode';
var HuMessageStateType;
(function (HuMessageStateType) {
    HuMessageStateType[HuMessageStateType["InstanceValidationCompleted"] = 1] = "InstanceValidationCompleted";
    HuMessageStateType[HuMessageStateType["InstanceSerializationCompleted"] = 3] = "InstanceSerializationCompleted";
    HuMessageStateType[HuMessageStateType["InstanceSerializationFailed"] = 4] = "InstanceSerializationFailed";
    HuMessageStateType[HuMessageStateType["InstanceDataChunksCreated"] = 5] = "InstanceDataChunksCreated";
    HuMessageStateType[HuMessageStateType["InstanceDataChunksCreationFailed"] = 6] = "InstanceDataChunksCreationFailed";
    HuMessageStateType[HuMessageStateType["InstanceQueuedForFileLoad"] = 7] = "InstanceQueuedForFileLoad";
    HuMessageStateType[HuMessageStateType["InstanceAllFilesLoaded"] = 8] = "InstanceAllFilesLoaded";
    HuMessageStateType[HuMessageStateType["InstanceHasSendToHead"] = 9] = "InstanceHasSendToHead";
    HuMessageStateType[HuMessageStateType["HeadMessageReceivedFromPlugin"] = 10] = "HeadMessageReceivedFromPlugin";
    HuMessageStateType[HuMessageStateType["HeadWorkflowReady"] = 11] = "HeadWorkflowReady";
    HuMessageStateType[HuMessageStateType["HeadTransformationReady"] = 12] = "HeadTransformationReady";
    HuMessageStateType[HuMessageStateType["HeadIntegrationReady"] = 13] = "HeadIntegrationReady";
    HuMessageStateType[HuMessageStateType["InstanceMessageReceivedFromHead"] = 14] = "InstanceMessageReceivedFromHead";
    HuMessageStateType[HuMessageStateType["HeadWaitForExternalSystem"] = 15] = "HeadWaitForExternalSystem";
    HuMessageStateType[HuMessageStateType["InstanceReadyToIntegrationPlugin"] = 16] = "InstanceReadyToIntegrationPlugin";
    HuMessageStateType[HuMessageStateType["InstanceIntegrationPluginExecutionComplete"] = 17] = "InstanceIntegrationPluginExecutionComplete";
    HuMessageStateType[HuMessageStateType["HeadTransformationFailed"] = 18] = "HeadTransformationFailed";
    HuMessageStateType[HuMessageStateType["InstanceIntegrationPluginExecutionFailed"] = 19] = "InstanceIntegrationPluginExecutionFailed";
    HuMessageStateType[HuMessageStateType["InstanceObjectServePluginExecutionComplete"] = 20] = "InstanceObjectServePluginExecutionComplete";
    HuMessageStateType[HuMessageStateType["InstanceObjectServePluginExecutionFailed"] = 21] = "InstanceObjectServePluginExecutionFailed";
    HuMessageStateType[HuMessageStateType["HeadMessagePaused"] = 22] = "HeadMessagePaused";
    HuMessageStateType[HuMessageStateType["HeadWorkComplete"] = 100] = "HeadWorkComplete";
})(HuMessageStateType || (HuMessageStateType = {}));
export const PluginInstancePage = () => {
    const { guid } = useParams();
    const [additionalValue, setAdditionalValue] = useState(`?guid=${guid}`);
    const { setAction } = useActionLogger();
    const classes = useStyles();
    const history = useHistory();
    const { mutate: deleteInstance } = useDeleteInstance();
    const { data: instanceDetails } = useGetPluginInstance(additionalValue);
    const { refetch: getInitialize } = useGetInitializeInstanceByGuid(guid, false);
    const { refetch: setInstanceServiceMode } = useSetInstanceServiceModeByGuid(guid, false);
    const handleInitializationClick = () => {
        getInitialize().then(({ data }) => {
            if (!data?.isSuccess) {
                setAction({
                    type: 'error',
                    message: data?.errorFullMessage,
                });
            }
            else {
                setAction({
                    type: 'success',
                    message: 'Agent initialized successfully',
                });
            }
        });
    };
    const formatErrorMessage = (array) => {
        const preparedArray = array.map((message) => HuMessageStateType[message.stateType]);
        const formattedErrorMsg = [...new Set(preparedArray)].join(', ');
        return `The agent was not switched to the service mode, because the messages of ${formattedErrorMsg} types are not processed`;
    };
    const handleSetInstanceServiceMode = () => {
        setInstanceServiceMode().then(({ data }) => {
            if (!data?.isSuccess) {
                setAction({
                    type: 'error',
                    message: formatErrorMessage(data?.notCompletedMessageInfos),
                });
            }
            else {
                setAction({
                    type: 'success',
                    message: 'Agent successfully switched to service mode',
                });
            }
        });
    };
    return (React.createElement(PageLayout, null,
        React.createElement("div", { className: classes.tableHideOverflow },
            React.createElement(Box, { component: "div", display: "inline" },
                React.createElement(Button, { variant: "contained", size: "medium", className: classes.colorButtonBack, onClick: () => { history.push('/plugin-instances'); } }, "\u041D\u0430\u0437\u0430\u0434 \u043A \u0441\u043F\u0438\u0441\u043A\u0443"))),
        instanceDetails && instanceDetails.pluginInstanceInfo ?
            React.createElement(Paper, null,
                React.createElement(InstanceView, { instanceDetails: instanceDetails.pluginInstanceInfo, onDelete: (guid) => {
                        deleteInstance(guid);
                        // setViewMode({ type: 'default' });
                    } }),
                React.createElement(Box, { css: { paddingBottom: '3rem', paddingLeft: '9rem' } },
                    React.createElement(Button, { variant: "contained", size: "medium", className: `${classes.colorButtonBack} ${classes.fontSmall}`, onClick: handleSetInstanceServiceMode },
                        "\u041F\u0435\u0440\u0435\u0432\u0435\u0441\u0442\u0438 \u0432 ",
                        React.createElement("br", null),
                        " \u0440\u0435\u0436\u0438\u043C \u043E\u0431\u0441\u043B\u0443\u0436\u0438\u0432\u0430\u043D\u0438\u044F"),
                    React.createElement(Box, { css: { marginLeft: '2rem', display: 'inline-block' } },
                        React.createElement(Button, { variant: "contained", size: "medium", className: `${classes.colorButtonBack} ${classes.fontSmall}`, onClick: handleInitializationClick },
                            React.createElement("div", { className: classes.innerBtnTextStyles }, "\u0418\u043D\u0438\u0446\u0438\u0430\u043B\u0438\u0437\u0438\u0440\u043E\u0432\u0430\u0442\u044C")))))
            : null,
        instanceDetails && !instanceDetails.isSuccess ?
            React.createElement("h2", null, "\u041F\u0440\u043E\u0438\u0437\u043E\u0448\u043B\u0430 \u043E\u0448\u0438\u0431\u043A\u0430") : null));
};
