import React, { useState } from 'react';
import { TextField, Box, makeStyles, Select, MenuItem, Button } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    input: {
        marginRight: theme.spacing(1),
        flex: 1,
        flexShrink: 0,
    },
}));
export const NewLinkRow = ({ stageDescriptions, onSave }) => {
    const styles = useStyles();
    const initialSelectedStage = stageDescriptions[0]?.guid || undefined;
    const [selectedStage, setSelectedStage] = useState(initialSelectedStage);
    const executionResultOptions = stageDescriptions.find((stage) => stage.guid === selectedStage)
        ?.executionResultDescriptions;
    const initialExecutionResult = executionResultOptions?.[0]?.guid || undefined;
    const [executionResult, setExecutionResult] = useState(initialExecutionResult);
    const selectedStageDescription = stageDescriptions.find((stage) => stage.guid === selectedStage)?.description || '';
    return (React.createElement(Box, { mb: 1, display: "flex", alignItems: "center" },
        React.createElement(Select, { className: styles.input, value: selectedStage || '', onChange: ({ target }) => setSelectedStage(target.value), disabled: stageDescriptions.length === 0 }, stageDescriptions.map(({ code, guid }) => (React.createElement(MenuItem, { key: guid, value: guid }, code)))),
        React.createElement(TextField, { className: styles.input, value: selectedStageDescription, disabled: true }),
        React.createElement(Select, { className: styles.input, value: executionResult || '', onChange: ({ target }) => setExecutionResult(target.value), disabled: !executionResult }, executionResultOptions?.map(({ code, guid }) => (React.createElement(MenuItem, { key: guid, value: guid }, code)))),
        React.createElement(Box, { minWidth: "110px" },
            React.createElement(Button, { variant: "outlined", color: "primary", disabled: !selectedStage || !executionResult, onClick: () => {
                    if (!selectedStage || !executionResult) {
                        return;
                    }
                    onSave(executionResult, selectedStage);
                } }, "Save"))));
};
