import React, { useEffect, useState } from 'react';
import { useStyles } from "@/pages/IntegrationChains/styles";
import { Box, Button, Paper } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { PageLayout } from '@/shared/components/PageLayout';
import { FormAdd } from "@/pages/IntegrationChains/components/FormAdd";
import { useCreateIntegrationChain } from "@/pages/IntegrationChains/services/hooks/use-create-integration-chain";
import { useGetAllChains } from "@/pages/IntegrationChains/services/hooks/use-get-all-chains";
export const IntegrationChainPageAdd = () => {
    const classes = useStyles();
    const history = useHistory();
    const { mutate: createIntegrationChain, data: integrationChainResponse } = useCreateIntegrationChain();
    const [additionalValue] = useState('');
    const { refetch } = useGetAllChains(additionalValue);
    useEffect(() => {
        if (integrationChainResponse?.isSuccess) {
            refetch();
            history.push(`/integration-chain/${integrationChainResponse.guid}`);
        }
    }, [integrationChainResponse]);
    return (React.createElement(PageLayout, null,
        React.createElement("div", { style: { width: '100%', marginBottom: '1rem' } },
            React.createElement(Box, { component: "div", display: "inline" },
                React.createElement(Button, { variant: "contained", size: "medium", className: classes.colorButtonBack, onClick: () => { history.push('/integration-chains'); } }, "\u041D\u0430\u0437\u0430\u0434 \u043A \u0441\u043F\u0438\u0441\u043A\u0443"))),
        React.createElement(Paper, null,
            React.createElement(FormAdd, { onSubmit: createIntegrationChain }))));
};
