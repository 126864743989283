import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography, makeStyles } from '@material-ui/core';
import { PageLayout } from '@/shared/components/PageLayout';
import { useActionLogger } from '@/shared/hooks/action-logger';
import { useGetAllChains } from '../IntegrationChains/services/hooks/use-get-all-chains';
import { useGetEntitiesWithProperties } from './services/hooks/use-get-entities-with-properties';
const useStyles = makeStyles(() => ({
    title: {
        marginTop: '1rem',
        marginBottom: '1rem',
        marginLeft: '1rem',
    },
    gridForm: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    form: {
        paddingTop: '1rem',
        marginLeft: '8px',
    },
    gridButton: {
    // marginTop: '2rem',
    },
    buttonTurn: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        height: '55px',
    },
    gridBackButton: {
        // marginTop: '6rem',
        display: 'flex',
        gap: '1rem'
    },
    backButton: {
        fontWeight: 700,
        fontSize: '16px',
    },
    nextButton: {
        fontWeight: 700,
        fontSize: '16px',
        color: '#0066CC',
    },
    gridNextButton: {
    // marginTop: '6rem',
    // textAlign: 'center',
    },
    gridContainer: {
        marginTop: '2.38rem',
    },
}));
export const MigratorImportKeysPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const { guid, source, destination } = useParams();
    const [additionalValue] = useState('');
    const [integrationChain, setIntagrationChain] = useState('');
    const { setAction } = useActionLogger();
    const { data: chainsList } = useGetAllChains(additionalValue);
    // TODO: Реализовать после исправления ошибки на беке (приходит ответ только с ошибкой)
    const { /*data: importedEntitiesKeys,*/ refetch } = useGetEntitiesWithProperties({
        entityGuid: guid,
        chainCode: integrationChain,
    });
    const handleGetChain = (event) => {
        setIntagrationChain(event.target.value);
    };
    const handleGetEntitiesWithProperties = () => {
        refetch().then(({ isError, error, isSuccess }) => {
            if (isError) {
                setAction({
                    type: 'error',
                    message: error.message,
                });
            }
            if (isSuccess) {
                setAction({
                    type: 'success',
                    message: 'Import was successful',
                });
            }
        });
    };
    // eslint-disable-next-line no-console
    console.log(guid);
    return (React.createElement(PageLayout, null,
        React.createElement(Typography, { variant: "h4", className: classes.title, style: { marginLeft: 8, marginBottom: '2rem' } }, "\u0418\u043C\u043F\u043E\u0440\u0442 \u043A\u043B\u044E\u0447\u0435\u0439"),
        React.createElement(Grid, { spacing: 1, className: classes.gridForm },
            React.createElement(Grid, { container: true, xs: 6, className: classes.form },
                React.createElement(FormControl, { variant: "outlined", style: { width: '70%' } },
                    React.createElement(InputLabel, { id: "demo-simple-select-outlined-label" }, "\u0418\u043D\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u043E\u043D\u043D\u044B\u0435 \u043F\u0440\u043E\u0446\u0435\u0441\u0441\u044B"),
                    React.createElement(Select, { labelId: "demo-simple-select-outlined-label", id: "demo-simple-select-outlined", value: integrationChain, label: "\u0418\u043D\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u043E\u043D\u043D\u044B\u0435 \u043F\u0440\u043E\u0446\u0435\u0441\u0441\u044B", onChange: handleGetChain }, chainsList?.chainDescriptions.map((chain) => (React.createElement(MenuItem, { key: chain.guid, value: chain.code }, chain.code))))),
                React.createElement(Grid, { item: true, xs: 3, className: classes.gridButton },
                    React.createElement(Button, { style: { marginLeft: 16 }, variant: "contained", size: "medium", className: classes.buttonTurn, onClick: handleGetEntitiesWithProperties }, "\u0417\u0430\u043F\u0443\u0441\u0442\u0438\u0442\u044C"))),
            React.createElement(Grid, { container: true, spacing: 1, className: classes.gridContainer },
                React.createElement(Grid, { item: true, xs: 6, className: classes.gridBackButton },
                    React.createElement(Button, { className: classes.backButton, onClick: () => history.push(`/migrator/${guid}/${source}/${destination}/rebuild`) }, "\u041D\u0430\u0437\u0430\u0434"),
                    React.createElement(Button, { onClick: () => history.push(`/migrator/${guid}/${source}/${destination}/dataMigration`), className: classes.nextButton }, "\u0421\u043B\u0435\u0434\u0443\u044E\u0449\u0438\u0439 \u0448\u0430\u0433"))))));
};
