import React, { useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Box, Button } from '@material-ui/core';
import { useStyles } from "@/pages/Plugins/styles";
import { PageLayout } from '@/shared/components/PageLayout';
import { FormEdit } from "@/pages/Plugins/components/FormEdit";
import { useGetPlugin, useGetPlugins } from "@/shared/services/plugins/hooks/use-get-plugins";
import { useDeletePlugin } from "@/pages/Plugins/services/hooks/use-delete-plugin";
import { useDeleteFile } from "@/pages/Plugins/services/hooks/use-delete-file";
import { useCreatePluginFile } from "@/pages/Plugins/services/hooks/use-create-plugin-file";
import { useGenerateMethods } from "@/pages/Plugins/services/hooks/use-generate-methods";
import { useVersionPlugins } from "@/pages/Plugins/services/hooks/use-versionPlugins";
import { useNewVersionPlugins } from "@/pages/Plugins/services/hooks/use-new-version-plugin";
export const PluginPage = () => {
    const { guid, version } = useParams();
    const history = useHistory();
    const [additionalValue, setAdditionalValue] = useState(`?pluginGuid=${guid}${version ? `&versionGuid=${version}` : ''}`);
    const { data: plugin, refetch } = useGetPlugin(additionalValue);
    //
    const { refetch: refetchPluginsList } = useGetPlugins('');
    const { mutate: deletePlugin } = useDeletePlugin(() => {
        history.push('/plugins');
        refetchPluginsList();
    });
    const { mutate: deleteFile } = useDeleteFile(() => {
        refetch();
    });
    const { mutate: createPluginFile } = useCreatePluginFile(() => refetch());
    const { mutateAsync: generateMethod } = useGenerateMethods(() => refetch());
    const { mutate: ActualVersion } = useVersionPlugins(() => refetch());
    const { mutateAsync: AddNewVersionPlugins } = useNewVersionPlugins(() => refetch());
    const classes = useStyles();
    return (React.createElement(PageLayout, null,
        React.createElement("div", { style: { width: '100%', marginBottom: '1rem' } },
            React.createElement(Box, { component: "div", display: "inline" },
                React.createElement(Button, { variant: "contained", size: "medium", className: classes.colorButtonBack, onClick: () => { history.push('/plugins'); } }, "\u041D\u0430\u0437\u0430\u0434 \u043A \u0441\u043F\u0438\u0441\u043A\u0443"))),
        plugin && plugin?.huHeadPluginDescription ? (React.createElement(FormEdit, { versionGuid: version, initialValue: plugin.huHeadPluginDescription, onChangePluginVersion: (version) => {
                if (version.guid) {
                    setAdditionalValue(`?pluginGuid=${guid}&versionGuid=${version.guid}`);
                    history.push(`/plugin/${guid}/${version.guid}`);
                }
            }, onDeletePlugin: (guid) => deletePlugin(guid), onDeleteFile: deleteFile, onCreatePluginFile: createPluginFile, onGenerateMethods: generateMethod, onActualVersion: ActualVersion, onNewVersionPlugins: AddNewVersionPlugins, disabledSaveButton: false, isLoadingGenerateMethod: false })) : null,
        plugin && !plugin.isSuccess ?
            React.createElement("h2", null, "\u041F\u0440\u043E\u0438\u0437\u043E\u0448\u043B\u0430 \u043E\u0448\u0438\u0431\u043A\u0430") : null));
};
