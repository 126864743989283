// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../images/loader.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loader{position:fixed;width:100%;height:100%;top:0;left:0;right:0;bottom:0;background:rgba(255,255,255,.5);z-index:3;display:none}.loader.show{display:block !important}.loader__image{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);width:140px;height:140px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center no-repeat;background-size:contain}", "",{"version":3,"sources":["webpack://src/shared/components/Loader/Loader.scss"],"names":[],"mappings":"AAAA,QACI,cAAA,CACA,UAAA,CACA,WAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CAEA,SAAA,CAEA,YAAA,CAEA,aACI,wBAAA,CAGJ,eACI,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,WAAA,CACA,YAAA,CACA,mEAAA,CACA,uBAAA","sourcesContent":[".loader {\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: rgba(255, 255, 255, 0.5);\n\n    z-index: 3;\n    //z-index: 10001;\n    display: none;\n\n    &.show {\n        display: block !important;\n    }\n\n    &__image {\n        position: absolute;\n        top: 50%;\n        left: 50%;\n        transform: translate(-50%, -50%);\n        width: 140px;\n        height: 140px;\n        background: url(\"@/shared/images/loader.svg\") center no-repeat;\n        background-size: contain;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
