import React, { useState } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import { PageLayout } from '@/shared/components/PageLayout';
import { Menu } from '@/shared/components/Menu';
import { useStyles } from "@/pages/Systems/styles";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, } from '@material-ui/core';
import { useGetAllSystems } from './services/hooks/use-get-all-systems';
export const Systems = () => {
    const classes = useStyles();
    const columns = [
        { value: 'id', label: 'ИД', minWidth: 40 },
        { value: 'guid', label: 'Уникальный идентификатор', minWidth: 280 },
        { value: 'code', label: 'Код', minWidth: 200 },
        { value: 'description', label: 'Описание' },
    ];
    const history = useHistory();
    const [additionalValue, setAdditionalValue] = useState('');
    const { data: systemsList, refetch } = useGetAllSystems(additionalValue);
    const rowsPerPageSetting = [10, 25, 50, 100];
    const handleChangePage = (_, newPage) => {
        setAdditionalValue(`?pageSize=${systemsList?.pageSize}&pageNumber=${newPage + 1}`);
        refetch();
    };
    const handleChangeRowsPerPage = (event) => {
        setAdditionalValue(`?pageSize=${Number(event.target.value)}`);
        refetch();
    };
    return (React.createElement(PageLayout, null,
        React.createElement("div", null,
            React.createElement(Box, { component: "div", display: "inline" },
                React.createElement(Menu, { addButtonProps: {
                        disabled: false,
                        handleClick: () => history.push('/system/add'),
                        label: 'Добавить систему',
                    } })),
            React.createElement(TableContainer, { component: Paper, className: classes.tableHideOverflow },
                React.createElement(Typography, { variant: "h4", className: classes.marginBott }, "\u0421\u0438\u0441\u0442\u0435\u043C\u044B"),
                React.createElement(Table, { className: classes.table },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null, columns.map((column) => (React.createElement(TableCell, { key: column.value, align: column.align, className: classes.tableRowHead }, column.label))))),
                    React.createElement(TableBody, null, systemsList && systemsList?.huHeadSystemInfos ? systemsList?.huHeadSystemInfos.map((plugin) => (React.createElement(TableRow, { key: plugin.guid }, columns.map((column) => {
                        const value = plugin[column.value];
                        column.value;
                        return (React.createElement(TableCell, { component: "section", scope: "row", key: column.value, className: classes.tableRow, style: { width: column.minWidth } }, column.value === 'guid' ?
                            (React.createElement(NavLink, { to: `/system/${plugin.guid}` }, (column.component && column.component(value)) || value))
                            :
                                (column.component && column.component(value)) || value));
                    })))) : null)),
                systemsList && systemsList.huHeadSystemInfos ? systemsList?.huHeadSystemInfos && (React.createElement(TablePagination, { labelRowsPerPage: 'Cтрок на странице', rowsPerPageOptions: rowsPerPageSetting, component: "div", count: systemsList?.totalCount, rowsPerPage: systemsList?.pageSize, page: systemsList?.currentPage - 1, onChangePage: handleChangePage, onChangeRowsPerPage: handleChangeRowsPerPage })) : null))));
};
