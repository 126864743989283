import { client } from '@/shared/api';
const PATH_AUTH_API = 'Admin';
export const getAllChains = async (additionlInfo) => {
    const { data } = await client.get(`${PATH_AUTH_API}/GetIntegrationChainDescriptionList${additionlInfo}`);
    if (!data.isSuccess) {
        throw new Error(`GetIntegrationChainDescriptionList. ${data.errorMessage}`);
    }
    return data;
};
export const getChain = async (additionlInfo) => {
    const { data } = await client.get(`${PATH_AUTH_API}/GetIntegrationChainByGuid${additionlInfo}`);
    // if (!data.isSuccess) {
    //     throw new Error(`GetIntegrationChainByGuid. ${data.errorMessage}`);
    // }
    return data;
};
export const getStagesDescription = async (guid) => {
    const { data } = await client.get(`${PATH_AUTH_API}/GetStagesDescription/${guid}`);
    if (!data.isSuccess) {
        throw new Error(`GetStagesDescription. ${guid} ${data.errorMessage}`);
    }
    return data;
};
export const deleteStage = async (guid) => {
    const { data } = await client.delete(`${PATH_AUTH_API}/DeleteStage/${guid}`);
    if (!data.isSuccess) {
        throw new Error(`DeleteStage. ${data.errorMessage}`);
    }
    return data;
};
export const getNextWorkflowStagesTypes = async (guid) => {
    const { data } = await client.get(`${PATH_AUTH_API}/GetNextWorkflowStageType/${guid}`);
    if (!data.isSuccess) {
        throw new Error(`GetNextWorkflowStageType. ExceptionString: ${data.errorMessage}`);
    }
    return data;
};
export const getNextSystemAvailable = async ({ guid, stagePluginType, }) => {
    // try {
    const { data } = await client.get(`${PATH_AUTH_API}/GetSystemsAvailable/${guid}/${stagePluginType}`);
    // if (!data.isSuccess) {
    //     throw new Error(`GetSystemsAvailable. ExceptionString: ${data.errorMessage}`);
    // }
    return data;
    // } catch (error) {
    //     throw new Error('error');
    //     return error
    // }
};
export const createIntegrationChain = async (config) => {
    const { data } = await client.post(`${PATH_AUTH_API}/CreateNewIntegrationChain`, config);
    if (!data.isSuccess) {
        throw new Error(`CreateNewIntegrationChain error. ExceptionString: ${data.errorMessage}`);
    }
    return data;
};
export const deleteChain = async (guid) => {
    const { data } = await client.delete(`${PATH_AUTH_API}/DeleteIntegrationChain/${guid}`);
    if (!data.isSuccess) {
        throw new Error(`DeleteIntegrationChain error. ExceptionString: ${data.errorMessage}`);
    }
    return data;
};
export const createWorkflowStage = async (config) => {
    const { data } = await client.post(`${PATH_AUTH_API}/CreateWorkflowStage`, config);
    if (!data.isSuccess) {
        throw new Error(`CreateWorkflowStage. ${data.errorMessage} `);
    }
    return data;
};
export const getInputLinksByStage = async (stageGuid) => {
    const { data } = await client.get(`${PATH_AUTH_API}/GetInputLinksByStage/${stageGuid}`);
    if (!data.isSuccess) {
        throw new Error(`Input links. ${data.errorMessage}`);
    }
    return data;
};
export const deleteInputLink = async (guid) => {
    const { data } = await client.delete(`${PATH_AUTH_API}/DeleteLink/${guid}`);
    if (!data.isSuccess) {
        throw new Error(`DeleteLink. ${data.errorMessage}`);
    }
    return data;
};
// export type GetParentStagesResponse =
export const getParentStages = async ({ chainGuid, currentStageGuid, }) => {
    // TODO Add types
    const { data } = await client.get(`${PATH_AUTH_API}/GetParentStages/${chainGuid}/${currentStageGuid}`);
    return data;
};
export const createWorkflowStagesLink = async (config) => {
    const { data } = await client.post(`${PATH_AUTH_API}/CreateWorkflowStagesLink`, config);
    if (!data.isSuccess) {
        throw new Error(`CreateWorkflowStage. ${data.errorMessage} `);
    }
    return data;
};
export const getTransformationPluginsAvailable = async (guid) => {
    const { data } = await client.get(`${PATH_AUTH_API}/GetTransformationPluginsAvailable/${guid}`);
    return data;
};
