import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { PageLayout } from '@/shared/components/PageLayout';
import Paper from '@material-ui/core/Paper';
import { FormAdd } from "@/pages/PluginInstances/components/FormAdd";
import { useRegisterInstance } from "@/pages/PluginInstances/services/hooks/use-register-instance";
import { useStyles } from "@/pages/PluginInstances/styles";
import { Box, Button } from '@material-ui/core';
import { useGetPluginInstances } from "@/shared/services/instances/hooks/use-get-plugin-instances";
export const PluginInstancePageAdd = () => {
    const classes = useStyles();
    const history = useHistory();
    const [validationBackend, setValidationBackend] = useState(null);
    const { mutate: registerInstance, isLoading: registerInstanceIsLoading, data: registerInstanceResponse, } = useRegisterInstance((errors) => {
        setValidationBackend(errors);
    });
    const [additionalValue] = useState('');
    const { refetch } = useGetPluginInstances(additionalValue);
    useEffect(() => {
        if (registerInstanceResponse?.isSuccess) {
            refetch();
            history.push(`/plugin-instance/${registerInstanceResponse.guid}`);
        }
    }, [registerInstanceResponse]);
    //
    return (React.createElement(PageLayout, null,
        React.createElement("div", { className: classes.tableHideOverflow },
            React.createElement(Box, { component: "div", display: "inline" },
                React.createElement(Button, { variant: "contained", size: "medium", className: classes.colorButtonBack, onClick: () => { history.push('/plugin-instances'); } }, "\u041D\u0430\u0437\u0430\u0434 \u043A \u0441\u043F\u0438\u0441\u043A\u0443"))),
        React.createElement(Paper, null,
            React.createElement(FormAdd, { onSubmit: registerInstance, pluginInstanceError: validationBackend, isSubmitting: registerInstanceIsLoading }))));
};
