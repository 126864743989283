import { client } from '@/shared/api';
const PATH_AUTH_API = 'Admin';
export const createNewPlugin = async (pluginData) => {
    const { data } = await client.post(`${PATH_AUTH_API}/CreateNewPlugin`, pluginData);
    if (!data.isSuccess) {
        throw new Error(`CreateNewPlugin. ${data.errorMessage}`);
    }
    return data;
};
export const deletePlugin = async (guid) => {
    const { data } = await client.delete(`${PATH_AUTH_API}/DeletePlugin/${guid}`);
    if (!data.isSuccess) {
        throw new Error(`DeletePlugin. ${data.errorMessage}`);
    }
    return data;
};
export const deletePluginFile = async (guid) => {
    const { data } = await client.delete(`${PATH_AUTH_API}/DeletePluginFile`, {
        data: { guid },
    });
    if (!data.isSuccess) {
        throw new Error(`DeletePluginFile. ${data.errorMessage}.`);
    }
    return data;
};
export const generateMethods = async ({ integrationTimeoutInMinutes, pluginVersion }) => {
    const { data } = await client.post(`${PATH_AUTH_API}/CreatePluginMethodsByLoadedPluginFiles`, {
        pluginVersionGuid: pluginVersion,
        integrationTimeoutInMinutes,
    });
    if (!data.isSuccess) {
        throw new Error(`CreatePluginMethodsByLoadedPluginFiles.${data.errorMessage}`);
    }
    return data;
};
export const versionPlugins = async (pluginData) => {
    const { data } = await client.post(`${PATH_AUTH_API}/SetActualPluginVersionOnInstances?versionGuid=${pluginData}`);
    if (!data.isSuccess) {
        throw new Error(`SetActualPluginVersionOnInstances. ${data.errorMessage}`);
    }
    return data;
};
export const AddNewVersionPlugins = async (pluginData) => {
    const { data } = await client.post(`${PATH_AUTH_API}/CreatePluginVersion`, pluginData);
    if (!data.isSuccess) {
        throw new Error(`CreatePluginVersion. ${data.errorMessage}`);
    }
    return data;
};
export const createPluginFile = async ({ name, extension, pluginVersion, body }) => {
    const { data } = await client.post(`${PATH_AUTH_API}/CreatePluginFile`, {
        name,
        extension,
        versionGuid: pluginVersion,
        body,
    });
    if (!data.isSuccess) {
        throw new Error(`CreatePluginFile. ${data.errorMessage}`);
    }
    return data;
};
