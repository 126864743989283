import React from 'react';
import { TextField, Box, makeStyles } from '@material-ui/core';
import { ConfirmDelete } from '@/shared/components/ConfirmDelete';
const useStyles = makeStyles((theme) => ({
    input: {
        marginRight: theme.spacing(1),
        flex: 1,
        flexShrink: 0,
    },
}));
export const LinkRow = ({ guid, code, description, result, onDeleteRow }) => {
    const styles = useStyles();
    return (React.createElement(Box, { mb: 1, display: "flex", alignItems: "center" },
        React.createElement(TextField, { className: styles.input, value: code, InputProps: { readOnly: true }, InputLabelProps: { shrink: true } }),
        React.createElement(TextField, { className: styles.input, value: description, InputProps: { readOnly: true }, InputLabelProps: { shrink: true } }),
        React.createElement(TextField, { className: styles.input, value: result, InputProps: { readOnly: true }, InputLabelProps: { shrink: true } }),
        React.createElement(Box, { minWidth: "110px", display: "flex", alignItems: "center" },
            React.createElement(ConfirmDelete, { view: "icon", onDelete: () => onDeleteRow(guid) }))));
};
