import React from 'react';
import { styled } from '@material-ui/core';
import { Link } from 'react-router-dom';
const LinkComponent = styled(Link)(() => ({
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    paddingLeft: '8px',
    paddingRight: '8px',
    color: 'inherit',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    textDecoration: 'none',
}));
export const ActiveLink = ({ href, name, isActive }) => (React.createElement("div", { style: { color: isActive ? 'rgb(58, 219, 255)' : '#eceff4' } },
    React.createElement(LinkComponent, { to: href }, name)));
