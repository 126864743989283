import dagre from 'dagre';
import { isNode, Position } from 'react-flow-renderer';
import { NODE_WIDTH, NODE_HEIGHT } from './CustomNode/CustomNode';
/**
 * Returns the point position in percent
 */
export const getPointPosition = (count, index) => {
    if (count === 1)
        return 50;
    let result = 0;
    const step = 100 / (count - 1);
    for (let i = 0; i <= count; i += 1) {
        if (i === index)
            return result;
        result += step;
    }
    return result;
};
const customElementProps = {
    position: { x: 0, y: 0 },
    type: 'custom',
};
export const createNodeElements = (stagesDescription) => {
    if (!stagesDescription)
        return;
    const { stageInfos } = stagesDescription;
    return stageInfos.map(({ code, pluginTypeString, guid }) => ({
        id: guid,
        data: {
            title: pluginTypeString,
            subtitle: code,
        },
        ...customElementProps,
    }));
};
export const getLayoutedElements = (dagreGraph, elements) => {
    dagreGraph.setGraph({ rankdir: 'TB' });
    elements.forEach((el) => {
        if (isNode(el)) {
            dagreGraph.setNode(el.id, { width: NODE_WIDTH * 2, height: NODE_HEIGHT + NODE_HEIGHT / 2 });
        }
        else {
            dagreGraph.setEdge(el.source, el.target);
        }
    });
    dagre.layout(dagreGraph);
    return elements.map((el) => {
        if (isNode(el)) {
            const nodeWithPosition = dagreGraph.node(el.id);
            el.targetPosition = Position.Top;
            el.sourcePosition = Position.Bottom;
            // unfortunately we need this little hack to pass a slightly different position
            // to notify react flow about the change. Moreover we are shifting the dagre node position
            // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
            el.position = {
                x: nodeWithPosition.x + Math.random(),
                y: nodeWithPosition.y,
            };
        }
        return el;
    });
};
