import { Box, Typography } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import React, { useState } from 'react';
import { MessagesStatusTable } from './components/MessagesStatusTable';
import { dataSource } from './mock';
export const MessagesStatus = () => {
    const [tableData, setTableData] = useState(dataSource);
    const [filter, setFilter] = useState('All');
    const handleChangeFilter = (event, newAlignment) => {
        setFilter(newAlignment);
        setTableData(dataSource.filter(({ status }) => {
            if (newAlignment === 'All') {
                return true;
            }
            else {
                return status === newAlignment;
            }
        }));
    };
    return (React.createElement(Box, { p: 2, height: "75vh" },
        React.createElement(Box, { mb: 1 },
            React.createElement(Typography, { variant: "h4" }, "\u0421\u0442\u0430\u0442\u0443\u0441 \u043E\u0431\u0440\u0430\u0431\u043E\u0442\u043A\u0438 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0439"),
            React.createElement(Box, { mb: 2, mt: 2 },
                React.createElement(ToggleButtonGroup, { size: "small", value: filter, exclusive: true, onChange: handleChangeFilter },
                    React.createElement(ToggleButton, { value: "All" }, "\u0412\u0441\u0435"),
                    React.createElement(ToggleButton, { value: "InProgress" }, "\u0410\u043A\u0442\u0438\u0432\u043D\u044B\u0435"),
                    React.createElement(ToggleButton, { value: "Completed" }, "\u0417\u0430\u0432\u0435\u0440\u0448\u0435\u043D\u043D\u044B\u0435"),
                    React.createElement(ToggleButton, { value: "Error" }, "\u041E\u0448\u0438\u0431\u043A\u0438"))),
            React.createElement(MessagesStatusTable, { rows: tableData }))));
};
