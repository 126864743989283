import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles(() => ({
    table: {
        minWidth: 650,
    },
    tableHideOverflow: {
        overflowX: 'hidden',
    },
    tableRow: {
        cursor: 'pointer',
        padding: '5px 16px 5px 16px',
        fontSize: '0.8rem',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '0px',
        tableLayout: 'auto',
        lineHeight: '1.5rem',
    },
    tableRowHead: {
        cursor: 'pointer',
        padding: '5px 16px 5px 16px',
        fontSize: '0.8rem',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '0px',
        tableLayout: 'auto',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        fontWeight: 'bolder',
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
    colorButton: {
        backgroundColor: '#0066CC',
        color: 'white',
    },
    colorButtonBack: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        marginTop: '1rem',
    },
    marginBott: {
        marginTop: '1rem',
        marginBottom: '1rem',
        marginLeft: '1rem',
    },
}));
