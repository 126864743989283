import React, { useEffect, useState } from 'react';
import { useGetInputLinksByStage } from '@/pages/IntegrationChains/services/hooks/use-get-input-links-by-stage';
import { useGetStagesDescription } from '@/pages/IntegrationChains/services/hooks/use-get-stages-description';
import { makeStyles, Typography } from '@material-ui/core';
import ReactFlow, { MiniMap, Controls, ArrowHeadType } from 'react-flow-renderer';
import dagre from 'dagre';
import { CustomNode } from './CustomNode/CustomNode';
import { createNodeElements, getLayoutedElements } from './utils';
const useStyles = makeStyles(() => ({
    scheme: {
        height: 'calc(70vh - 110px)',
    },
    textAlign: {
        textAlign: 'center',
        marginTop: '2px',
    },
}));
const nodeTypes = {
    custom: CustomNode,
};
const linkProps = {
    type: 'smoothstep',
    arrowHeadType: ArrowHeadType.ArrowClosed,
    labelBgPadding: [8, 4],
    labelBgBorderRadius: 4,
    labelBgStyle: { fill: '#FFCC00', color: '#FFF', fillOpacity: 0.7 },
};
export const ChainScheme = ({ guid, name }) => {
    const styles = useStyles();
    const { data: stagesDescription } = useGetStagesDescription(guid);
    const getInputLinks = useGetInputLinksByStage();
    const [elements, setElements] = useState();
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    const getAllInputLinks = (stages) => Promise.all(stages.map(async ({ id }) => {
        const response = await getInputLinks.mutateAsync(id);
        return { parentId: id, ...response };
    }));
    useEffect(() => {
        const elements = createNodeElements(stagesDescription);
        async function createScheme() {
            if (!elements)
                return;
            const allInputLinks = await getAllInputLinks(elements);
            const nodeLinks = [];
            for (const inputLink of allInputLinks) {
                if (!inputLink.isSuccess)
                    return;
                const { stagesLinkInfos } = inputLink;
                stagesLinkInfos.forEach(({ guid, previousStageDescription, linkExecutionResultDescription }, index) => {
                    nodeLinks.push({
                        id: guid,
                        source: previousStageDescription.guid,
                        target: inputLink.parentId,
                        sourceHandle: `${previousStageDescription.guid}-${index}`,
                        targetHandle: `${inputLink.parentId}-${index}`,
                        label: linkExecutionResultDescription.code,
                        ...linkProps,
                    });
                });
            }
            const nodesWithPoints = elements.map((item) => {
                const source = nodeLinks.filter((link) => link?.source === item.id).map((link) => link?.sourceHandle);
                const target = nodeLinks.filter((link) => link?.target === item.id).map((link) => link?.targetHandle);
                return {
                    ...item,
                    data: {
                        ...item.data,
                        source,
                        target,
                    },
                };
            });
            setElements(getLayoutedElements(dagreGraph, [...nodesWithPoints, ...nodeLinks]));
        }
        createScheme();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return elements ? (React.createElement(ReactFlow, { className: styles.scheme, nodeTypes: nodeTypes, elements: elements },
        React.createElement(MiniMap, null),
        React.createElement(Typography, { className: styles.textAlign, variant: 'h6' }, name),
        React.createElement(Controls, null))) : null;
};
