import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import { routes } from '@/pages/routes';
import { CssBaseline, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { PageHeader } from '../PageHeader';
import { Navigation } from '../LeftSideBar/components/Navigation/components/Navigation';
const drawerWidth = 250;
const useStyles = makeStyles((theme) => createStyles({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: '#000',
    },
    appBarShift: {
        // width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#0066CC',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    hide: {
        display: 'none',
    },
    mrapper: {
        display: 'Table',
    },
    cell: {
        display: 'TableCell',
        verticalAlign: 'middle',
    },
}));
const navigationItems = [
    {
        href: routes.management,
        name: 'Главная',
    },
    {
        href: routes.systems,
        name: 'Системы',
    },
    {
        href: routes.pluginInstances,
        name: 'Агенты',
    },
    {
        href: routes.plugins,
        name: 'Плагины',
    },
    {
        href: routes.integrationChains,
        name: 'Интеграционные процессы',
    },
    // {
    //     href: routes.users,
    //     name: 'Пользователи',
    // },
    {
        href: routes.monitoring,
        name: 'Мониторинг',
    },
    {
        href: routes.migrators,
        name: 'Мигратор',
    }
];
export const PageLayout = ({ children }) => {
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpenAndClose = () => {
        setOpen(!open);
    };
    const classes = useStyles();
    return (React.createElement("div", { className: classes.root },
        React.createElement(CssBaseline, null),
        React.createElement(AppBar, { position: "fixed", className: clsx(classes.appBar, {
                [classes.appBarShift]: open,
            }) },
            React.createElement(Toolbar, null,
                React.createElement(IconButton, { color: "inherit", "aria-label": "open drawer", onClick: handleDrawerOpenAndClose, edge: "start", className: clsx(classes.menuButton, open) },
                    React.createElement(MenuIcon, null)),
                React.createElement(PageHeader, null))),
        React.createElement(Drawer, { className: classes.drawer, variant: "persistent", anchor: "left", open: open, classes: {
                paper: classes.drawerPaper,
            } },
            React.createElement(Toolbar, null),
            React.createElement("div", { className: classes.drawerContainer },
                React.createElement("div", { className: classes.mrapper },
                    React.createElement("div", { className: classes.cell },
                        React.createElement(Navigation, { items: navigationItems }))),
                React.createElement(Divider, null))),
        React.createElement("main", { className: clsx(classes.content, {
                [classes.contentShift]: open,
            }) }, children)));
};
