import React, { useEffect, useState } from 'react';
import { Box, Button, CssBaseline, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, } from '@material-ui/core';
import { UploadFiles } from '@/shared/components/UploadFiles';
import { Autocomplete } from '@material-ui/lab';
import { useForm } from 'react-hook-form';
import { ConfirmDelete } from '@/shared/components/ConfirmDelete';
const useStyles = makeStyles((theme) => ({
    form: {
        width: '400px',
        marginTop: '35px',
    },
    drop: {
        width: '15%',
        height: '15%',
    },
    colorButton: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        marginBottom: '1rem',
        marginTop: '1rem',
        textAlign: 'center',
    },
    fontSmall: {
        fontSize: '10px'
    },
    width: {
        width: '400px',
    },
    size: {
        width: '100px',
    },
    paper: {
        padding: theme.spacing(2),
        paddingRight: theme.spacing(3),
        color: theme.palette.text.secondary,
        marginTop: '2rem',
    },
    margin: {
        color: 'Black',
        padding: '1px 1px',
    },
    root: {
        flexGrow: 1,
        marginBottom: '2rem',
    },
    marginBott: {
        marginTop: '1rem',
        marginBottom: '1rem',
        marginLeft: '1rem',
        color: 'Black',
        textAlign: 'center',
    },
    marginTopColorGrid: {
        color: 'grey',
        marginTop: '2rem',
        fontSize: '15px',
    },
    textAliginEndMarginBottom: {
        textAlign: 'end',
        marginBottom: '2rem',
    },
    color: {
        color: 'grey',
    },
    textAliginEnd: {
        textAlign: 'end',
    },
    textAliginEndWithColor: {
        textAlign: 'end',
        color: 'Black',
        marginTop: '8px',
    },
    styleForMethodFiles: {
        marginTop: '1rem',
        marginBottom: '1rem',
        marginLeft: '1rem',
        color: 'Black',
    },
    table: {
        minWidth: 650,
    },
    tableHideOverflow: {
        overflowX: 'hidden',
    },
    tableRow: {
        cursor: 'pointer',
        padding: '5px 16px 5px 16px',
        fontSize: '0.8rem',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '0px',
        tableLayout: 'auto',
        lineHeight: '1.5rem',
    },
    tableRowHead: {
        cursor: 'pointer',
        padding: '5px 16px 5px 16px',
        fontSize: '0.8rem',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '0px',
        tableLayout: 'auto',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        fontWeight: 'bolder',
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
    colorButtonBack: {
        backgroundColor: '#0066CC',
        color: 'white',
        padding: '6px 1px',
        borderRadius: '0px',
        marginTop: '1rem',
    },
    widthFields: {
        width: '205px',
    },
}));
const getBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    // @ts-ignore
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});
const getFileNameAndExtension = (filename) => {
    const lastDot = filename.lastIndexOf('.');
    const fileName = filename.substring(0, lastDot);
    const ext = filename.substring(lastDot + 1);
    return {
        fileName,
        ext,
    };
};
const columns = [
    { value: 'id', label: 'ИД', minWidth: 40 },
    { value: 'name', label: 'Название', minWidth: 180 },
    { value: 'extension', label: 'Расширение' },
];
const columnsMethot = [
    { value: 'id', label: 'ИД', minWidth: 40 },
    { value: 'name', label: 'Название метода', minWidth: 180 },
    { value: 'description', label: 'Описание', minWidth: 200 },
    { value: 'assemblyName', label: 'Название сборки' },
];
export const FormEdit = ({ 
// onDeleteFile,
versionGuid, onChangePluginVersion, onDeletePlugin, onGenerateMethods, onCreatePluginFile, onActualVersion, onNewVersionPlugins, disabledSaveButton = false, isLoadingGenerateMethod, initialValue, }) => {
    const styles = useStyles();
    const [isDisableButtons, setDisableButtons] = useState(false);
    const { name, guid, pluginTypeDescription, huHeadPluginVersionDescriptions, selectedVersion } = initialValue;
    const { register, errors } = useForm();
    const [huHeadPluginVersionDescription, setPluginVersionDescription] = useState();
    const [versionNumber, setVersionNumber] = useState();
    const [integrationTimeoutInMinutes, setIntegrationTimeoutInMinutes] = useState(0);
    // const actualPlugin = huHeadPluginVersionDescriptions.filter(
    //     ({ isActual }) => isActual === true,
    // )[0];
    //
    const actualPlugin = selectedVersion;
    useEffect(() => {
        register();
        register('versionNumber', {
            required: true,
        });
        // setPluginVersionDescription(huHeadPluginVersionDescriptions.filter(({ isActual }) => isActual === true)[0]);
    }, [register]);
    const handleSendFile = async (file) => {
        const { fileName, ext } = getFileNameAndExtension(file.name);
        const fileBody = await getBase64(file);
        if (typeof fileBody !== 'string') {
            throw new Error('Failed to retrieve file body');
        }
        await onCreatePluginFile({
            name: fileName,
            extension: ext,
            pluginVersion: selectedVersion.guid,
            body: fileBody.split(',')[1],
        });
        // setPluginVersionDescription(huHeadPluginVersionDescriptions.filter(({ isActual }) => isActual === true)[0]);
    };
    const onCreateNewMethod = async () => {
        setDisableButtons(true);
        await onGenerateMethods({
            integrationTimeoutInMinutes: integrationTimeoutInMinutes || 0,
            pluginVersion: defaultPluginVersion().guid,
        });
        setDisableButtons(false);
    };
    // const changeVersionPlagin = (data: HuHeadPluginVersionDescriptions | null) => {
    // const changeVersionPlagin = (version: string | null) => {
    //     onChangePluginVersion(version)
    //     // if (data === null) {
    //     //     setPluginVersionDescription(undefined);
    //     // } else {
    //     //     setPluginVersionDescription(data);
    //     // }
    // };
    const onClickNewVersionPlugins = () => {
        const request = {
            pluginGuid: guid,
            versionNumber: versionNumber,
        };
        onNewVersionPlugins(request);
        setVersionNumber('');
    };
    const rowsPerPageSetting = [25, 50, 100];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageSetting[0]);
    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(Number(event.target.value));
        setPage(0);
    };
    const defaultPluginVersion = () => {
        if (versionGuid) {
            return huHeadPluginVersionDescriptions.filter(({ guid }) => String(guid) === String(versionGuid))[0];
        }
        else {
            return huHeadPluginVersionDescriptions.filter(({ isActual }) => isActual === true)[0];
        }
    };
    const isActualPlugin = Boolean(actualPlugin.isActual);
    // @ts-ignore
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { className: styles.root },
            React.createElement(Paper, null,
                React.createElement(CssBaseline, null),
                React.createElement(Grid, { container: true, justify: "space-between" },
                    React.createElement(Grid, { item: true, xs: 12, sm: 5, spacing: 3 },
                        React.createElement(Typography, { variant: "h5", className: styles.marginBott }, "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u043F\u043B\u0430\u0433\u0438\u043D\u0435"),
                        React.createElement(Grid, { container: true, spacing: 1, className: styles.marginTopColorGrid },
                            React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u0422\u0438\u043F:"),
                            React.createElement(Grid, { item: true, xs: 6, className: styles.color }, pluginTypeDescription),
                            React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u0423\u043D\u0438\u043A\u0430\u043B\u044C\u043D\u044B\u0439 \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440:"),
                            React.createElement(Grid, { item: true, xs: 6, className: styles.color }, guid),
                            React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435:"),
                            React.createElement(Grid, { item: true, xs: 6, className: styles.color }, name),
                            React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEndWithColor }, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0432\u0435\u0440\u0441\u0438\u044E:"),
                            React.createElement(Grid, { item: true, xs: 6, className: styles.margin },
                                React.createElement(Autocomplete, { className: styles.widthFields, id: "size-small-standard-multi", fullWidth: true, options: huHeadPluginVersionDescriptions, getOptionLabel: (option) => option.versionNumber.toString(), defaultValue: defaultPluginVersion(), onChange: (_event, value) => {
                                        onChangePluginVersion(value);
                                    }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { placeholder: "\u0412\u0435\u0440\u0441\u0438\u0438", fullWidth: true, id: "outlined-basic", variant: "outlined", size: "small" }))) }),
                                isActualPlugin ? (React.createElement(Typography, { color: "primary" }, "\u0410\u043A\u0442\u0443\u0430\u043B\u044C\u043D\u0430\u044F \u0432\u0435\u0440\u0441\u0438\u044F \u043F\u043B\u0430\u0433\u0438\u043D\u0430")) : (React.createElement(Typography, { color: "error" }, "\u041D\u0435\u0430\u043A\u0442\u0443\u0430\u043B\u044C\u043D\u0430\u044F \u0432\u0435\u0440\u0441\u0438\u044F \u043F\u043B\u0430\u0433\u0438\u043D\u0430"))),
                            React.createElement(Box, { ml: 2 },
                                React.createElement(Button, { variant: "contained", className: `${styles.colorButton} ${styles.fontSmall}`, disabled: isActualPlugin, onClick: () => onActualVersion(defaultPluginVersion().guid) }, "\u0423\u0441\u0442\u0430\u043D\u043E\u0432\u0438\u0442\u044C \u0442\u0435\u043A\u0443\u0449\u0443\u044E \u0432\u0435\u0440\u0441\u0438\u044E")),
                            React.createElement(Box, { ml: 2 },
                                React.createElement(ConfirmDelete, { onDelete: () => onDeletePlugin(guid) })))),
                    React.createElement(Grid, { item: true, xs: 12, sm: 5 },
                        React.createElement(Typography, { variant: "h5", className: styles.marginBott }, "\u0414\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u0432\u0435\u0440\u0441\u0438\u0438 \u043F\u043B\u0430\u0433\u0438\u043D\u0430"),
                        React.createElement(Grid, { container: true, spacing: 1, className: styles.marginTopColorGrid },
                            React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEndWithColor }, "\u041D\u043E\u043C\u0435\u0440 \u0432\u0435\u0440\u0441\u0438\u0438 *"),
                            React.createElement(Grid, { item: true, xs: 6 },
                                React.createElement(TextField, { className: styles.widthFields, fullWidth: true, placeholder: "\u041D\u0430\u043F\u0440\u0438\u043C\u0435\u0440: 1.0.0", id: "outlined-basic", variant: "outlined", size: "small", type: "number", onChange: ({ target }) => setVersionNumber(target.value), value: versionNumber, error: Boolean(errors?.versionNumber) }))),
                        React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEndWithColor },
                            React.createElement(Box, { mt: 4 },
                                React.createElement(Button, { variant: "contained", className: styles.colorButton, disabled: disabledSaveButton, onClick: () => onClickNewVersionPlugins() }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C"))))))),
        React.createElement(Grid, { item: true, xs: 12, spacing: 3 },
            React.createElement(CssBaseline, null),
            React.createElement(TableContainer, { component: Paper, className: styles.tableHideOverflow },
                React.createElement(Typography, { variant: "h5" }, "\u0424\u0430\u0439\u043B\u044B"),
                React.createElement(Table, { className: styles.table },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null, columns.map((column) => (React.createElement(TableCell, { key: column.value, align: column.align, className: styles.tableRowHead, style: { minWidth: column.minWidth } }, column.label))))),
                    React.createElement(TableBody, null, actualPlugin?.huHeadPluginFileDescriptions
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((plugin) => (React.createElement(TableRow, { key: plugin.guid }, columns.map((column) => {
                        const value = plugin[column.value];
                        column.value;
                        return (React.createElement(TableCell, { component: "section", scope: "row", key: column.value, className: styles.tableRow, style: { width: column.minWidth } }, (column.component && column.component(value)) || value));
                    })))))),
                huHeadPluginVersionDescriptions.length > rowsPerPageSetting[0] && (React.createElement(TablePagination, { labelRowsPerPage: 'Cтрок на странице', rowsPerPageOptions: rowsPerPageSetting, component: "div", count: huHeadPluginVersionDescriptions.length, rowsPerPage: rowsPerPage, page: page, onChangePage: handleChangePage, onChangeRowsPerPage: handleChangeRowsPerPage }))),
            React.createElement(UploadFiles, { onSendFiles: handleSendFile, isDisabled: isDisableButtons })),
        React.createElement(Grid, { item: true, xs: 12, spacing: 3 },
            React.createElement(Box, { mt: 3 },
                React.createElement(CssBaseline, null),
                React.createElement(TableContainer, { component: Paper, className: styles.tableHideOverflow },
                    React.createElement(Typography, { variant: "h5" }, "\u041C\u0435\u0442\u043E\u0434\u044B"),
                    React.createElement(Table, { className: styles.table },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null, columnsMethot.map((column) => (React.createElement(TableCell, { key: column.value, align: column.align, className: styles.tableRowHead, style: { minWidth: column.minWidth } }, column.label))))),
                        React.createElement(TableBody, null, actualPlugin?.huHeadPluginMethodDescriptions
                            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((plugin) => (React.createElement(TableRow, { key: plugin.guid }, columnsMethot.map((column) => {
                            const value = plugin[column.value];
                            column.value;
                            return (React.createElement(TableCell, { component: "section", scope: "row", key: column.value, className: styles.tableRow, style: { width: column.minWidth } }, (column.component && column.component(value)) || value));
                        })))))),
                    100 > rowsPerPageSetting[0] && (React.createElement(TablePagination, { labelRowsPerPage: 'Cтрок на странице', rowsPerPageOptions: rowsPerPageSetting, component: "div", count: 100, rowsPerPage: rowsPerPage, page: page, onChangePage: handleChangePage, onChangeRowsPerPage: handleChangeRowsPerPage }))),
                React.createElement(Box, { display: "flex", alignItems: "center" },
                    React.createElement(Button, { variant: "contained", className: styles.colorButton, disabled: integrationTimeoutInMinutes <= 0 || isLoadingGenerateMethod || isDisableButtons, onClick: () => onCreateNewMethod() }, "\u0421\u0433\u0435\u043D\u0435\u0440\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u043C\u0435\u0442\u043E\u0434\u044B"),
                    React.createElement(Box, { ml: 2, display: "flex", alignItems: "center" },
                        "\u0418\u043D\u0442\u0435\u0440\u0432\u0430\u043B",
                        React.createElement(Box, { width: 40, mx: 1 },
                            React.createElement(TextField, { fullWidth: true, value: integrationTimeoutInMinutes, type: "number", onChange: (evt) => setIntegrationTimeoutInMinutes(Number(evt.target.value)) })),
                        "\u043C\u0438\u043D."))))));
};
