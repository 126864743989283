import { Box, Button, makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import { ConfirmDelete } from '@/shared/components/ConfirmDelete';
const useStyles = makeStyles((theme) => ({
    input: {
        marginRight: theme.spacing(1),
        flex: 1,
        flexShrink: 0,
        marginBottom: '1px',
        padding: '7px',
    },
    buttonDelete: {
        position: 'relative',
        top: '7px',
        right: "-7px",
    }
}));
export const WorkflowStagesRow = ({ guid, showLabels = false, onDeleteRow, onLink, code, description, pluginType, system, pluginName, pluginMethodName, }) => {
    const styles = useStyles();
    return (React.createElement(Box, { mb: 1, display: "flex", alignItems: "center", mt: 2 },
        React.createElement(TextField, { className: styles.input, label: showLabels ? 'Код' : '', value: code, InputProps: { readOnly: true }, InputLabelProps: { shrink: true }, disabled: true }),
        React.createElement(TextField, { className: styles.input, label: showLabels ? 'Описание' : '', value: description, InputProps: { readOnly: true }, InputLabelProps: { shrink: true }, disabled: true }),
        React.createElement(TextField, { className: styles.input, label: showLabels ? 'Тип' : '', value: pluginType, InputProps: { readOnly: true }, InputLabelProps: { shrink: true }, disabled: true }),
        React.createElement(TextField, { className: styles.input, label: showLabels ? 'Система' : '', value: system, InputProps: { readOnly: true }, InputLabelProps: { shrink: true }, disabled: true }),
        React.createElement(TextField, { className: styles.input, label: showLabels ? 'Плагин' : '', value: pluginName, InputProps: { readOnly: true }, InputLabelProps: { shrink: true }, disabled: true }),
        React.createElement(TextField, { className: styles.input, label: showLabels ? 'Метод' : '', value: pluginMethodName, InputProps: { readOnly: true }, InputLabelProps: { shrink: true }, disabled: true }),
        React.createElement(Box, { minWidth: "110px", display: "flex", justifyContent: "flex-end", alignItems: "center" },
            onLink && (React.createElement(Button, { variant: "outlined", color: "primary", size: "small", onClick: () => onLink(guid) }, "\u0421\u0441\u044B\u043B\u043A\u0430")),
            React.createElement(ConfirmDelete, { className: styles.buttonDelete, view: "icon", onDelete: () => onDeleteRow(guid) }))));
};
