import React from 'react';
import { Popover } from '@material-ui/core';
import { getColorByType } from './utilities/get-color-by-type';
import { useStyles } from './trees.styles';
export const ValueInfo = ({ value, isSelected, parentNodeId, onNodeSelect }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleContext = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: `${parentNodeId}/Values/${value.Guid}`, className: isSelected ? classes.highlighted : undefined, "aria-describedby": id, onContextMenu: handleContext, style: { width: 'fit-content', color: getColorByType(value.Type) }, onClick: (e) => {
                e.stopPropagation;
                onNodeSelect(`${parentNodeId}/Values/${value.Guid}`, value.Type);
            } }, value.Name),
        React.createElement(Popover, { id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
            } },
            React.createElement("div", { className: classes.paper },
                React.createElement("div", null,
                    React.createElement("b", null, "ID: "),
                    value.$id),
                React.createElement("div", null,
                    React.createElement("b", null, "Guid: "),
                    value.Guid),
                React.createElement("div", null,
                    React.createElement("b", null, "ForeignKeyPropertyGuid: "),
                    value.ForeignKeyPropertyGuid),
                React.createElement("div", null,
                    React.createElement("b", null, "Type: "),
                    value.Type)))));
};
