import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { routes } from '@/pages/routes';
//
import { IntegrationChains } from '@/pages/IntegrationChains';
import { IntegrationChainPageAdd } from '@/pages/IntegrationChains/add';
import { IntegrationChainPage } from '@/pages/IntegrationChains/guid';
//
import { Management } from '@/pages/Management';
//
import { PluginPage } from '@/pages/Plugins/guid';
import { PluginPageAdd } from '@/pages/Plugins/add';
import { Plugins } from '@/pages/Plugins';
//
import { Systems } from '@/pages/Systems';
import { SystemPage } from '@/pages/Systems/guid';
import { SystemAdd } from '@/pages/Systems/add';
//
import { PluginInstances } from '@/pages/PluginInstances';
import { PluginInstancePage } from '@/pages/PluginInstances/guid';
import { PluginInstancePageAdd } from '@/pages/PluginInstances/add';
//
import { MigratorsList } from '@/pages/Migrators/MigratorList';
import { MigratorPageAdd } from '@/pages/Migrators/add';
import { DataMapping } from '@/pages/Migrators/components/Tree/DataMapping';
//
import { Users } from '@/pages/Users';
import { Login } from '@/pages/Login';
import { Monitoring } from '@/pages/Monitoring';
import { PageHeader } from '@/shared/components/PageHeader';
import { MessagesStatus } from '@/pages/MessagesStatus';
import { PrivateRoute } from '@/shared/components/PrivateRoute';
import { useAuth } from '@/shared/controllers/auth/context';
import { MigratorPage } from '@/pages/Migrators/guid';
import { MigratorPageDestination } from '@/pages/Migrators/guid_destination';
import { MigratorRebuildImportTree } from '@/pages/Migrators/rebuild';
import { MigratorImportKeysPage } from '@/pages/Migrators/import';
import { DataMigrationPage } from '@/pages/Migrators/migration';
import { DataMigrationEntity } from '@/pages/Migrators/migrationEntity';
import store from '@/pages/Migrators/components/Tree/store';
import { MigratorPageSource } from '@/pages/Migrators/guid_source';
import { useStyles } from './styles';
export const App = () => {
    useStyles();
    const { isAuth } = useAuth();
    return (React.createElement(Switch, null,
        !isAuth && React.createElement(Route, { path: routes.login, component: Login }),
        React.createElement(PrivateRoute, { path: "/" },
            React.createElement(PageHeader, null),
            React.createElement(Switch, null,
                React.createElement(PrivateRoute, { path: routes.management },
                    React.createElement(Management, null)),
                React.createElement(PrivateRoute, { path: routes.messagesStatus },
                    React.createElement(MessagesStatus, null)),
                React.createElement(PrivateRoute, { path: routes.integrationChains },
                    React.createElement(IntegrationChains, null)),
                React.createElement(PrivateRoute, { path: routes.integrationChainAdd },
                    React.createElement(IntegrationChainPageAdd, null)),
                React.createElement(PrivateRoute, { path: routes.integrationChain },
                    React.createElement(IntegrationChainPage, null)),
                React.createElement(PrivateRoute, { path: routes.plugins },
                    React.createElement(Plugins, null)),
                React.createElement(PrivateRoute, { path: routes.pluginAdd, exact: true },
                    React.createElement(PluginPageAdd, null)),
                React.createElement(PrivateRoute, { path: [routes.plugin, routes.pluginVersion], exact: true },
                    React.createElement(PluginPage, null)),
                React.createElement(PrivateRoute, { path: routes.pluginInstances },
                    React.createElement(PluginInstances, null)),
                React.createElement(PrivateRoute, { path: routes.pluginInstanceAdd },
                    React.createElement(PluginInstancePageAdd, null)),
                React.createElement(PrivateRoute, { path: routes.pluginInstance },
                    React.createElement(PluginInstancePage, null)),
                React.createElement(PrivateRoute, { path: routes.systemAdd },
                    React.createElement(SystemAdd, null)),
                React.createElement(PrivateRoute, { path: routes.systems },
                    React.createElement(Systems, null)),
                React.createElement(PrivateRoute, { path: routes.system },
                    React.createElement(SystemPage, null)),
                React.createElement(PrivateRoute, { path: routes.users },
                    React.createElement(Users, null)),
                React.createElement(PrivateRoute, { path: routes.monitoring },
                    React.createElement(Monitoring, null)),
                React.createElement(PrivateRoute, { path: routes.migrators },
                    React.createElement(MigratorsList, null)),
                React.createElement(PrivateRoute, { path: routes.migratorDataMigrationEntity },
                    React.createElement(DataMigrationEntity, null)),
                React.createElement(PrivateRoute, { path: routes.dataMapping },
                    React.createElement(Provider, { store: store },
                        React.createElement(DataMapping, null))),
                React.createElement(PrivateRoute, { path: routes.migratorRebuild },
                    React.createElement(MigratorRebuildImportTree, null)),
                React.createElement(PrivateRoute, { path: routes.migratorImportKeys },
                    React.createElement(MigratorImportKeysPage, null)),
                React.createElement(PrivateRoute, { path: routes.migratorDataMigration },
                    React.createElement(DataMigrationPage, null)),
                React.createElement(PrivateRoute, { path: routes.migratorSource },
                    React.createElement(MigratorPageSource, null)),
                React.createElement(PrivateRoute, { path: routes.migratorDestination },
                    React.createElement(MigratorPageDestination, null)),
                React.createElement(PrivateRoute, { path: routes.migratorAdd },
                    React.createElement(MigratorPageAdd, null)),
                React.createElement(PrivateRoute, { path: routes.migrator },
                    React.createElement(MigratorPage, null)),
                React.createElement(Route, { path: "*" },
                    React.createElement(Redirect, { to: routes.management }))))));
};
