import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, Typography } from '@material-ui/core';
import { useStyles } from "@/pages/Plugins/styles";
import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { PageLayout } from '@/shared/components/PageLayout';
import { useGetPlugins } from '@/shared/services/plugins/hooks/use-get-plugins';
import { Menu } from '@/shared/components/Menu';
export const Plugins = () => {
    const history = useHistory();
    const columns = [
        { value: 'id', label: 'ИД', minWidth: 40 },
        { value: 'guid', label: 'Уникальный идентификатор', minWidth: 300 },
        { value: 'pluginTypeDescription', label: 'Тип', minWidth: 140 },
        { value: 'name', label: 'Название плагина' },
        // { value: 'pluginDescription', label: 'Описание плагина' },
    ];
    const [additionalValue, setAdditionalValue] = useState('');
    const { data: pluginsList, refetch } = useGetPlugins(additionalValue);
    pluginsList?.huHeadPluginDescriptions?.reduce((acc, { guid, pluginTypeDescription, name, id }) => ({
        ...acc,
        [pluginTypeDescription]: acc[pluginTypeDescription]
            ? [...acc[pluginTypeDescription], { guid, name, id }]
            : [{ guid, name, id }],
    }), {}),
        [pluginsList];
    const classes = useStyles();
    const rowsPerPageSetting = [10, 25, 50, 100];
    const handleChangePage = (_, newPage) => {
        setAdditionalValue(`?pageSize=${pluginsList?.pageSize}&pageNumber=${newPage + 1}`);
        refetch();
    };
    const handleChangeRowsPerPage = (event) => {
        setAdditionalValue(`?pageSize=${Number(event.target.value)}`);
        refetch();
    };
    return (React.createElement(PageLayout, null,
        React.createElement("div", null,
            React.createElement(Box, { component: "div", display: "inline" },
                React.createElement(Menu, { addButtonProps: {
                        disabled: false,
                        handleClick: () => history.push('/plugin/add'),
                        label: 'Добавить плагин',
                    } })),
            React.createElement(TableContainer, { component: Paper, className: classes.tableHideOverflow },
                React.createElement(Typography, { variant: "h4", className: classes.marginBott }, "\u041F\u043B\u0430\u0433\u0438\u043D\u044B"),
                React.createElement(Table, { className: classes.table },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null, columns.map((column) => (React.createElement(TableCell, { key: column.value, align: column.align, className: classes.tableRowHead }, column.label))))),
                    React.createElement(TableBody, null, pluginsList?.huHeadPluginDescriptions.map((plugin) => (React.createElement(TableRow, { key: plugin.guid }, columns.map((column) => {
                        const value = plugin[column.value];
                        column.value;
                        return (React.createElement(TableCell, { component: "section", scope: "row", key: column.value, className: classes.tableRow, style: { width: column.minWidth } }, column.value === 'guid' ?
                            (React.createElement(NavLink, { to: `/plugin/${plugin.guid}` }, (column.component && column.component(value)) || value))
                            :
                                (column.component && column.component(value)) || value));
                    })))))),
                pluginsList?.huHeadPluginDescriptions && (React.createElement(TablePagination, { labelRowsPerPage: 'Cтрок на странице', rowsPerPageOptions: rowsPerPageSetting, component: "div", count: pluginsList?.totalCount, rowsPerPage: pluginsList?.pageSize, page: pluginsList?.currentPage - 1, onChangePage: handleChangePage, onChangeRowsPerPage: handleChangeRowsPerPage }))))));
};
