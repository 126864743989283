import { useMutation, useQueryClient } from 'react-query';
import { useActionLogger } from '@/shared/hooks/action-logger';
import { deletePluginFile } from '../index';
export const useDeleteFile = (callback) => {
    const queryClient = useQueryClient();
    const { setAction } = useActionLogger();
    return useMutation(deletePluginFile, {
        onSuccess: () => {
            setAction({
                type: 'success',
                message: 'Removing of the plugin file was successful',
            });
            if (callback && typeof callback === 'function') {
                callback();
            }
        },
        onMutate: (guid) => {
            const previousPlugins = queryClient.getQueryData('GetPluginDescriptionList');
            const removePluginFileByPluginGuid = (plugins) => plugins?.map(({ huHeadPluginVersionDescriptions, ...plugin }) => ({
                ...plugin,
                huHeadPluginVersionDescriptions: huHeadPluginVersionDescriptions.filter((file) => file.guid !== guid),
            }));
            queryClient.setQueryData('GetPluginDescriptionList', removePluginFileByPluginGuid);
            return { previousPlugins };
        },
        onError: (error, guid, context) => {
            queryClient.setQueryData('GetPluginDescriptionList', context.previousPlugins);
            setAction({
                type: 'error',
                message: error.toString(),
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries('GetPluginDescriptionList');
        },
    });
};
