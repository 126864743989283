export const dataSource = [
    {
        key: '1',
        id: '1136',
        startDate: '20.09.20 14:40',
        integrationChain: 'GetDataFromDirAndSaveTo1C',
        status: 'Completed',
        currentStage: 'End',
        result: 'Ok',
    },
    {
        key: '2',
        id: '1177',
        startDate: '20.09.20 14:40',
        integrationChain: 'GetDataFromDirAndSaveTo1C_TWO',
        status: 'Completed',
        currentStage: 'End',
        result: 'Ok',
    },
    {
        key: '3',
        id: '1136',
        startDate: '20.09.20 14:40',
        integrationChain: 'GetDataFromDirAndSaveTo1C_TWO',
        status: 'InProgress',
        currentStage: 'SaveOneCKeyToMig',
        result: 'Ok',
    },
    {
        key: '4',
        id: '1136',
        startDate: '20.09.20 14:40',
        integrationChain: 'GetDataFromDirAndSaveTo1C_Test',
        status: 'Error',
        currentStage: 'GetDataFromDir',
        result: 'ConnectionFailed',
    },
    {
        key: '5',
        id: '1186',
        startDate: '20.09.20 14:40',
        integrationChain: 'EmployeesFromDirTo1C',
        status: 'InProgress',
        currentStage: 'End',
        result: 'Ok',
    },
    {
        key: '6',
        id: '1222',
        startDate: '20.09.20 14:40',
        integrationChain: 'ContractFromDirTo1C',
        status: 'InProgress',
        currentStage: 'End',
        result: '',
    },
    {
        key: '7',
        id: '1244',
        startDate: '20.09.20 14:40',
        integrationChain: 'ContractorFromDirTo1C',
        status: 'InProgress',
        currentStage: 'End',
        result: '',
    },
    {
        key: '8',
        id: '1234',
        startDate: '20.09.20 14:40',
        integrationChain: 'AccountFromDirTo1C',
        status: 'Error',
        currentStage: 'End',
        result: '',
    },
];
