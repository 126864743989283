export const init = {
    connections: []
};
export const connectionsReducer = (state = init, action) => {
    switch (action.type) {
        case 'GET_CONNECTIONS':
            return {
                ...state,
                connections: action.payload
            };
        case 'ADD_CONNECTION':
            return {
                ...state,
                connections: [...state.connections, action.payload]
            };
        case 'UPDATE_CONNECTION':
            return {
                ...state,
                connections: state.connections.map(connection => connection.propertyLinkGuid === action.payload.propertyLinkGuid ? action.payload : { ...connection })
            };
        case 'DELETE_CONNECTION':
            return {
                ...state,
                connections: state.connections.filter(connection => connection.propertyLinkGuid !== action.payload)
            };
        default:
            return state;
    }
};
