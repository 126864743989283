import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, InputLabel, makeStyles, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { PageLayout } from '@/shared/components/PageLayout';
import { MonitoringInfoTable } from '@/pages/Monitoring/Table';
import { client } from '@/shared/api';
import { DropDownHashCode } from './components/DropDown';
import { useGetDashboardsInfo } from './services/hooks/use-get-hashboards';
const uuid = (index) => `${Math.random()}_${index ? index : Math.random()}_${new Date().getTime()}`;
const PATH_AUTH_API = 'Admin';
let hashTagsForRequest = '';
const useStyles = makeStyles((theme) => ({
    colorButtons: {
        backgroundColor: '#0066CC',
        color: 'white',
        height: '40px',
        minWidth: '127px',
        borderRadius: '0px',
    },
    openFilterText: {
        marginRight: '10px',
        fontSize: '14px'
    },
    openFilterButton: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
    },
    filterButton: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        height: '40px',
    },
    messageText: {
        minWidth: '240px',
        height: '56px',
    },
}));
const decodeString = (codeType) => {
    let stringBytes = new TextEncoder().encode(codeType);
    let base64String = btoa(String.fromCharCode(...stringBytes));
    return base64String;
};
export const Monitoring = () => {
    const [users, setDashboards] = useState();
    const [value, setValue] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [dashboardType, setDashboardType] = useState('');
    const [hashTags, setHashTags] = useState('');
    const [valueDescription, setValueDescription] = useState([]);
    const { data: infos } = useGetDashboardsInfo();
    const startDatetoISO = startDate ? startDate.toISOString() : null;
    const endDatetoISO = endDate ? endDate.toISOString() : null;
    const dashBoardTypetoBase64 = decodeString(dashboardType);
    // const queryHashTags = (data && isSuccess) ? ([...data?.hashTags].reduce((acc, curr) => acc + `&HashTags=${curr}`, '')) : '';
    const styles = useStyles();
    async function executeFetch(additionalRequest) {
        const data = await fetchInfo(additionalRequest);
        setDashboards(data);
    }
    useEffect(() => {
        executeFetch('');
        // eslint-disable-next-line react-hooks/exhaustive-deps       
    }, []);
    const [, setIsOpenUserDrawer] = useState(false);
    const handleClickRowByUsersTable = async (dashboard) => {
        try {
            const dashboardInfomation = Object.assign({}, users);
            if (dashboard.isShowStageInfo === undefined || dashboard.isShowStageInfo === false) {
                const { data } = await client.get(`${PATH_AUTH_API}/GetMessageStagesInfo/${dashboard.messageId}`);
                if (data) {
                    dashboardInfomation?.dashboardInfos.forEach((element) => {
                        if (dashboard.messageId === element.messageId) {
                            element.messageStage = data;
                            element.isShowStageInfo = true;
                        }
                    });
                }
            }
            else {
                dashboardInfomation?.dashboardInfos.forEach((element) => {
                    if (dashboard.messageId === element.messageId) {
                        element.isShowStageInfo = false;
                    }
                });
            }
            setDashboards(dashboardInfomation);
        }
        catch (e) {
            alert(e);
            return undefined;
        }
    };
    const handleClear = (_e) => {
        setValue('');
        setStartDate(null);
        setEndDate(null);
        setDashboardType('');
        setMessage('');
        setValueDescription([]);
        setHashTags('');
    };
    const handleClickRefreshTable = () => {
        executeFetch('');
        handleClear('');
    };
    const handleClickRow = (value) => {
        if (value) {
            hashTagsForRequest = '';
            value.forEach((element) => {
                if (hashTagsForRequest.length > 0) {
                    hashTagsForRequest += `${'&HashTags='}${element}`;
                }
                else {
                    hashTagsForRequest = `&HashTags=${element}`;
                }
            });
            setHashTags(hashTagsForRequest);
        }
        setIsOpenUserDrawer(false);
    };
    // const handleClickSearch = () => {
    //     const inputValue = (document.getElementById('search-bar-input') as HTMLInputElement).value;
    //     const searchForRequest = inputValue.length > 0 ? `value=${inputValue}` : '';
    //     let result = '';
    //     if (hashTagsForRequest.length > 0) {
    //         result = `?${hashTagsForRequest}`;
    //     }
    //     if (searchForRequest.length > 0 && result.length > 0) {
    //         result += `&${searchForRequest}`;
    //     } else if (result.length === 0) {
    //         result = `?${searchForRequest}`;
    //     }
    //     executeFetch(result);
    // };
    const handleClickPaginationChange = (value) => {
        executeFetch((startDate || endDate || message || dashboardType) ? `?chain-type-code-query=${dashboardType ? dashBoardTypetoBase64 : ''}&chain-instantiated-from=${startDate ? startDatetoISO : ''}&chain-instantiated-to=${endDate ? endDatetoISO : ''}&Value=${message ? message : ''}${hashTags}&${value}` : value);
    };
    async function fetchInfo(additionalRequest) {
        try {
            const { data } = await client.get(`${PATH_AUTH_API}/GetDashboardInfo${additionalRequest}`);
            return data;
        }
        catch (e) {
            alert(e);
            return undefined;
        }
    }
    if (users === null) {
        return React.createElement("p", null, "Loading...");
    }
    const handleSetMessage = (event) => {
        setMessage(event.target.value);
    };
    const handleSetDashboardType = (event) => {
        setDashboardType(event.target.value);
    };
    const handleFilterDashboardsInfo = () => {
        executeFetch(`?chain-type-code-query=${dashboardType ? dashBoardTypetoBase64 : ''}&chain-instantiated-from=${startDate ? startDatetoISO : ''}&chain-instantiated-to=${endDate ? endDatetoISO : ''}&Value=${message ? message : ''}${hashTags}&PageNumber=${users?.currentPage}&PageSize=${users?.pageSize}`);
    };
    const chainTypes = infos
        ?.dashboardInfos.map(dashboardInfo => dashboardInfo.chainType)
        .filter((item, index, array) => array.indexOf(item) === index);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageLayout, null,
            React.createElement(Box, { mb: 3, display: "flex", justifyContent: "flex-end" },
                React.createElement(Box, { mr: 2 },
                    React.createElement(Button, { className: styles.openFilterButton, onClick: () => setOpen(prev => !prev) }, !open ? (React.createElement(React.Fragment, null,
                        React.createElement(Typography, { className: styles.openFilterText }, "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u0444\u0438\u043B\u044C\u0442\u0440\u044B"),
                        React.createElement(FilterList, null))) : (React.createElement(React.Fragment, null,
                        React.createElement(Typography, { className: styles.openFilterText }, "\u0421\u043A\u0440\u044B\u0442\u044C \u0444\u0438\u043B\u044C\u0442\u0440\u044B"),
                        React.createElement(FilterList, null)))))),
            open && (React.createElement(React.Fragment, null,
                React.createElement(Box, { mb: 3, display: 'flex', justifyContent: 'flex-start' },
                    React.createElement(Box, { mr: 1, display: "flex", alignItems: "flex-end" },
                        React.createElement(TextField, { id: "outlined-basic", label: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435 \u0437\u043D\u0430\u0447\u0435\u043D\u0438\u044F", placeholder: "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435 \u0437\u043D\u0430\u0447\u0435\u043D\u0438\u044F", multiline: true, variant: "outlined", className: styles.messageText, value: message, onChange: handleSetMessage, required: true })),
                    React.createElement(Box, { display: 'flex', alignItems: 'flex-end' },
                        React.createElement(DropDownHashCode, { onClickRow: (value) => handleClickRow(value), valueDescription: valueDescription, setValueDescription: setValueDescription })),
                    React.createElement(Box, { ml: 1, display: 'flex', alignItems: 'flex-end' },
                        React.createElement(FormControl, { variant: "outlined", style: { minWidth: 270 } },
                            React.createElement(InputLabel, { id: "demo-simple-select-outlined-label" }, "T\u0438\u043F \u043F\u0440\u043E\u0446\u0435\u0441\u0441\u0430"),
                            React.createElement(Select, { labelId: "demo-simple-select-outlined-label", id: "demo-simple-select-outlined", value: dashboardType, label: "T\u0438\u043F \u043F\u0440\u043E\u0446\u0435\u0441\u0441\u0430", onChange: handleSetDashboardType, required: true }, chainTypes?.map((chainType, index) => (React.createElement(MenuItem, { key: uuid(index), value: chainType }, chainType)))))),
                    React.createElement(Box, { ml: 1 },
                        React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                            React.createElement(DemoContainer, { components: ['DateTimePicker'] },
                                React.createElement(DateTimePicker, { ampm: false, format: 'DD/MM/YYYY hh:mm', label: "\u0414\u0430\u0442\u0430 \u043D\u0430\u0447\u0430\u043B\u0430", value: startDate, onChange: (newDate) => setStartDate(newDate) })))),
                    React.createElement(Box, { ml: 1 },
                        React.createElement(LocalizationProvider, { dateAdapter: AdapterDayjs },
                            React.createElement(DemoContainer, { components: ['DateTimePicker'] },
                                React.createElement(DateTimePicker, { ampm: false, format: 'DD/MM/YYYY hh:mm', label: "\u0414\u0430\u0442\u0430 \u0432\u044B\u043F\u043E\u043B\u043D\u0435\u043D\u0438\u044F", value: endDate, onChange: (newDate) => setEndDate(newDate) })))),
                    React.createElement(Box, { ml: 1, mt: 1, display: 'flex', alignItems: 'center' },
                        React.createElement(Button, { className: styles.colorButtons, onClick: handleFilterDashboardsInfo }, "\u041F\u043E\u0438\u0441\u043A")),
                    React.createElement(Box, { ml: 1, mt: 1, display: "flex", justifyContent: "flex-end", alignItems: 'center' },
                        React.createElement(Button, { size: "medium", variant: "contained", className: styles.colorButtons, onClick: handleClickRefreshTable }, "\u0421\u0431\u0440\u043E\u0441\u0438\u0442\u044C"))))),
            React.createElement(Box, { height: "75vh" },
                React.createElement(MonitoringInfoTable, { dashboards: users, onClickRow: handleClickRowByUsersTable, onClickPagination: handleClickPaginationChange })))));
};
