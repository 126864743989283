import { useMutation, useQueryClient } from 'react-query';
import { useActionLogger } from '@/shared/hooks/action-logger';
import { registerSystem } from '../index';
export const useRegisterSystem = (callback) => {
    const queryClient = useQueryClient();
    const { setAction } = useActionLogger();
    return useMutation(registerSystem, {
        onSuccess: (resp) => {
            if (resp?.isSuccess) {
                queryClient.invalidateQueries('GetAllSystems');
                setAction({
                    type: 'success',
                    message: 'Registering the system was successful',
                });
            }
            else if (resp?.fieldValidationErrors) {
                if (callback && typeof callback === 'function') {
                    callback(resp.fieldValidationErrors);
                }
            }
        },
        onError: (error) => {
            setAction({
                type: 'error',
                message: error.toString(),
            });
        }
    });
};
