import React, { useEffect } from 'react';
import { Box, Button, Grid, makeStyles, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { PluginType } from '@/entities/plugin';
const useStyles = makeStyles((theme) => ({
    form: {
        width: '400px',
    },
    input: {
        marginBottom: theme.spacing(2),
    },
    colorButton: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        marginTop: '2rem',
    },
    marginBott: {
        marginTop: '1rem',
        marginBottom: '1rem',
        marginLeft: '1rem',
        color: 'Black',
        textAlign: 'center',
    },
    marginTopColorGrid: {
        color: 'grey',
        marginTop: '2rem',
        fontSize: '15px',
    },
    textAliginEnd: {
        textAlign: 'end',
        marginTop: '8px',
        color: 'Black',
    },
}));
export const FormAdd = ({ onSubmit, disabledSaveButton = false }) => {
    const styles = useStyles();
    const { register, handleSubmit, setValue, errors } = useForm();
    useEffect(() => {
        register('name', {
            required: true,
        });
        register('type', {
            required: true,
        });
    }, [register]);
    return (React.createElement(Paper, null,
        React.createElement("form", { className: styles.form, onSubmit: handleSubmit((data) => onSubmit(data)) },
            React.createElement(Typography, { variant: "h5", className: styles.marginBott }, "\u0414\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u043F\u043B\u0430\u0433\u0438\u043D\u0430"),
            React.createElement(Box, { className: styles.form, justifyContent: "flex-start" },
                React.createElement(Grid, { container: true, spacing: 1, className: styles.marginTopColorGrid },
                    React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 *"),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(TextField, { className: styles.input, fullWidth: true, id: "outlined-basic", variant: "outlined", size: "small", onChange: ({ target }) => setValue('name', target.value), error: Boolean(errors?.name) })),
                    React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u0422\u0438\u043F \u043F\u043B\u0430\u0433\u0438\u043D\u0430 *"),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(TextField, { className: styles.input, fullWidth: true, id: "standard-select-currency", select: true, variant: "outlined", size: "small", onChange: ({ target }) => setValue('type', target.value), error: Boolean(errors?.type) },
                            React.createElement(MenuItem, { value: PluginType.Integration }, "Integration"),
                            React.createElement(MenuItem, { value: PluginType.Transformation }, "Transformation"),
                            React.createElement(MenuItem, { value: PluginType.Serialization }, "Serialization"),
                            React.createElement(MenuItem, { value: PluginType.ObjectServe }, "Object Serve"))),
                    React.createElement(Box, { mb: 2, mt: 4, ml: 2 },
                        React.createElement(Button, { variant: "contained", className: styles.colorButton, type: "submit", disabled: disabledSaveButton }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")))))));
};
