import React, { useEffect } from 'react';
import { Box, Button, Grid, IconButton, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import DeleteIcon from '@material-ui/icons/Delete';
import { ConfirmDeleteSystem } from '../../services/delete-system';
const useStyles = makeStyles((theme) => ({
    form: {
        width: '600px',
        // height: '415px',
        marginLeft: '1rem',
        paddingBottom: '1.5rem',
    },
    input: {
        marginBottom: theme.spacing(2),
    },
    button: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        marginTop: '1rem',
    },
    paddingBott: {
        paddingTop: '2rem',
        paddingBottom: '2rem',
        marginLeft: '1rem',
    },
    marginTopColorGrid: {
        marginTop: '2rem',
        fontSize: '15px',
    },
    textAliginEnd: {
        textAlign: 'end',
        marginTop: '8px',
    },
    textAliginEndColor: {
        textAlign: 'end',
        color: 'grey',
    },
    color: {
        color: 'grey',
    },
    buttonDelete: {
        position: 'relative',
        top: '15px',
        right: "-7px",
    }
}));
export const FormEdit = ({ updatingSystem, onSubmit, 
// serializationOptions,
// integrationOptions,
// objectServeOptions,
// pluginInstanceOptions,
defaultValues, onDelete, }) => {
    const styles = useStyles();
    const { register, control, handleSubmit } = useForm({
        defaultValues: {
            guid: defaultValues.systemInfo.guid,
            code: defaultValues?.systemInfo.code,
            description: defaultValues?.systemInfo.description,
            integrationPluginGuid: defaultValues.systemInfo.integrationPluginGuid,
            objectServePluginGuid: defaultValues.systemInfo.objectServePluginGuid,
            pluginInstanceGuid: defaultValues.systemInfo.pluginInstanceGuid,
            serializationPluginGuid: defaultValues.systemInfo.serializationPluginGuid,
            updateSystemSecrets: defaultValues?.systemInfo.huHeadSecretInfos.map((secret) => ({
                secretType: secret.type,
                value: secret.value,
            })),
        },
    });
    const { fields, append, remove } = useFieldArray({ control, name: 'updateSystemSecrets' });
    useEffect(() => {
        register('code');
        register('guid');
        register('description');
        register('serializationPluginGuid');
        register('integrationPluginGuid');
        register('objectServePluginGuid');
        register('pluginInstanceGuid');
    }, [register]);
    return (React.createElement("form", { className: styles.form, onSubmit: handleSubmit((data) => onSubmit(data)) },
        React.createElement(Typography, { variant: "h5", className: styles.paddingBott }, "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u0441\u0438\u0441\u0442\u0435\u043C\u0435"),
        React.createElement(Box, { className: styles.form, justifyContent: "flex-start" },
            React.createElement(Grid, { container: true, spacing: 1, className: styles.marginTopColorGrid },
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEndColor }, "\u041A\u043E\u0434:"),
                React.createElement(Grid, { item: true, xs: 6, className: styles.color }, defaultValues.systemInfo.code),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEndColor }, "\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435:"),
                React.createElement(Grid, { item: true, xs: 6, className: styles.color }, defaultValues.systemInfo.description),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEndColor }, "\u0423\u043D\u0438\u043A\u0430\u043B\u044C\u043D\u044B\u0439 \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440:"),
                React.createElement(Grid, { item: true, xs: 6, className: styles.color }, defaultValues.systemInfo.guid),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEndColor }, "\u0421\u0435\u0440\u0438\u0430\u043B\u0438\u0437\u0430\u0446\u0438\u043E\u043D\u043D\u044B\u0439 \u043F\u043B\u0430\u0433\u0438\u043D:"),
                React.createElement(Grid, { item: true, xs: 6, className: styles.color }, defaultValues.systemInfo.serializationPluginGuid),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEndColor }, "\u0418\u043D\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u043E\u043D\u043D\u044B\u0439 \u043F\u043B\u0430\u0433\u0438\u043D:"),
                React.createElement(Grid, { item: true, xs: 6, className: styles.color }, defaultValues.systemInfo.integrationPluginGuid),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEndColor }, "\u0410\u0433\u0435\u043D\u0442:"),
                React.createElement(Grid, { item: true, xs: 6, className: styles.color }, defaultValues.systemInfo.pluginInstanceGuid),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEndColor }, "\u041F\u043B\u0430\u0433\u0438\u043D \u043E\u0442\u043B\u043E\u0436\u0435\u043D\u043D\u043E\u0439 \u043E\u0447\u0435\u0440\u0435\u0434\u0438:"),
                React.createElement(Grid, { item: true, xs: 6, className: styles.color }, defaultValues.systemInfo.objectServePluginGuid),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u0421\u0435\u043A\u0440\u0435\u0442"),
                React.createElement(Grid, { item: true, xs: 6 }, fields.map((item, index) => (React.createElement(Box, { key: item.id, display: "flex", alignItems: "center" },
                    React.createElement(Box, { mr: 1, width: "150px" },
                        React.createElement(Controller, { as: React.createElement(TextField, { className: styles.input, fullWidth: true, label: "\u0422\u0438\u043F", required: true, select: true, defaultValue: "", id: "standard-select-currency", variant: "outlined", size: "small" },
                                React.createElement(MenuItem, { value: 1 }, "\u041B\u043E\u0433\u0438\u043D"),
                                React.createElement(MenuItem, { value: 2 }, "\u041F\u0430\u0440\u043E\u043B\u044C"),
                                React.createElement(MenuItem, { value: 3 }, "\u0422\u043E\u043A\u0435\u043D"),
                                React.createElement(MenuItem, { value: 4 }, "\u0421\u0435\u0440\u0432\u0435\u0440"),
                                React.createElement(MenuItem, { value: 5 }, "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0411\u0414"),
                                React.createElement(MenuItem, { value: 6 }, "\u0425\u043E\u0441\u0442"),
                                React.createElement(MenuItem, { value: 7 }, "\u041F\u043E\u0440\u0442")), name: `updateSystemSecrets[${index}].secretType`, control: control, defaultValue: item.type })),
                    React.createElement(Box, { width: "200px" },
                        React.createElement(TextField, { type: item.type === 2 ? 'password' : 'text', className: styles.input, fullWidth: true, id: "standard-select-currency", variant: "outlined", size: "small", label: "\u0417\u043D\u0430\u0447\u0435\u043D\u0438\u0435", required: true, name: `updateSystemSecrets[${index}].value`, defaultValue: item.value, inputRef: register({
                                required: true,
                            }) })),
                    React.createElement(IconButton, { className: styles.buttonDelete, edge: "end", onClick: () => remove(index) },
                        React.createElement(DeleteIcon, null))))))),
            React.createElement("div", { style: { width: '100%' } },
                React.createElement(Box, { component: "div", display: "inline" },
                    React.createElement(Button, { variant: "contained", type: "submit", disabled: updatingSystem, className: styles.button }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C \u0438\u0437\u043C\u0435\u043D\u0435\u043D\u0438\u044F")),
                React.createElement(Box, { component: "div", display: "inline", m: 1 },
                    React.createElement(Button, { variant: "contained", onClick: () => append({ secretType: 1, value: '' }), className: styles.button }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0441\u0435\u043A\u0440\u0435\u0442")),
                React.createElement(Box, { component: "div", display: "inline" },
                    React.createElement(ConfirmDeleteSystem, { onDelete: () => onDelete(defaultValues.systemInfo.guid) }))))));
};
