import { client } from "@/shared/api";
const PATH_AUTH_API = 'Admin';
export const getHashTags = async () => {
    const { data } = await client.get(`${PATH_AUTH_API}/GetHashTags`);
    if (!data.isSuccess) {
        throw new Error(`GetHashTags. ${data.errorMessage}`);
    }
    return data;
};
export const getDashBoardsInfos = async () => {
    const { data } = await client.get(`${PATH_AUTH_API}/GetDashboardInfo`);
    if (!data) {
        throw new Error(`The error of the method GetDashboardInfo `);
    }
    return data;
};
