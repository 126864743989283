import { useMutation, useQueryClient } from 'react-query';
import { useActionLogger } from '@/shared/hooks/action-logger';
import { deletePlugin } from '../index';
export const useDeletePlugin = (callback) => {
    const queryClient = useQueryClient();
    const { setAction } = useActionLogger();
    return useMutation(deletePlugin, {
        onSuccess: () => {
            queryClient.invalidateQueries('GetPluginDescriptionList');
            setAction({
                type: 'success',
                message: 'Removing the plugin was successful',
            });
            if (callback && typeof callback === 'function') {
                callback();
            }
        },
        onError: (error) => {
            setAction({
                type: 'error',
                message: error.toString(),
            });
        },
    });
};
