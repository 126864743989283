import React from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    form: {
        width: '600px',
        height: '360px',
        cursor: 'pointer',
        marginTop: '10px',
        marginLeft: '2rem',
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    input: {
        marginBottom: theme.spacing(2),
        width: '400px',
        cursor: 'pointer',
        height: '40px',
    },
    margin: {
        margin: theme.spacing(1),
    },
    marginTop: {
        marginTop: '2rem',
    },
    textAliginEnd: {
        textAlign: 'end',
    },
    color: {
        color: 'grey',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    marginTopColorGrid: {
        color: 'grey',
        marginTop: '2rem',
        fontSize: '15px',
    },
    textAliginEndMarginBottom: {
        textAlign: 'end',
        marginBottom: '2rem',
    },
}));
export const InstanceView = ({ instanceDetails }) => {
    const styles = useStyles();
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { className: styles.form },
            React.createElement(Typography, { variant: "h5", className: styles.marginTop }, "\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E\u0431 \u0430\u0433\u0435\u043D\u0442\u0435"),
            React.createElement(Grid, { container: true, spacing: 1, className: styles.marginTopColorGrid },
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0430\u0433\u0435\u043D\u0442\u0430:"),
                React.createElement(Grid, { item: true, xs: 6, className: styles.color }, instanceDetails.name),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A HTTP:"),
                React.createElement(Grid, { item: true, xs: 6, className: styles.color }, instanceDetails.httpHeader),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u0410\u0434\u0440\u0435\u0441:"),
                React.createElement(Grid, { item: true, xs: 6, className: styles.color }, instanceDetails.host),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u041F\u043E\u0440\u0442:"),
                React.createElement(Grid, { item: true, xs: 6, className: styles.color }, instanceDetails.port),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "Custom Head Http Header:"),
                React.createElement(Grid, { item: true, xs: 6, className: styles.color }, instanceDetails?.instanceData?.headHttpHeader || ''),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "Custom Head Host:"),
                React.createElement(Grid, { item: true, xs: 6, className: styles.color }, instanceDetails?.instanceData?.headHostName || ''),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u0421\u0442\u0430\u0442\u0443\u0441 \u0430\u0433\u0435\u043D\u0442\u0430:"),
                React.createElement(Grid, { item: true, xs: 6, className: styles.color }, instanceDetails.stateName),
                React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEndMarginBottom }, "\u0423\u043D\u0438\u043A\u0430\u043B\u044C\u043D\u044B\u0439 \u0438\u0434\u0435\u043D\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0440:"),
                React.createElement(Grid, { item: true, xs: 6, className: styles.color }, instanceDetails.guid)))));
};
