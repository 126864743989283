import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
    marginBott: {
        marginTop: '1rem',
        marginBottom: '1rem',
        marginLeft: '1rem',
    },
    tableHideOverflow: {
        overflowX: 'hidden',
    },
    table: {
        minWidth: 400,
    },
    tableRowHead: {
        //   cursor: 'pointer',
        padding: '5px 16px 5px 16px',
        fontSize: '0.8rem',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '0px',
        tableLayout: 'auto',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        fontWeight: 'bolder',
        borderLeft: '1px solid rgba(224, 224, 224, 1)',
        //   backgroundColor: '#d6d9db',
    },
    tableRow: {
        // cursor: 'pointer',
        padding: '5px 16px 5px 16px',
        fontSize: '0.8rem',
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        borderRadius: '0px',
        tableLayout: 'auto',
        lineHeight: '1.5rem',
    },
    colorButtonBack: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        marginTop: '1rem',
    },
    registerButton: {
        backgroundColor: '#0066CC',
        color: '#FFFFFF',
        fontSize: '10px'
    },
    titleGuid: {
        paddingTop: '2rem',
    },
    guidForm: {
        width: '900px',
    },
    guidGridContainer: {
        color: 'grey',
        marginTop: '2rem',
        fontSize: '15px',
    },
    guidText: {
        textAlign: 'start',
    },
    guidField: {
        color: 'grey',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 250,
    },
    guidSystemContainer: {
        marginTop: '2rem',
        justifyContent: 'center',
        alignItems: 'center',
    },
    guidSystemText: {
        textAlign: 'center',
        fontSize: '18px'
    },
    guidSystemButton: {
    // textAlign: 'end',
    },
    guidNextButton: {
        marginLeft: '480px',
        marginTop: '1.5rem',
        fontSize: '16px',
        fontWeight: 700,
        color: '#0066CC',
    },
    buttonSave: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        height: '50px',
    }
}));
