export const routes = {
    management: '/management',
    integrationChains: '/integration-chains',
    integrationChainAdd: '/integration-chain/add',
    integrationChain: '/integration-chain/:guid',
    plugins: '/plugins',
    plugin: '/plugin/:guid',
    pluginVersion: '/plugin/:guid/:version',
    pluginAdd: '/plugin/add',
    pluginInstances: '/plugin-instances',
    pluginInstanceAdd: '/plugin-instance/add',
    pluginInstance: '/plugin-instance/:guid',
    messagesStatus: '/messages-status',
    systems: '/systems',
    system: '/system/:guid',
    systemAdd: '/system/add',
    users: '/users',
    login: '/login',
    monitoring: '/monitoring',
    migrators: '/migrators',
    dataMapping: '/migrator/:guid/:source/:destination/data_mapping',
    migratorAdd: '/migrator/add',
    migrator: '/migrator/:guid',
    migratorSource: '/migrator/:guid/source/:source/:destination',
    migratorDestination: '/migrator/:guid/destination/:source/:destination/',
    migratorRebuild: '/migrator/:guid/:source/:destination/rebuild',
    migratorImportKeys: '/migrator/:guid/:source/:destination/import',
    migratorDataMigration: '/migrator/:guid/:source/:destination/dataMigration/',
    migratorDataMigrationEntity: '/migrator/:guid/:source/:destination/dataMigration/:name'
};
