export var PluginTypeDescription;
(function (PluginTypeDescription) {
    PluginTypeDescription["INTEGRATION"] = "Integration";
    PluginTypeDescription["TRANSFORMATION"] = "Transformation";
    PluginTypeDescription["SERIALIZATION"] = "Serialization";
    PluginTypeDescription["OBJECTSERVING"] = "ObjectServing";
})(PluginTypeDescription || (PluginTypeDescription = {}));
export var PluginType;
(function (PluginType) {
    PluginType[PluginType["Serialization"] = 1] = "Serialization";
    PluginType[PluginType["Transformation"] = 2] = "Transformation";
    PluginType[PluginType["Integration"] = 3] = "Integration";
    PluginType[PluginType["ObjectServe"] = 4] = "ObjectServe";
})(PluginType || (PluginType = {}));
