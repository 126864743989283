import { useMutation, useQueryClient } from 'react-query';
import { useActionLogger } from '@/shared/hooks/action-logger';
import { initiateChain } from '..';
export const useInitiateChainForMetadata = () => {
    const queryClient = useQueryClient();
    const { setAction } = useActionLogger();
    return useMutation(initiateChain, {
        onSuccess: () => {
            queryClient.invalidateQueries('GetMigratorList');
            setAction({
                type: 'success',
                message: 'The initiate chain for metadata was successful',
            });
        },
        onError: (error) => {
            setAction({
                type: 'error',
                message: error.toString(),
            });
        },
    });
};
