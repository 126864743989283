import { useMutation, useQueryClient } from 'react-query';
import { useActionLogger } from '@/shared/hooks/action-logger';
import { createPluginFile } from '../index';
export const useCreatePluginFile = (callback) => {
    const queryClient = useQueryClient();
    const { setAction } = useActionLogger();
    return useMutation(createPluginFile, {
        onSuccess: async () => {
            await queryClient.invalidateQueries('GetPluginDescriptionList');
            setAction({
                type: 'success',
                message: 'Creation of the plugin file was successful',
            });
            if (callback && typeof callback === 'function') {
                callback();
            }
        },
        onError: (error) => {
            setAction({
                type: 'error',
                message: error.toString(),
            });
        },
    });
};
