import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Grid, MenuItem, TextField, Typography, makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    form: {
        width: '500px',
        marginLeft: '1rem',
        marginTop: '2rem',
    },
    input: {
        marginBottom: theme.spacing(2),
        width: '200px',
        height: '40px',
    },
    colorButton: {
        backgroundColor: '#0066CC',
        color: 'white',
        borderRadius: '0px',
        marginBottom: '2rem',
        marginTop: '1rem',
    },
    paddingBott: {
        marginLeft: '1rem',
    },
    margin: {
        margin: theme.spacing(1),
    },
    marginTop: {
        marginTop: '2rem',
    },
    textAliginEnd: {
        textAlign: 'end',
        marginTop: '8px',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    marginTopColorGrid: {
        marginTop: '2rem',
        fontSize: '15px',
    },
    textAliginEndMarginBottom: {
        textAlign: 'end',
        marginBottom: '2rem',
    },
}));
export const FormAdd = ({ onSubmit, disabledSaveButton }) => {
    const styles = useStyles();
    const { register, handleSubmit, setValue, errors } = useForm();
    useEffect(() => {
        register('name', {
        //   required: true,
        });
        register('host', {
        //   required: true,
        });
        register('httpHeader', {
        //   required: true,
        });
        register('port', {
        //   required: true,
        });
    }, [register]);
    //   const getErrorByName = (name: string) => migratorError?.filter((error) => String(error.fieldName).toLowerCase() === String(name).toLowerCase())[0];
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { className: styles.form, onSubmit: handleSubmit((data) => onSubmit(data)) },
            React.createElement(Typography, { variant: "h5", className: styles.paddingBott, style: { margin: 0, paddingTop: '1rem' } }, "\u0414\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u043C\u0438\u043A\u0440\u043E\u0441\u0435\u0440\u0432\u0438\u0441\u0430 \u043C\u0438\u0433\u0440\u0430\u0446\u0438\u0438"),
            React.createElement(Box, { className: styles.form, justifyContent: "flex-start" },
                React.createElement(Grid, { container: true, spacing: 1, className: styles.marginTopColorGrid },
                    React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u043C\u0438\u043A\u0440\u043E\u0441\u0435\u0440\u0432\u0438\u0441\u0430 *"),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(TextField, { className: styles.input, fullWidth: true, id: "outlined-basic", variant: "outlined", size: "small", onChange: ({ target }) => setValue('name', target.value), error: Boolean(errors?.name) })),
                    React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u0425\u043E\u0441\u0442 *"),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(TextField, { className: styles.input, fullWidth: true, id: "outlined-basic", variant: "outlined", size: "small", onChange: ({ target }) => setValue('host', target.value), error: Boolean(errors.host) })),
                    React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u041F\u043E\u0440\u0442 *"),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(TextField, { className: styles.input, fullWidth: true, id: "outlined-basic", variant: "outlined", size: "small", onChange: ({ target }) => setValue('port', Number(target.value)), error: Boolean(errors.port) })),
                    React.createElement(Grid, { item: true, xs: 6, className: styles.textAliginEnd }, "\u0417\u0430\u0433\u043E\u043B\u043E\u0432\u043E\u043A HTTP *"),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(TextField, { className: styles.input, fullWidth: true, id: "standard-select-currency", select: true, variant: "outlined", size: "small", onChange: ({ target }) => setValue('httpHeader', target.value), error: Boolean(errors.httpHeader) },
                            React.createElement(MenuItem, { value: "http" }, "http"),
                            React.createElement(MenuItem, { value: "https" }, "https"))))),
            React.createElement(Box, null,
                React.createElement(Button, { variant: "contained", className: styles.colorButton, type: "submit", disabled: disabledSaveButton, style: { marginBottom: '1rem' } }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C")))));
};
