import React, { useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { PageLayout } from '@/shared/components/PageLayout';
import { Drawer } from './components/Drawer';
import { FormAddingUser } from './components/FormAddingUser';
import { UsersTable } from './components/UsersTable';
import { usersMock } from './mock';
export const Users = () => {
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    // Mocks
    const [users] = useState(usersMock);
    const [isOpenUserDrawer, setIsOpenUserDrawer] = useState(false);
    const [targetUser, setTargetUser] = useState();
    const handleAddProfile = () => {
        setIsOpenDrawer(true);
    };
    const handleClickRowByUsersTable = (data) => {
        setTargetUser(data);
        setIsOpenUserDrawer(true);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PageLayout, null,
            React.createElement(Box, { height: "75vh" },
                React.createElement(Box, { mb: 1 },
                    React.createElement(Typography, { variant: "h4" }, "\u041F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0438")),
                React.createElement(Box, { mb: 2 },
                    React.createElement(Button, { variant: "contained", color: "primary", startIcon: React.createElement(AddCircleIcon, null), onClick: handleAddProfile }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C")),
                React.createElement(UsersTable, { rows: users, onClickRow: handleClickRowByUsersTable }))),
        React.createElement(Drawer, { isOpen: isOpenDrawer, onClose: () => setIsOpenDrawer(false) },
            React.createElement(FormAddingUser, { onSubmit: () => console.log('submit') })),
        React.createElement(Drawer, { isOpen: isOpenUserDrawer, onClose: () => setIsOpenUserDrawer(false) },
            React.createElement(FormAddingUser, { isEditing: true, initialState: targetUser, onSubmit: () => console.log('submit') }))));
};
