import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { useStyles } from "@/pages/IntegrationChains/styles";
import { PageLayout } from '@/shared/components/PageLayout';
import { Box, Button, Paper } from '@material-ui/core';
import { FormEdit } from "@/pages/IntegrationChains/components/FormEdit";
import { useGetChain } from "@/pages/IntegrationChains/services/hooks/use-get-all-chains";
import { useDeleteChain } from "@/pages/IntegrationChains/services/hooks/use-delete-chain";
import { useActionLogger } from '@/shared/hooks/action-logger';
export const IntegrationChainPage = () => {
    const { guid } = useParams();
    const [additionalValue, setAdditionalValue] = useState(`?guid=${guid}`);
    const { data: chainDetails, refetch } = useGetChain(additionalValue);
    const classes = useStyles();
    const history = useHistory();
    const { mutate: deleteChain } = useDeleteChain(() => refetch());
    const { setAction } = useActionLogger();
    useEffect(() => {
        if (!chainDetails?.isSuccess && chainDetails?.errorMessage)
            setAction({
                type: 'error',
                message: chainDetails ? String(chainDetails.errorMessage) : `Ошибка при загрузке интеграционной цепи с id ${guid}`,
            });
        if (chainDetails?.errorFullMessage)
            console.error(chainDetails?.errorFullMessage);
    }, [chainDetails]);
    return (React.createElement(PageLayout, null,
        React.createElement("div", { style: { width: '100%', marginBottom: '1rem' } },
            React.createElement(Box, { component: "div", display: "inline" },
                React.createElement(Button, { variant: "contained", size: "medium", className: classes.colorButtonBack, onClick: () => { history.push('/integration-chains'); } }, "\u041D\u0430\u0437\u0430\u0434 \u043A \u0441\u043F\u0438\u0441\u043A\u0443"))),
        chainDetails && chainDetails.chainDescription ?
            React.createElement(Paper, null,
                React.createElement(FormEdit, { refetchChain: refetch, stageInfos: chainDetails.stageInfos, defaultValues: chainDetails.chainDescription, onDelete: deleteChain }))
            : null,
        chainDetails && !chainDetails.isSuccess ?
            React.createElement("h2", null, "\u041F\u0440\u043E\u0438\u0437\u043E\u0448\u043B\u0430 \u043E\u0448\u0438\u0431\u043A\u0430") : null));
};
