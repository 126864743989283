/* eslint-disable no-console */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import Xarrow, { Xwrapper, useXarrow } from 'react-xarrows';
import { nanoid } from 'nanoid';
import { Button, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Scrollbars } from 'react-custom-scrollbars';
// import { useQueryClient } from 'react-query';
import { PageLayout } from '@/shared/components/PageLayout';
import { useHistory, useParams } from 'react-router-dom';
import { useActionLogger } from '@/shared/hooks/action-logger';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from '../Tree/trees.styles';
import { ArrowType } from '../../types';
import { useGetMetadataModel } from '../../services/hooks/use-get-metadata-model';
import { useGetListLinks } from '../../services/hooks/use-get-list-link';
import { useCreatePropertyLink } from '../../services/hooks/use-create-property-link';
import { useDeleteMigLink } from '../../services/hooks/use-delete-link';
import { Tree } from './Tree';
import { SelectArrow } from './SelectArrow';
function generateArrayFromString(string) {
    const parts = string.split('/');
    const result = [];
    for (let i = 1; i < parts.length; i++) {
        const arrPart = parts.slice(0, i + 1).join('/');
        result.push(arrPart);
    }
    return result;
}
export const DataMapping = () => {
    const classes = useStyles();
    const history = useHistory();
    const { guid, source, destination } = useParams();
    const { data: metadataModelSource, refetch: refetchSource } = useGetMetadataModel(`?migratorGuid=${guid}&systemGuid=${source}`);
    const { data: metadataModelDestination, refetch: refetchDestination } = useGetMetadataModel(`?migratorGuid=${guid}&systemGuid=${destination}`);
    // const [additional, setAdditional] = useState('')
    const { data: listOfLinks, refetch } = useGetListLinks(`?migratorGuid=${guid}`);
    // setAdditional(`?migratorGuid=${guid}`)
    const { mutate: createLink, data: responseGuid } = useCreatePropertyLink();
    const { mutate: deleteLink } = useDeleteMigLink();
    const { setAction } = useActionLogger();
    const nodeTypesRef = useRef({
        source: undefined,
        receiver: undefined,
    });
    const { connections } = useSelector((store) => store.connectionsState);
    // const { sourceType, receiverType } = useSelector((store: RootState) => store.nodeTypesState);
    // const [types, setTypes] = useState<{
    //     sourceType: TreeNodeType | undefined;
    //     receiverType: TreeNodeType | undefined;
    // }>({ sourceType: undefined, receiverType: undefined });
    const dispatch = useDispatch();
    const [selectedSourceNodeId, setSelectedSourceNodeId] = useState('');
    const [selectedReceiverNodeId, setSelectedReceiverNodeId] = useState('');
    const [selectedConnectionId, setSelectedConnectionId] = useState('');
    const [visible, setVisible] = useState(false);
    const [enable] = useState(false);
    const [newGuid, setNewGuid] = useState([]);
    const updateXarrow = useXarrow();
    useEffect(() => {
        const fetchData = async () => {
            await refetchSource();
            await refetchDestination();
            await refetch();
            const existing = listOfLinks?.links.map((link) => {
                const newLink = link.description.split('.');
                const newHistoryStart = generateArrayFromString(newLink[0]);
                const newHistoryEnd = generateArrayFromString(newLink[1]);
                const payload = {
                    arrowStart: newLink[0].split('/').slice(0, 2).join('/'),
                    arrowEnd: newLink[1].split('/').slice(0, 2).join('/'),
                    sourcePropertyGuid: newLink[0],
                    destinationPropertyGuid: newLink[1],
                    type: ArrowType.High,
                    historyStart: newHistoryStart.reverse(),
                    historyEnd: newHistoryEnd.reverse(),
                    propertyLinkGuid: link.propertyLinkGuid,
                    description: link.description,
                };
                return payload;
            });
            dispatch({ type: 'GET_CONNECTIONS', payload: existing });
        };
        fetchData();
    }, [listOfLinks?.links]);
    const createConnection = useCallback((firstNodeId, secondNodeId) => {
        // console.log('createConnection');
        if (!firstNodeId || !secondNodeId) {
            return;
        }
        const newConnection = {
            propertyLinkGuid: nanoid(),
            sourcePropertyGuid: firstNodeId,
            destinationPropertyGuid: secondNodeId,
            arrowStart: firstNodeId,
            arrowEnd: secondNodeId,
            type: ArrowType.Simple,
            historyStart: [firstNodeId],
            historyEnd: [secondNodeId],
            description: '',
        };
        createLink({
            migratorGuid: guid,
            migPropertyLinkDto: {
                propertyLinkGuid: '00000000-0000-0000-0000-000000000000',
                sourcePropertyGuid: newConnection.sourcePropertyGuid.split('/').pop() || '',
                destinationPropertyGuid: newConnection.destinationPropertyGuid.split('/').pop() || '',
                description: `${newConnection.sourcePropertyGuid}.${newConnection.destinationPropertyGuid}`,
            },
        });
        dispatch({ type: 'ADD_CONNECTION', payload: newConnection });
    }, [dispatch, createLink, responseGuid?.isSuccess, setNewGuid]);
    useEffect(() => {
        connections.forEach((con) => {
            if (responseGuid?.isSuccess) {
                const payload = {
                    guid: responseGuid.migPropertyLinkGuid,
                    sourcePropertyGuid: con.sourcePropertyGuid,
                    destinationPropertyGuid: con.destinationPropertyGuid,
                };
                setNewGuid([...newGuid, payload]);
            }
        });
    }, [responseGuid?.isSuccess]);
    const onTree1LeafSelected = (nodeId, type) => {
        if (nodeId.startsWith('source')) {
            nodeTypesRef.current.source = type;
        }
        const existingConnection = connections.find((connection) => connection.sourcePropertyGuid === nodeId);
        if (existingConnection) {
            setAction({
                type: 'error',
                message: 'Connection from this node already exists',
            });
            setSelectedSourceNodeId('');
            return;
        }
        if (selectedSourceNodeId === nodeId) {
            setSelectedSourceNodeId('');
            return;
        }
        setSelectedSourceNodeId(nodeId);
        setSelectedReceiverNodeId('');
    };
    const onTree2LeafSelected = (nodeId, type) => {
        if (nodeId.startsWith('receiver')) {
            nodeTypesRef.current.receiver = type;
        }
        if (!nodeId) {
            return;
        }
        const existingConnection = connections.find((connection) => connection.destinationPropertyGuid === nodeId);
        if (existingConnection) {
            setAction({
                type: 'error',
                message: 'Connection to this node already exists',
            });
            return;
        }
        if (nodeTypesRef.current.source && nodeTypesRef.current.receiver) {
            if (nodeTypesRef.current.source !== nodeTypesRef.current.receiver
                && (!(nodeTypesRef.current.source === 6 && nodeTypesRef.current.receiver === 2))
                && (!(nodeTypesRef.current.source === 2 && nodeTypesRef.current.receiver === 6))) {
                setAction({
                    type: 'error',
                    message: 'Node types cannot be different',
                });
                return;
            }
        }
        if (!selectedSourceNodeId && nodeId === selectedReceiverNodeId) {
            setSelectedReceiverNodeId('');
        }
        if (selectedSourceNodeId) {
            setSelectedReceiverNodeId(nodeId);
            createConnection(selectedSourceNodeId, nodeId);
            setSelectedSourceNodeId('');
            setSelectedReceiverNodeId('');
        }
        // setEnable(true);
    };
    const removeLinkNode = (linkGuid) => {
        const needConnection = connections.find((con) => con.propertyLinkGuid === linkGuid);
        const needConWithGuid = newGuid.find((con) => con.sourcePropertyGuid === needConnection?.sourcePropertyGuid &&
            con.destinationPropertyGuid === needConnection.destinationPropertyGuid);
        dispatch({ type: 'DELETE_CONNECTION', payload: linkGuid });
        deleteLink(`?migratorGuid=${guid}&migPropertyLinkGuid=${needConWithGuid ? needConWithGuid?.guid : linkGuid}`);
        setSelectedConnectionId('');
    };
    const getArrowColor = (item) => {
        if (item.propertyLinkGuid === selectedConnectionId && visible)
            return 'red';
        switch (item.type) {
            case ArrowType.High:
                return 'green';
            case ArrowType.Simple:
                return 'CornflowerBlue';
            default:
                return 'CornflowerBlue';
        }
    };
    const onSourceSystemExpand = (id) => {
        // console.log('onSourceSystemExpand', id);
        connections.forEach((connection) => {
            if (connection.arrowStart === id) {
                const newHistory = connection.historyStart.slice(0, -1);
                const [newArrowStart] = newHistory.slice(-1);
                // console.log('newHistory', newHistory);
                dispatch({
                    type: 'UPDATE_CONNECTION',
                    payload: {
                        ...connection,
                        historyStart: newHistory,
                        arrowStart: newArrowStart,
                        type: newHistory.length === 1 && connection.historyEnd.length === 1
                            ? ArrowType.Simple
                            : ArrowType.High,
                    },
                });
            }
        });
        setSelectedConnectionId('');
        setVisible(false);
        setTimeout(updateXarrow, 500);
    };
    const onRecieveSystemExpand = (id) => {
        // console.log('onRecieveSystemExpand', id);
        connections.forEach((connection) => {
            if (connection.arrowEnd === id) {
                const newHistory = connection.historyEnd.slice(0, -1);
                const [newArrowEnd] = newHistory.slice(-1);
                // console.log('newHistory', newHistory);
                dispatch({
                    type: 'UPDATE_CONNECTION',
                    payload: {
                        ...connection,
                        historyEnd: newHistory,
                        arrowEnd: newArrowEnd,
                        type: newHistory.length === 1 && [...connection.historyStart].length === 1
                            ? ArrowType.Simple
                            : ArrowType.High,
                    },
                });
            }
        });
        setSelectedConnectionId('');
        setVisible(false);
        setTimeout(updateXarrow, 500);
    };
    const onSourceSystemCollapse = (id) => {
        connections.forEach((connection) => {
            if (connection.sourcePropertyGuid.includes(id)) {
                dispatch({
                    type: 'UPDATE_CONNECTION',
                    payload: {
                        ...connection,
                        arrowStart: id,
                        type: ArrowType.High,
                        historyStart: [...connection.historyStart, id],
                    },
                });
            }
        });
        setSelectedConnectionId('');
        setVisible(false);
        setTimeout(updateXarrow, 500);
    };
    const onRecieveSystemCollapse = (id) => {
        // console.log('onRecieveSystemCollapse', id);
        connections.forEach((connection) => {
            if (connection.destinationPropertyGuid.includes(id)) {
                dispatch({
                    type: 'UPDATE_CONNECTION',
                    payload: {
                        ...connection,
                        arrowEnd: id,
                        type: ArrowType.High,
                        historyEnd: [...connection.historyEnd, id],
                    },
                });
            }
        });
        setSelectedConnectionId('');
        setVisible(false);
        setTimeout(updateXarrow, 500);
    };
    // console.log('connections', connections);
    // console.log('newGuid', newGuid);
    return (React.createElement(PageLayout, null,
        React.createElement(Typography, { variant: "h4", className: classes.titleDataMapping, style: { marginLeft: 7 } }, "\u041C\u0430\u043F\u043F\u0438\u043D\u0433 \u0434\u0430\u043D\u043D\u044B\u0445"),
        React.createElement(Grid, { container: true, justify: "center", style: { position: 'relative' } },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "h5", className: classes.titleDataGrid }, "\u0421\u0438\u0441\u0442\u0435\u043C\u0430 - \u0418\u0441\u0442\u043E\u0447\u043D\u0438\u043A")),
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(Typography, { variant: "h5", className: classes.titleDataGrid }, "\u0421\u0438\u0441\u0442\u0435\u043C\u0430 - \u041F\u0440\u0438\u0435\u043C\u043D\u0438\u043A")),
            React.createElement(Xwrapper, null,
                React.createElement(Grid, { container: true, style: { overflow: 'hidden', position: 'relative', marginLeft: 8 } },
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement("div", { style: {
                                border: '1px solid #0066CC',
                                paddingLeft: '10px',
                                paddingTop: '10px',
                                marginRight: '30px',
                                minHeight: '62vh',
                                height: '100%',
                                maxHeight: '100vh'
                            } },
                            React.createElement(Scrollbars, { style: { width: '100%', height: '100%' }, onScrollFrame: updateXarrow, onScrollStop: updateXarrow }, metadataModelSource?.huEntityString && (React.createElement(Tree, { node: JSON.parse(metadataModelSource?.huEntityString), selectedNodeId: selectedSourceNodeId, onNodeSelect: onTree1LeafSelected, parentNodeId: "sourceTreeNode", onExpand: onSourceSystemExpand, onCollapse: onSourceSystemCollapse, enable: enable }))))),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement("div", { style: {
                                border: '1px solid #0066CC',
                                paddingLeft: '20px',
                                paddingTop: '10px',
                                minHeight: '62vh',
                                height: '100%',
                                maxHeight: '100vh'
                            } },
                            React.createElement(Scrollbars, { style: { width: '100%', height: '100%' }, onScrollFrame: updateXarrow, onScrollStop: updateXarrow }, metadataModelDestination?.huEntityString && (React.createElement(Tree, { node: JSON.parse(metadataModelDestination?.huEntityString), selectedNodeId: selectedReceiverNodeId, onNodeSelect: onTree2LeafSelected, parentNodeId: "receiverTreeNode", onExpand: onRecieveSystemExpand, onCollapse: onRecieveSystemCollapse, enable: enable }))))),
                    connections &&
                        connections?.map((connection) => {
                            const isSelected = selectedConnectionId === connection.propertyLinkGuid && visible;
                            return (React.createElement(Xarrow, { key: connection.sourcePropertyGuid, start: connection.arrowStart, end: connection.arrowEnd, strokeWidth: 4, color: getArrowColor(connection), curveness: 0.7, startAnchor: { position: 'right', offset: { x: 3 } }, endAnchor: { position: 'left', offset: { x: -23 } }, path: "grid", gridBreak: "80%", headSize: 4, passProps: connection.type !== ArrowType.High
                                    ? {
                                        onClick: () => {
                                            setSelectedConnectionId(connection.propertyLinkGuid);
                                            setVisible((prev) => !prev);
                                        },
                                        cursor: 'pointer',
                                    }
                                    : undefined, labels: isSelected ? (React.createElement(SelectArrow, { connection: connection, removeLinkNode: removeLinkNode, setSelectedNode1: setSelectedSourceNodeId, setSelectedNode2: setSelectedReceiverNodeId })) : undefined }));
                        })))),
        React.createElement(Grid, { container: true, spacing: 1, className: classes.gridButtons },
            React.createElement(Grid, { item: true, xs: 6, style: { display: 'flex', gap: '1rem' } },
                React.createElement(Button, { className: classes.buttonBack, style: { marginLeft: 0 }, onClick: () => history.push(`/migrator/${guid}/destination/${source}/${destination}`) }, "\u041D\u0430\u0437\u0430\u0434"),
                React.createElement(Button, { className: classes.buttonNext, onClick: () => history.push(`/migrator/${guid}/${source}/${destination}/rebuild`) }, "\u0421\u043B\u0435\u0434\u0443\u044E\u0449\u0438\u0439 \u0448\u0430\u0433")))));
};
