import React, { useState, useMemo } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { Button, Paper } from '@material-ui/core';
// import { useStyles } from "@/pages/Systems/styles";
import { PageLayout } from '@/shared/components/PageLayout';
import { FormEdit } from "@/pages/Systems/components/FormEdit";
import { useStyles } from "@/pages/Systems/styles";
import { useGetAllSystems, useGetSystemByGuid } from "@/pages/Systems/services/hooks/use-get-all-systems";
import { useDeleteSystem } from "@/pages/Systems/services/hooks/use-delete-system";
import { useUpdateSystem } from "@/pages/Systems/services/hooks/use-update-system";
import { useGetPluginInstances } from "@/shared/services/instances/hooks/use-get-plugin-instances";
import { useNeedetGetPlugins } from './services/hooks/use-get-needed-plugins';
export const SystemPage = () => {
    const { guid } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const [additionalValue] = useState(`?guid=${guid}`);
    const { data: system } = useGetSystemByGuid(additionalValue);
    const { refetch } = useGetAllSystems('');
    const { mutate: updateSystem, isLoading: updatingSystem } = useUpdateSystem();
    const { mutate: deleteSystem } = useDeleteSystem(() => {
        refetch();
        history.push('/systems');
    });
    //
    const { data: pluginInstances } = useGetPluginInstances(additionalValue);
    const { data: pluginsList } = useNeedetGetPlugins();
    //
    const serializationOptions = useMemo(() => pluginsList?.huHeadPluginDescriptions
        ?.filter(({ pluginTypeDescription }) => pluginTypeDescription === 'Serialization')
        .map(({ name, guid }) => ({
        label: name,
        value: guid,
    })) || [], [pluginsList]);
    const integrationOptions = useMemo(() => pluginsList?.huHeadPluginDescriptions
        ?.filter(({ pluginTypeDescription }) => pluginTypeDescription === 'Integration')
        .map(({ name, guid }) => ({
        label: name,
        value: guid,
    })) || [], [pluginsList]);
    const objectServeOptions = useMemo(() => pluginsList?.huHeadPluginDescriptions
        ?.filter(({ pluginTypeDescription }) => pluginTypeDescription === 'ObjectServing')
        .map(({ name, guid }) => ({
        label: name,
        value: guid,
    })) || [], [pluginsList]);
    const pluginInstanceOptions = useMemo(() => pluginInstances?.pluginInstanceInfoWithHeadAddresses.map(({ name, guid }) => ({
        label: name,
        value: guid,
    })) || [], [pluginInstances]);
    return (React.createElement(PageLayout, null,
        React.createElement("div", { className: classes.overflow },
            React.createElement("div", { style: { width: '100%' } },
                React.createElement(Button, { variant: "contained", size: "medium", className: classes.colorButtonBack, onClick: () => { history.push('/systems'); } }, "\u041D\u0430\u0437\u0430\u0434 \u043A \u0441\u043F\u0438\u0441\u043A\u0443"))),
        React.createElement(Paper, null, system && system.systemInfo ? (React.createElement(FormEdit, { updatingSystem: updatingSystem, serializationOptions: serializationOptions, integrationOptions: integrationOptions, objectServeOptions: objectServeOptions, pluginInstanceOptions: pluginInstanceOptions, onSubmit: updateSystem, onDelete: deleteSystem, defaultValues: system })) : null)));
};
